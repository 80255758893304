import axios from 'axios';

import {  AVATAR_SKINS_FETCH_SUCCESS, 
          AVATAR_FACES_FETCH_SUCCESS, 
          AVATAR_HEADS_FETCH_SUCCESS, 
          AVATAR_SHIRTS_FETCH_SUCCESS } from './types';

export const avatarSkinsFetch = () => {
  const url = '/api/avatar_skins' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: AVATAR_SKINS_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const avatarFacesFetch = () => {
  const url = '/api/avatar_faces' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: AVATAR_FACES_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const avatarHeadsFetch = (headwear) => {
  const url = `/api/avatar_heads`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: AVATAR_HEADS_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const avatarShirtsFetch = (headwear) => {
  const url = `/api/avatar_shirts`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: AVATAR_SHIRTS_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

