import React, { Component } from 'react';
import { connect } from 'react-redux';

import { puzzleInfo } from './JigsawData'

import '../../../stylesheets/jigsawPuzzles.css';

class Puzzle extends React.Component {
  constructor(props) {
    super(props);
  }

  indexClass(){ 
    if(this.props.active){
      return "z-20"
    }
    return "z-10"
  }

  shadowClass(){ 
    if(this.props.active){ 
      return "puzzles-active z-10"
    }else{ 
      return "invisible"
    }
  }

  render() {
    const puzzle = this.props.puzzle
    const viewBox = puzzleInfo[puzzle.index].data.viewBox
    return (
      <div className="pointer-none">
          <img className={this.indexClass()} 
            style={{ 
            position: 'absolute',
            left: `${puzzle.left}px`,
            top: `${puzzle.top}px`,
            width: `${puzzle.width * this.props.sizeRate}px`,
            height: `${puzzle.height * this.props.sizeRate}px`,
          }}
           src={require(`../../../assets/games/jigsaw_puzzles/mount_sinai/${puzzle.index}.png`)}  />

           <svg className="pointer-none z-30" viewBox={puzzleInfo[puzzle.index].data.viewBox}
            style={{ 
              position: 'absolute',
              left: `${puzzle.left}px`,
              top: `${puzzle.top}px`,
              width: `${puzzle.width * this.props.sizeRate}px`,
              height: `${puzzle.height * this.props.sizeRate}px`,
            }}
          >        
            <path style={{pointerEvents:"visible"}} fill="transparent" d={puzzleInfo[puzzle.index].data.path}
              onMouseDown={this.props.mouseDownHandler}
              onTouchStart={this.props.touchStartHandler} 
            />
          </svg> 

          <svg className={this.shadowClass()} viewBox={puzzleInfo[puzzle.index].data.viewBox}
            style={{ 
              position: 'absolute',
              left: `${puzzle.left}px`,
              top: `${puzzle.top}px`,
              width: `${puzzle.width * this.props.sizeRate}px`,
              height: `${puzzle.height * this.props.sizeRate}px`,
            }}
          >        
          <path fill="black" d={puzzleInfo[puzzle.index].data.path}
          />
        </svg> 
        </div> 
    );
  }
}


export default connect()(Puzzle);