import {
    GAME_LIST_FETCH_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    gameList: [],
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case GAME_LIST_FETCH_SUCCESS: 
        return { ...state, gameList: action.payload };  
      default:
        return state;
    }
  };
