import React from 'react';
import { isMobile } from "react-device-detect";

export function buttonSize(width, height){ 
	var ratio = width/height
  if(ratio > 1){
    if(width > 1500){
      return 0.05 * 1500
    } 
    else if(width > 1000){ 
      return 0.06 * width
    }
    if(isMobile){ 
      return 0.048  * width
    }
    return 0.07 * width 
  }else{ 
    return 0.06 * height
  }
}