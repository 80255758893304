import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { MdOutlineClose } from "react-icons/md"; 

import '../stylesheets/fancyButton.css';

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowPathIcon, ArrowRightOnRectangleIcon, XMarkIcon, PlayIcon } from '@heroicons/react/24/outline'

export default function ShareModal(props) {
  const [open, setOpen] = useState(true)
  const completeButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={completeButtonRef}
        open={props.open}
        onClose={props.closeHandler}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/*}
            style={{backgroundImage: "url(/images/the_creation/cover.jpg)", backgroundSize: "auto", backgroundColor: "rgba(255,255,255,0.4)", backgroundBlendMode:"overlay", backgroundRepeat: "no-repeat", backgroundPosition:"center"}} 
            {*/}
            <div className="pb-2 bg-primary-dark inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle w-full w-1/2 max-w-md">
              <div className="px-2 py-2 md:px-3 md:py-3 bg-primary-color">
                <div className="mb-4 content-between">
                  <div className="text-center">
                    <Dialog.Title as="h3" className="mt-2 text-white modal-text-title text-3xl md:text-4xl leading-6 font-bold">
                       Share
                    </Dialog.Title>
                  </div>
                </div>
                <div className="text-right -mt-12 md:-mt-16">
                  <button className="no-outline-button"
                    onClick={props.closeHandler}
                  >
                    <p className="text-3xl sm:text-5xl text-white">
                      <MdOutlineClose/>
                    </p>
                  </button>
                </div> 
              </div>
              <div className="my-4 flex justify-center gap-8">
                <FacebookShareButton
                  url={window.location}
                  hashtag={"#biblefunclub"}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={48} round /> 
                </FacebookShareButton>
                <TwitterShareButton
                  title={props.twitterTitle}
                  url={window.location}
                  hashtags={["biblefunclub"]}
                >
                  <TwitterIcon size={48} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  title={props.whatsappTitle}
                  url={window.location}
                >
                  <WhatsappIcon size={48} round />
                </WhatsappShareButton>
              </div>
              <div className="mx-4 my-3 bg-white shadow-sm focus:ring-primary-color focus:border-primary-color border-gray-500 rounded-md">

                  <div className="flex">
                    <input
                      className="sharing-text w-full outline-transparent"
                      value={`${window.location.href}`}
                    />
                    <button className="w-24 lg:w-32 fancy-button orange small"
                      onClick={(e) => navigator.clipboard.writeText(window.location) }
                    >
                      Copy
                    </button>
                  </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}