import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'

class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div> 
        <div className="min-h-screen bg-primary-dark"> 
          <NavBar/>
          <div className="overflow-hidden">
            <div className="min-h-screen m-auto relative max-w-7xl bg-primary-color py-2 sm:py-6 md:py-8 px-4 sm:px-6 lg:px-8">
              <div>
                <h3 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-4xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-4xl">
                  About Bible Fun Club
                </h3>
              </div>
              <div className="mt-8 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">  
                <div className="md:row-start-1 md:col-start-2 lg:col-start-3">
                  <img
                    className="mt-8 lg:mt-0 mx-auto w-48 md:w-64 lg:w-72 rounded-lg shadow-lg object-cover object-center"
                    src="/images/about/moscot.webp"
                  />
                </div>
                <div className="mb-2 my-auto lg:row-start-1 lg:col-span-2"> 
                  <p className="lesson-text text-justify text-lg text-gray-800">
                   Hello! Welcome to the brickAmaze Bible Fun Club. I am Angela Chung from Penang, Malaysia. 
                   I accepted Christ in the year 2001. I served and taught in Sunday school for about 10 years before I backslided. 
                   One sin leads to another, eventually I only see darkness in my life. 
                   Every day the sky looked cloudy to me. 
                   I wasn't able to see any future in my life. 
                   I tried to fill that despair with other things. 
                   That was when I started to play Lego. 
                   I became so possessive and buried myself in a virtual world made of Lego. 
                  </p>
                </div>
              </div> 
              <div className="py-4 lg:py-12">
                <img
                  className="m-auto w-full md:w-1/2 rounded-lg shadow-lg object-cover object-center"
                  src="/images/about/city.webp"
                />
              </div>
              <div>
                <p className="lesson-text text-justify text-lg text-gray-800">
                  Thank God for He is faithful. Although I strayed away, He never forsake me. 
                  For many years I kept my sin in the darkness. 
                  And it was sin that made my life a total misery. 
                  Praise the Lord that He sent someone into my life to wake my soul up, who is now my husband. 
                  It was the first time I confessed my sin to another person. 
                  What followed after the confession, was a rough journey filled with tears and pain but lead to restoration.  
                  Upon repentance, my life was completely transformed. 
                  I was set free from my sin as I was reminded that Jesus has died on the cross for me. 
                  I am white as snow despite all the horrible things I have done, as Jesus has paid the price for me. 
                  God loves me even when I am unworthy. Soon, I dismantled the Lego virtual world I built and it marked the new life of mine in 2016. 
                </p>
                <br/>
                <p className="mt-2 lesson-text text-justify text-lg text-gray-800">
                  However, deep in my heart, I wanted to glorify the Lord with Lego. So I started to teach the kids bible story using Lego builds. 
                  It is effective as kids get excited to see Lego builds with Bible scenes. 
                  After some time, I even started to make Lego stop motion videos to tell Bible stories. 
                </p> 
                <br/>
                <p className="mt-2 lesson-text text-justify text-lg text-gray-800">
                  I worked as an engineer, but in 2018, I decided to take a leap of faith, (with husband's support), I quit my 17-year engineering job to work in a web application company. 
                  It wasn't easy at start but thank God that i persevered. 
                  After couple years of learning, I was finally able to kick off this Bible Fun Club in year 2023.  
                </p> 
                <br/>
                <p className="mt-2 lesson-text text-justify text-lg text-gray-800">
                  My vision for this website is to share one key message to the world: 
                </p> 
                <br/>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  "Out of God's love for us, He sent His only begotten son, Jesus Christ to die for us, so that those who accept Him will have eternal life as God's own children."
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default connect() (About);