import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../../stylesheets/gameSetting.css';

class Item extends React.Component {
  constructor(props) {
    super(props);
  }

  svgEclipse(width, height){ 
    if(this.props.item.selected){ 
      return (
        <svg height={height} width={width}>
          <ellipse class="spot-difference-circle" cx={width/2} cy={height/2} rx={width/2 - 3} ry={height/2 - 3}
          style={{ 
            fill:"transparent",
            stroke:"red",
            strokeWidth:"3"
          }} />
        </svg>
      )
    }
  }

  render() {
    const width = (this.props.item.width * this.props.sizeRate) 
    const height = (this.props.item.height * this.props.sizeRate)
    return( 
      <div> 
        <div ref={this.props.refer} onClick={this.props.clickHandler}
          className="z-20 bg-yellow" 
          style = {{
            position: 'absolute',    
            top: `${this.props.item.top * this.props.sizeRate}px`,
            left: `${(this.props.item.left * this.props.sizeRate)}px`,
            width: `${this.props.item.width * this.props.sizeRate}px`, 
            height: `${this.props.item.height * this.props.sizeRate}px`,
          }}
        >
          {this.svgEclipse(width, height)}
        </div>
        <div ref={this.props.refer} onClick={this.props.clickHandler}
          className="z-20 bg-yellow" 
          style = {{
            position: 'absolute',    
            top: `${this.props.item.top * this.props.sizeRate}px`,
            left: `${this.props.picWidth + (this.props.item.left * this.props.sizeRate)}px`,
            width: `${this.props.item.width * this.props.sizeRate}px`, 
            height: `${this.props.item.height * this.props.sizeRate}px`,
          }}
        >
          {this.svgEclipse(width, height)}
        </div>
      </div>
    )
  }
}


export default connect()(Item);
