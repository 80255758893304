import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'
import TrophyTabs from './Trophies/TrophyTabs'

import { userTrophiesFetch } from '../actions/userActions';
import { gameListFetch } from '../actions/gameActions'
import { quizListFetch } from '../actions/quizActions'

class Trophies extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modalOpen: false,
      bronzeTrophyCount: 0, 
      silverTrophyCount: 0, 
      goldTrophyCount: 0
    }
  }

  trophyModalItem(){
    if(this.state.modalOpen){
    }
  }

  trophyTabItem(){ 
    return(
      <TrophyTabs { ...this.props } 
        parent = { this }
      />
    ) 
  }

  trophyCount(){ 
    let goldCount = 0 
    let silverCount = 0
    let bronzeCount = 0
    for(var i = 0; i < this.props.gameTrophies.length; i++ ){ 
      let trophy = this.props.gameTrophies[i]
      if(trophy.gold == true){ goldCount++ }
      if(trophy.silver == true){ silverCount++ }
      if(trophy.bronze == true){ bronzeCount++ }
    }
    for(var i = 0; i < this.props.quizTrophies.length; i++ ){ 
      let trophy = this.props.quizTrophies[i]
      if(trophy.gold == true){ goldCount++ }
      if(trophy.silver == true){ silverCount++ }
      if(trophy.bronze == true){ bronzeCount++ }
    }
    return [bronzeCount, silverCount, goldCount]
  }

  trophiesItem(){ 
    if(this.props.trophiesLoaded){
      return(
        <div className="py-4 mx-auto bg-primary-dark rounded-lg"> 
          <div className="grid grid-cols-3">  
            <div className="col-start-1 col-span-2 sm:col-span-1 sm:col-start-2"> 
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="ml-2 sm:ml-0 text-left sm:text-center my-4 mx-auto text-xl md:text-2xl font-medium leading-6 tracking-wide text-gray-100">My Trophies</h3>
            </div>
            <div className="col-start-3"> 
              <div className="flex flex-row m-auto justify-end mr-2 md:mr-8">                   
                <p className="my-auto text-white mr-1"> {this.state.goldTrophyCount} </p>
                <img  src="/images/trophies/gold.webp" alt="" className="-mt-1 md:-mt-3 h-12 md:h-16 mr-2" />
                <p className="my-auto text-white mr-1"> {this.state.silverTrophyCount} </p>
                <img  src="/images/trophies/silver.webp" alt="" className="-mt-1 md:-mt-3 h-12 md:h-16 mr-2" />
                <p className="my-auto text-white mr-1"> {this.state.bronzeTrophyCount} </p>
                <img  src="/images/trophies/bronze.webp" alt="" className="-mt-1 md:-mt-3 h-12 md:h-16 mr-2" />
              </div>
            </div>
          </div>
        
          <div className="pt-2 py-4 mx-2 md:mx-8 bg-primary-light rounded-lg">         
            { this.trophyTabItem() }
          </div>
        </div>
      )
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.trophiesLoaded != prevProps.trophiesLoaded){
      let trophyCount = this.trophyCount()
      this.setState({bronzeTrophyCount: trophyCount[0], silverTrophyCount: trophyCount[1], goldTrophyCount: trophyCount[2]})
    }
  }

  componentDidMount() {
    this.props.gameListFetch(null);
    this.props.quizListFetch();
    this.props.userTrophiesFetch();
  }

  render() {
    return (
      <div> 
        <div className="min-h-screen bg-primary-color"> 
          {this.trophyModalItem()}
          <NavBar/>
          <div className="lg:mx-auto lg:max-w-7xl overflow-hidden flex flex-col">
            <div className="min-h-0 flex-1 flex overflow-auto">
              <div className="w-full bg-primary-color mx-auto sm:px-6 lg:px-8 py-5">
                {/* content */}
                {this.trophiesItem()}
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    user: state.user.user,
    profile: state.user.profile,
    userLoaded: state.user.userLoaded, 
    trophiesLoaded: state.user.trophiesLoaded,
    gameTrophies: state.user.gameTrophies, 
    quizTrophies:  state.user.quizTrophies, 
    gameList: state.game.gameList,
    quizList: state.quiz.quizList
  }
};


export default connect(mapStateToProps, {
  userTrophiesFetch, 
  gameListFetch,
  quizListFetch
}) (Trophies);