import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import '../../../stylesheets/fancyButton.css';

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowPathIcon, ArrowRightOnRectangleIcon, XMarkIcon, PlayIcon } from '@heroicons/react/24/outline'

function volumeIcon(audio){ 
  if(audio == true){ 
    return <SpeakerWaveIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }else{
    return <SpeakerXMarkIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }
}

export default function GameOverModal(props) {
  const [open, setOpen] = useState(true)
  const completeButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={completeButtonRef}
        open={props.open}
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle sm:max-w-lg sm:w-full">
              <div> 
                <img className="mx-auto object-contain"
                  src={require(`../../../assets/animations/try_again.gif`)}  
                /> 
              </div>
              <div className="px-3 py-3 md:px-4 md:py-4">
                <div className="text-center">
                  <p className="game-desc text-2xl md:text-3xl text-white mb-3 md:mb-4">
                    Try Again 
                  </p> 
                   
                </div> 
                <div className="my-auto mx-auto justify-items-center grid grid-cols-1">
                  <button className="w-24 md:w-36 focus:outline-none fancy-button green cursor-pointer"
                    ref={completeButtonRef}
                    onClick={props.endHandler}
                  >
                   OK
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}