import {
  NOAH_QUEST_INIT, 
  NOAH_QUEST_UPDATE,
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    images: [
      {id: 1, pic: "bear.jpg"},
      {id: 2, pic: "cat.jpg"},
      {id: 3, pic: "crocodile.jpg"},
      {id: 4, pic: "dog.jpg"},
      {id: 5, pic: "donkey.jpg"},
      {id: 6, pic: "fox.jpg"},
      {id: 7, pic: "giraffe.jpg"},
      {id: 8, pic: "goat.jpg"},
      {id: 9, pic: "hedgehog.jpg"},
      {id: 10, pic: "leopard.jpg"},
      {id: 11, pic: "monkey.jpg"},
      {id: 12, pic: "ostrich.jpg"},
      {id: 13, pic: "penguin.jpg"},
      {id: 14, pic: "shark.jpg"},
      {id: 15, pic: "skunk.jpg"},
      {id: 16, pic: "tiger.jpg"},
      {id: 17, pic: "turtle.jpg"},
    ], 
    cards: [ 
    ]
  };
  
export default (state = INITIAL_STATE, action) => { 
  switch(action.type) {   
    case NOAH_QUEST_INIT: 
      return { ...state, cards: action.payload}      
    case NOAH_QUEST_UPDATE:  
      const newArray = [...state.cards]; //making a new array
       newArray[action.index].invisible = action.value//changing value in the new array
       return { 
        ...state, //copying the original state
        cards: newArray, //reassingning cards to new array
       }
    default:  
      return state; 
  } 
}; 