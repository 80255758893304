import { NOAH_QUEST_INIT, NOAH_QUEST_UPDATE } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const noahQuestUpdate = (index, value) => {
  return {
    type: NOAH_QUEST_UPDATE,
    index: index,
    value: value
  }
};

export const noahQuestInit = (payload) => {
  return {
    type: NOAH_QUEST_INIT,
    payload: payload
  }
};
