import {
    PROGRESS_OWNERS_FETCH_SUCCESS, 
    PROGRESS_OWNER_SAVE_SUCCESS
  } from '../actions/types.js';
  
const INITIAL_STATE = {
  owner: {},
  ownerList: []
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case PROGRESS_OWNER_SAVE_SUCCESS: 
      return { ...state, owner: action.payload };  
    case PROGRESS_OWNERS_FETCH_SUCCESS: 
      return { ...state, ownerList: action.payload};  
    default:
      return state;
  }
};
