import React, { Component } from 'react';
import { connect } from 'react-redux';

const avatarSize = 1300 
const shirtWidth = 1480
const shirtTopOffset = 370
const shirtLeftOffset = 78.5
const shirtNeckOffset = 329
const faceWidthOffset = 580
const faceTopOffset = 110
const faceLeftOffset = 362

class AvatarProfilePhoto extends React.Component {
  
  render(){
    if(this.props.profile && Object.keys(this.props.profile).length){ 
      const headOffset = parseFloat(this.props.profile.avatar_head.head_offset)
      const avatarHeadLeftOffset = parseFloat(this.props.profile.avatar_head.left_offset)
      const avatarHeadWidth = parseFloat(this.props.profile.avatar_head.width)
      
      return (
        <div className="bg-white block rounded-full shadow-lg overflow-hidden"
          style={{position: 'absolute', 
                top: `0px`,
                left: `${this.props.left}px`,
                width: `${this.props.profileWidth}px`,
                height: `${this.props.profileWidth}px`
          }}
        > 
          <img
            style={{
              position: 'absolute', 
              top: `${this.props.profileWidth*(headOffset/avatarSize)}px`,
              left: `0px`,
              width: `${this.props.profileWidth}px`,
            }}
            src={this.props.profile.avatar_skin.body}
          />
          <img className="z-0"
            style={{
              position: 'absolute', 
              top: `${this.props.profileWidth*((faceTopOffset + headOffset)/avatarSize)}px`,
              left: `${this.props.profileWidth*(faceLeftOffset/avatarSize)}px`,
              width: `${this.props.profileWidth * faceWidthOffset/avatarSize}px`,
            }}
            src={this.props.profile.avatar_face.image}
          />
          <img className="z-10 max-w-none"
            style={{
              position: 'absolute', 
              top: `${this.props.profileWidth*((headOffset + shirtTopOffset)/avatarSize)}px`,
              left: `-${(this.props.profileWidth*(shirtLeftOffset/avatarSize))}px`,
              width: `${this.props.profileWidth*shirtWidth/avatarSize}px`,
              clipPath: `inset(${shirtNeckOffset/avatarSize * this.props.profileWidth}px 0px 0px 0px)`
            }}
            src={this.props.profile.avatar_shirt.image}
          />
          <img className="z-10"
            style={{
              position: 'absolute', 
              top: `0px`,
              left: `${this.props.profileWidth* (avatarHeadLeftOffset/avatarSize)}px`,
              width: `${this.props.profileWidth * avatarHeadWidth/avatarSize}px`
            }}
            src={this.props.profile.avatar_head.image}
          />

        </div>
      )
    }else{ 
      return <div/>
    }  
  }
}

const mapStateToProps = (state) => {
  return { 
    profile: state.user.profile
  }
};

export default connect(mapStateToProps, {
})(AvatarProfilePhoto);