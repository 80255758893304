import { JIGSAW_PUZZLES_INIT, JIGSAW_PUZZLE_UPDATE, JIGSAW_PUZZLES_ACTIVE } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const jigsawPuzzleUpdate = (index, puzzle) => {
  return {
    type: JIGSAW_PUZZLE_UPDATE,
    index: index,
    puzzle: puzzle
  }
};

export const jigsawPuzzlesInit = (puzzles) => {
  return {
    type: JIGSAW_PUZZLES_INIT,
    puzzles: puzzles
  }
};

export const jigsawPuzzlesActive = (indexes) => { 
  return {
    type: JIGSAW_PUZZLES_ACTIVE,
    indexes: indexes
  }
}
