import {
  JONAH_GAME_START, 
  JONAH_GAME_OVER,
  } from '../../actions/types.js';

const initialState = {
  status: '',
}

export default (state = {}, {type} = {}) => {
  switch (type) {
    case JONAH_GAME_START:
      return {...state, status: 'playing'}
    case JONAH_GAME_OVER:
      return {...state, status: 'game-over'}
    default:
      return state
  }
}
