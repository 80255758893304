import {
  JONAH_GAME_RUNNING, 
  JONAH_GAME_OVER,
  JONAH_PIPE_GENERATE,
  } from '../../actions/types.js';

const initialState = {
  x: 300,
  pipes: [],
}

export default (state = initialState, {type} = {}) => {
  switch (type) {
    case JONAH_GAME_RUNNING:
      if (!state.pipes.length) {
        return state
      }

      return {...state, x: state.x - 10}
    case JONAH_PIPE_GENERATE:
      const topHeight = Math.round(Math.random() * 200) + 40

      return {...state, pipes: [...state.pipes, {topHeight}]}
    case JONAH_GAME_OVER:
      return initialState
    default:
      return state
  }
}
