import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from '../elements/Footer'
import NavBar from '../elements/NavBar'

import { quizListFetch } from '../actions/quizActions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Quizzes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.quizListFetch();
  }

  content(){ 
    if(this.props.quizList.length){ 
      return(
        <div className="lg:mx-auto lg:max-w-7xl bg-primary-color flex flex-col">
          <div className="min-h-0 flex-1 flex">
            <div className="w-full mx-auto px-2 py-2 sm:px-6 lg:px-8 sm:py-5">
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {this.props.quizList.map((quiz) => (
                  <li key={quiz.url} className="relative">
                    <div className="bg-white aspect-square flex h-full items-stretch flex-col rounded-lg cursor-pointer hover:opacity-75"
                      onClick={(e) => window.location.href = `/quizzes/${quiz.url}` }
                    >
                      <div > 
                        <img src={quiz.thumbnail} alt="" className="rounded-t-lg object-contain pointer-events-none" />
                      </div> 
                      <div className ="relative m-auto w-full"
                      > 
                        <div className="flex text-center">
                          <div className="m-auto">
                            <h2 style={{fontFamily: 'Luckiest Guy'}} className="lg:px-4 mt-1 leading-4 text-1xl text-primary-dark font-extrabold font-sans tracking-wide md:text-2xl lg:text-3xl"> 
                              { quiz.name }
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div> 
      )
    }
  }

  render() {
    return (
      <>
        <div className="min-h-screen bg-primary-color"> 
          <NavBar/>
          <div className="text-center mx-auto pt-3 sm:pt-6 md:pt-8">
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-5xl">
              Quizzes
            </h2>
          </div>
          {this.content()}            
        </div>
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    quizList: state.quiz.quizList
  }
};

export default connect(mapStateToProps, {
  quizListFetch
})(Quizzes);
