import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import YouTube from 'react-youtube'
import { Link } from 'react-router-dom';

function videoFrameWidth(){ 
  let width = window.innerWidth
  // landscape
  if(window.innerWidth/window.innerHeight > 16/9){ 
    width = window.innerHeight * 16/9
  }
  return width
}

function introVideoText(props, frameWidth){ 
  if(props.introTextEnabled){ 
    let leftMargin = (window.innerWidth - frameWidth)/2 + frameWidth/4
    let topMargin = frameWidth * 5/16 // frameHeight * 5/9
    let width = frameWidth/2
    let height = frameWidth * 300/1600 // divide 3

    if(isMobile){ 
      leftMargin = (window.innerWidth - frameWidth)/2
      width = frameWidth
    }

    return(
      <div className="z-50 mx-auto unselectable"
        style={{position: 'absolute', 
              top: `${topMargin}px`,
              left: `${leftMargin}px`,
              width: `${width}px`,
              height: `${height}px`,
        }}
      >
        <div className="p-2 md:p-4 text-lg md:text-2xl bg-primary-dark mt-2 text-center text-white game-desc ">
          <p className="mb-2 md:mb-4 text-lg md:text-2xl text-white game-desc text-center"> Your game will begin after the following intro video </p>
          <Link className="plan-link"
            onClick={(e) => window.location = `/users/sign_in` } 
          >Login </Link>
          to skip intro video
        </div>
      </div>
    )
  }
}

export default function GameIntroVideo(props){ 
  let frameWidth = videoFrameWidth()
  let frameHeight = frameWidth * 9/16
  let leftMargin = (window.innerWidth - frameWidth)/2
  const opts = {
    height: `${frameHeight}px`,
    width: `${frameWidth}px`,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: true, 
      modestbranding: 1
     },
  };
  return(
    <div>
      <div className="z-50 mx-auto unselectable"
        style={{position: 'absolute', 
              top: `0px`,
              left: `${leftMargin}px`,
              width: `${frameWidth}px`,
              height: `${frameHeight}px`,
        }}
      >
        <YouTube 
          videoId={`${props.videoId}`}
          opts={opts} 
          onEnd={props.introEndHandler} 
          onPlay={props.introPlayHandler}
        />
      </div>
      {introVideoText(props, frameWidth)}
    </div>
  )
}