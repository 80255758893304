import React, { Component } from 'react';
import { connect } from 'react-redux';

import VideoTabs from './Videos/VideoTabs'
import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'
import VideoRenderer from './Videos/VideoRenderer'

import { videoListFetch } from '../actions/videoActions';
import { userFetch } from '../actions/userActions';

const STORY = 1 
const LESSON = 2

class Videos extends React.Component {
  constructor(props) {
    super(props);
    let browser = true 
    let videoId = ""

    if(this.props.match.params.video){ 
      browser = false
      videoId = this.props.match.params.video
    }
    this.state = { 
      browser: browser, 
      videoId: videoId, 
      queryString: "", 
      topicId: 0, 
      activity: 0, 
      duration: 0
    }
  }

  handleClickOnVideo(videoId, topicId, duration, activity){ 
    this.setState({videoId: videoId, topicId: topicId, duration: duration, activity: activity})
    this.setState({browser: false, queryString: this.props.location.search})
    window.history.replaceState(null, "", `/videos/${videoId}`) 
  }

  handleBackButton(){ 
    window.history.replaceState(null, "", `/videos${this.state.queryString}`)
    this.setState({browser: true})
  }

  pageContent(){ 
    if(this.state.browser){ 
      return this.videoList()
    }else{ 
      if(Object.keys(this.props.videoList).length && this.props.userLoaded){ 
        return(
          <VideoRenderer  
            videoUrl = {`${this.state.videoId}`}
            topicId = {`${this.state.topicId}`}
            duration = {`${this.state.duration}`}
            activity = { `${this.state.activity}` }
            activeUser = { Object.keys(this.props.user).length > 0 }
            backButtonHandler = { this.handleBackButton.bind(this)}
            { ...this.props } 
          />
        )
      }
      return <div/>
    }
  }

  videoList(){ 
    return (
      <div> 
        <div className="min-h-screen bg-primary-color"> 
          <NavBar/>
          <div className="text-center mx-auto pt-3 sm:pt-6 md:pt-8">
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-5xl">
              Videos
            </h2>
          </div>
          <div className="lg:mx-auto lg:max-w-7xl bg-primary-color flex flex-col">
            <VideoTabs 
              ClickVideoHandler = { this.handleClickOnVideo.bind(this)}
              { ...this.props } 
            />
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if(this.props.videoList != prevProps.videoList && this.state.videoId){
      // find from story
      let video = this.props.videoList.stories.find((video) => video.video_id === this.state.videoId);
      let activity = STORY
      if(!video){ 
        video = this.props.videoList.lessons.find((video) => video.video_id === this.state.videoId);
        activity = LESSON
      }
      this.setState({topicId: video.topic_id, activity: activity, duration: video.duration})
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.videoListFetch();
    // If there is video url, means no NavBar, need to fetch user
    if(this.props.match.params.video){ 
      this.props.userFetch();
    }
  }

  render() {
    return (
      this.pageContent()
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    videoList: state.video.videoList, 
    user: state.user.user,
    userLoaded: state.user.userLoaded
  }
};

export default connect(mapStateToProps, {
  videoListFetch, 
  userFetch
})(Videos);