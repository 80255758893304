const FLAT_FEMALE_MALE_FLAT = { 
  center: [300, 300], 
  width: 600, 
  height: 800, 
  viewBox: "66.13 41.67 231.17 308.17", 
  path: "M219.81,157.26c0,16.61,13.48,30.1,30.09,30.1c19.32,0,21.83-12.8,32.4-12.8c4.99,0,14.5,1.35,14.5,12.58v85.2  h-85.21c-11.22,0-12.57,9.53-12.57,14.5c0,10.57,12.8,13.07,12.8,32.4c0,16.62-13.49,30.1-30.1,30.1  c-16.62,0-30.11-13.48-30.11-30.1c0-19.33,12.8-21.83,12.8-32.4c0-4.98-1.35-14.5-12.57-14.5H66.63V42.17H296.8v85.21  c0,11.22-9.51,12.57-14.5,12.57c-10.57,0-13.07-12.8-32.4-12.8C233.29,127.15,219.81,140.64,219.81,157.26z"
}
const FLAT_MALE_FEMALE_MALE = { 
  center: [500, 300], 
  width: 1000, 
  height: 600, 
  viewBox:"219.31 41.67 385.16 231.17", 
  path: "M603.97,157.26c0,16.61-13.48,30.1-30.1,30.1c-19.33,0-21.83-12.8-32.4-12.8c-4.98,0-14.5,1.35-14.5,12.58v85.2  h-85.2c-11.23,0-12.57-9.51-12.57-14.5c0-10.57,12.79-13.07,12.79-32.39c0-16.62-13.49-30.1-30.1-30.1  c-16.62,0-30.11,13.48-30.11,30.1c0,19.31,12.8,21.82,12.8,32.39c0,4.99-1.35,14.5-12.57,14.5H296.8v-85.2  c0-11.23-9.51-12.58-14.5-12.58c-10.57,0-13.07,12.8-32.4,12.8c-16.61,0-30.09-13.49-30.09-30.1c0-16.62,13.48-30.11,30.09-30.11  c19.32,0,21.83,12.8,32.4,12.8c4.99,0,14.5-1.35,14.5-12.57V42.17h230.17v85.21c0,11.22,9.53,12.57,14.5,12.57  c10.57,0,13.07-12.8,32.4-12.8C590.5,127.15,603.97,140.64,603.97,157.26z"
}
const FLAT_FEMALE_MALE_FEMALE = {
  center: [300, 300], 
  width: 600, 
  height: 800, 
  viewBox:"526.48 41.67 231.17 308.17", 
  path: "M680.16,157.26c0,16.61,13.48,30.1,30.1,30.1c19.33,0,21.83-12.8,32.4-12.8c4.98,0,14.49,1.35,14.49,12.58v85.2  h-85.2c-11.23,0-12.57,9.53-12.57,14.5c0,10.57,12.79,13.07,12.79,32.4c0,16.62-13.48,30.1-30.1,30.1  c-16.62,0-30.11-13.48-30.11-30.1c0-19.33,12.8-21.83,12.8-32.4c0-4.98-1.35-14.5-12.57-14.5h-85.21v-85.2  c0-11.23,9.53-12.58,14.5-12.58c10.57,0,13.07,12.8,32.4,12.8c16.62,0,30.1-13.49,30.1-30.1c0-16.62-13.48-30.11-30.1-30.11  c-19.33,0-21.83,12.8-32.4,12.8c-4.98,0-14.5-1.35-14.5-12.57V42.17h230.17v85.21c0,11.22-9.51,12.57-14.49,12.57  c-10.57,0-13.07-12.8-32.4-12.8C693.63,127.15,680.16,140.64,680.16,157.26z"
}
const FLAT_FLAT_MALE_FEMALE = { 
  center: [300, 300], 
  width: 600, 
  height: 800,   
  viewBox:"526.48 41.67 231.17 308.17", 
  path:"M757.15,42.17c-76.72,0-153.45,0-230.17,0v85.21c0,11.22,9.53,12.57,14.5,12.57c10.57,0,13.07-12.8,32.4-12.8  c16.62,0,30.1,13.49,30.1,30.11c0,16.61-13.48,30.1-30.1,30.1c-19.33,0-21.83-12.8-32.4-12.8c-4.98,0-14.5,1.35-14.5,12.58v85.2  h85.21c11.22,0,12.57,9.53,12.57,14.5c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1c16.62,0,30.1-13.48,30.1-30.1  c0-19.33-12.79-21.83-12.79-32.4c0-4.98,1.34-14.5,12.57-14.5c28.4,0,56.8,0,85.2,0C757.15,195.61,757.15,118.89,757.15,42.17z"
}
const FEMALE_MALE_FEMALE_FLAT = { 
  center: [300, 300], 
  width: 800, 
  height: 600, 
  viewBox:"66.13 271.84 308.16 231.17", 
  path:"M373.79,387.43c0,16.62-13.48,30.1-30.09,30.1c-19.33,0-21.83-12.79-32.4-12.79c-4.99,0-14.5,1.34-14.5,12.57  v85.2h-85.21c-11.22,0-12.57-9.51-12.57-14.49c0-10.57,12.8-13.07,12.8-32.4c0-16.62-13.49-30.1-30.1-30.1  c-16.62,0-30.11,13.48-30.11,30.1c0,19.32,12.8,21.83,12.8,32.4c0,4.98-1.35,14.49-12.57,14.49H66.63V272.34h85.21  c11.22,0,12.57,9.53,12.57,14.5c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1c16.61,0,30.1-13.48,30.1-30.1  c0-19.33-12.8-21.83-12.8-32.4c0-4.98,1.35-14.5,12.57-14.5h85.21v85.21c0,11.22,9.51,12.57,14.5,12.57  c10.57,0,13.07-12.8,32.4-12.8C360.31,357.32,373.79,370.81,373.79,387.43z"
}
const MALE_FEMALE_MALE_FEMALE = { 
  center: [300, 500], 
  width: 600, 
  height: 1000,   
  viewBox: "296.3 194.85 231.18 385.16", 
  path: "M449.99,387.43c0,16.62,13.48,30.1,30.1,30.1c19.31,0,21.82-12.79,32.39-12.79c4.99,0,14.5,1.34,14.5,12.57  v85.2h-85.2c-11.23,0-12.57,9.53-12.57,14.5c0,10.57,12.79,13.07,12.79,32.4c0,16.62-13.49,30.1-30.1,30.1  c-16.62,0-30.11-13.48-30.11-30.1c0-19.33,12.8-21.83,12.8-32.4c0-4.98-1.35-14.5-12.57-14.5H296.8v-85.2  c0-11.23,9.51-12.57,14.5-12.57c10.57,0,13.07,12.79,32.4,12.79c16.61,0,30.09-13.48,30.09-30.1c0-16.62-13.48-30.11-30.09-30.11  c-19.33,0-21.83,12.8-32.4,12.8c-4.99,0-14.5-1.35-14.5-12.57v-85.21h85.21c11.22,0,12.57-9.51,12.57-14.5  c0-10.57-12.8-13.07-12.8-32.39c0-16.62,13.49-30.1,30.11-30.1c16.61,0,30.1,13.48,30.1,30.1c0,19.31-12.79,21.82-12.79,32.39  c0,4.99,1.34,14.5,12.57,14.5h85.2v85.21c0,11.22-9.51,12.57-14.5,12.57c-10.57,0-13.07-12.8-32.39-12.8  C463.46,357.32,449.99,370.81,449.99,387.43z"
}
const FEMALE_MALE_FEMALE_MALE = { 
  center: [500, 300], 
  width: 1000, 
  height: 600, 
  viewBox: "219.31 502.01 385.16 231.18", 
  path: "M603.97,617.6c0,16.62-13.48,30.11-30.1,30.11c-19.33,0-21.83-12.8-32.4-12.8c-4.98,0-14.5,1.34-14.5,12.57  v85.21h-85.2c-11.23,0-12.57-9.53-12.57-14.5c0-10.57,12.79-13.07,12.79-32.4c0-16.62-13.49-30.1-30.1-30.1  c-16.62,0-30.11,13.48-30.11,30.1c0,19.33,12.8,21.83,12.8,32.4c0,4.98-1.35,14.5-12.57,14.5H296.8v-85.21  c0-11.23-9.51-12.57-14.5-12.57c-10.57,0-13.07,12.8-32.4,12.8c-16.61,0-30.09-13.49-30.09-30.11c0-16.62,13.48-30.11,30.09-30.11  c19.32,0,21.83,12.8,32.4,12.8c4.99,0,14.5-1.35,14.5-12.57v-85.21h85.21c11.22,0,12.57,9.53,12.57,14.5  c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1c16.61,0,30.1-13.48,30.1-30.1c0-19.33-12.79-21.83-12.79-32.4  c0-4.98,1.34-14.5,12.57-14.5h85.2v85.21c0,11.22,9.53,12.57,14.5,12.57c10.57,0,13.07-12.8,32.4-12.8  C590.5,587.49,603.97,600.98,603.97,617.6z"
}
const FEMALE_FLAT_FEMALE_MALE = { 
  center: [500, 300], 
  width: 800, 
  height: 600, 
  viewBox: "1600.36 502.01 308.16 231.18", 
  path: "M1908.02,502.51v230.18h-85.2c-11.23,0-12.58-9.53-12.58-14.5c0-10.57,12.8-13.07,12.8-32.4  c0-16.62-13.49-30.1-30.1-30.1c-16.62,0-30.11,13.48-30.11,30.1c0,19.33,12.8,21.83,12.8,32.4c0,4.98-1.35,14.5-12.57,14.5h-85.21  v-85.21c0-11.23-9.51-12.57-14.5-12.57c-10.57,0-13.07,12.8-32.4,12.8c-16.61,0-30.09-13.49-30.09-30.11  c0-16.62,13.48-30.11,30.09-30.11c19.32,0,21.83,12.8,32.4,12.8c4.99,0,14.5-1.35,14.5-12.57v-85.21h85.21  c11.22,0,12.57,9.53,12.57,14.5c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1c16.61,0,30.1-13.48,30.1-30.1  c0-19.33-12.8-21.83-12.8-32.4c0-4.98,1.35-14.5,12.58-14.5H1908.02z"
}
const MALE_FEMALE_MALE_FLAT = { 
  center: [300, 500], 
  width: 600, 
  height: 1000, 
  viewBox: "89.31 232.94 231.17 385.17", 
  path: "M242.99,425.52c0,16.62,13.48,30.11,30.09,30.11c19.33,0,21.83-12.8,32.4-12.8c4.99,0,14.5,1.34,14.5,12.57  v85.2h-85.21c-0.7,0-1.36,0.04-1.99,0.12c-0.31,0.03-0.62,0.08-0.91,0.13c-0.3,0.05-0.58,0.1-0.86,0.18  c-0.29,0.05-0.55,0.13-0.81,0.22c-0.19,0.07-0.39,0.12-0.57,0.2c-0.56,0.19-1.08,0.44-1.56,0.73c-0.43,0.25-0.82,0.51-1.18,0.79  c-0.38,0.29-0.71,0.6-1.03,0.92c-0.19,0.21-0.38,0.42-0.56,0.62c-0.17,0.21-0.34,0.43-0.49,0.65c-0.16,0.23-0.31,0.45-0.44,0.69  c-0.14,0.23-0.29,0.47-0.4,0.71c-0.13,0.25-0.25,0.51-0.35,0.77c-0.1,0.23-0.21,0.47-0.29,0.7c-0.08,0.21-0.14,0.43-0.22,0.64  c-0.04,0.09-0.07,0.19-0.08,0.29c-0.14,0.44-0.26,0.9-0.35,1.34c-0.12,0.49-0.19,1-0.26,1.48c-0.04,0.25-0.07,0.48-0.09,0.71  c-0.05,0.48-0.08,0.94-0.1,1.38c-0.01,0.22-0.01,0.43-0.01,0.64c-0.01,0.21-0.01,0.4-0.01,0.6c0,10.57,12.8,13.07,12.8,32.4  c0,16.62-13.49,30.1-30.1,30.1c-16.62,0-30.11-13.48-30.11-30.1c0-19.33,12.8-21.83,12.8-32.4c0-0.19,0-0.39-0.01-0.6  c0-0.21,0-0.42-0.01-0.64c-0.03-0.44-0.05-0.9-0.1-1.38c-0.03-0.23-0.05-0.47-0.09-0.71c-0.06-0.48-0.14-0.99-0.26-1.48  c-0.09-0.44-0.21-0.9-0.35-1.34c-0.01-0.09-0.04-0.2-0.08-0.29c-0.07-0.21-0.14-0.43-0.22-0.64c-0.08-0.23-0.18-0.47-0.29-0.7  c-0.29-0.69-0.65-1.36-1.08-2c-0.08-0.1-0.14-0.22-0.22-0.32c-0.13-0.17-0.26-0.34-0.39-0.49c-0.18-0.21-0.36-0.42-0.56-0.62  c-0.31-0.32-0.64-0.62-1-0.91c-0.01,0-0.03-0.01-0.03-0.01c-0.45-0.36-0.96-0.69-1.51-0.97c-0.55-0.29-1.14-0.55-1.79-0.74  c-0.26-0.09-0.53-0.17-0.81-0.22c-0.27-0.08-0.56-0.13-0.86-0.18c0,0,0,0-0.01,0c-0.3-0.06-0.6-0.1-0.91-0.13  c-0.62-0.08-1.29-0.12-1.99-0.12H89.81V310.43h85.21c11.22,0,12.57-9.51,12.57-14.49c0-10.57-12.8-13.07-12.8-32.4  c0-16.62,13.49-30.1,30.11-30.1c16.61,0,30.1,13.48,30.1,30.1c0,19.33-12.8,21.83-12.8,32.4c0,4.98,1.35,14.49,12.57,14.49h85.21  v85.21c0,11.22-9.51,12.57-14.5,12.57c-10.57,0-13.07-12.8-32.4-12.8C256.47,395.41,242.99,408.9,242.99,425.52z"
}
const MALE_FLAT_MALE_FEMALE ={ 
  center: [300, 500], 
  width: 600, 
  height: 1000, 
  viewBox: "570.23 194.85 231.17 385.16", 
  path: "M703.12,517.01c0,10.57,12.8,13.07,12.8,32.4c0,16.62-13.49,30.1-30.1,30.1c-16.62,0-30.11-13.48-30.11-30.1  c0-19.33,12.8-21.83,12.8-32.4c0-4.98-1.35-14.5-12.57-14.5h-85.21v-85.2c0-11.23,9.51-12.57,14.5-12.57  c10.57,0,13.07,12.79,32.4,12.79c16.61,0,30.09-13.48,30.09-30.1c0-16.62-13.48-30.11-30.09-30.11c-19.33,0-21.83,12.8-32.4,12.8  c-4.99,0-14.5-1.35-14.5-12.57v-85.21h85.21c11.22,0,12.57-9.51,12.57-14.5c0-10.57-12.8-13.07-12.8-32.39  c0-16.62,13.49-30.1,30.11-30.1c16.61,0,30.1,13.48,30.1,30.1c0,19.31-12.8,21.82-12.8,32.39c0,4.99,1.35,14.5,12.58,14.5h85.2  V502.5h-85.2C704.48,502.5,703.12,512.03,703.12,517.01z"
}
const FEMALE_MALE_FLAT_FLAT = { 
  center: [300, 300], 
  width: 800, 
  height: 600, 
  viewBox:"55.52 748.68 308.16 231.19", 
  path:"M363.18,864.28c0,16.62-13.48,30.11-30.09,30.11c-19.33,0-21.83-12.8-32.4-12.8c-4.99,0-14.5,1.35-14.5,12.57  v85.21H56.02V749.2h85.21c0.7,0,1.36,0.04,1.99,0.1c0.31,0.04,0.62,0.08,0.91,0.13c0.01,0,0.01,0,0.01,0  c0.3,0.07,0.57,0.12,0.86,0.18c0.27,0.07,0.55,0.14,0.81,0.22c0.65,0.2,1.25,0.45,1.79,0.74c0.55,0.29,1.04,0.62,1.51,0.97  c0,0,0.01,0.01,0.03,0.01c0.36,0.29,0.69,0.58,1,0.91c0.19,0.21,0.38,0.42,0.56,0.62c0.13,0.16,0.26,0.32,0.39,0.49  c0.08,0.1,0.14,0.22,0.22,0.32c0.43,0.64,0.79,1.31,1.08,2c0.1,0.23,0.19,0.47,0.29,0.7c0.08,0.21,0.16,0.43,0.22,0.64  c0.04,0.09,0.07,0.19,0.08,0.29c0.14,0.44,0.26,0.9,0.35,1.34c0.12,0.49,0.2,1,0.26,1.48c0.04,0.25,0.06,0.48,0.09,0.71  c0.05,0.48,0.08,0.94,0.1,1.38c0.01,0.22,0.01,0.43,0.01,0.64c0.01,0.21,0.01,0.4,0.01,0.6c0,10.57-12.8,13.07-12.8,32.4  c0,16.62,13.49,30.1,30.11,30.1c16.61,0,30.1-13.48,30.1-30.1c0-19.33-12.8-21.83-12.8-32.4c0-0.19,0-0.39,0.01-0.6  c0-0.21,0-0.42,0.01-0.64c0.03-0.44,0.05-0.9,0.1-1.38c0.03-0.23,0.05-0.47,0.09-0.71c0.07-0.48,0.14-0.99,0.26-1.48  c0.09-0.44,0.21-0.9,0.35-1.34c0.01-0.09,0.04-0.2,0.08-0.29c0.08-0.21,0.14-0.43,0.22-0.64c0.09-0.23,0.18-0.47,0.29-0.7  c0.1-0.26,0.22-0.52,0.35-0.77c0.13-0.25,0.26-0.48,0.4-0.71c0.13-0.23,0.29-0.45,0.44-0.69c0.16-0.22,0.32-0.44,0.49-0.65  c0.18-0.21,0.36-0.42,0.56-0.62c0.31-0.32,0.65-0.64,1.03-0.92c0.36-0.29,0.75-0.55,1.18-0.79c0.48-0.27,1-0.52,1.56-0.73  c0.18-0.08,0.38-0.13,0.57-0.2c0.26-0.08,0.53-0.16,0.81-0.22c0.27-0.07,0.56-0.13,0.86-0.18c0.3-0.05,0.6-0.09,0.91-0.13  c0.62-0.07,1.29-0.1,1.99-0.1h85.21v85.2c0,11.23,9.51,12.57,14.5,12.57c10.57,0,13.07-12.8,32.4-12.8  C349.7,834.17,363.18,847.66,363.18,864.28z"
}
const MALE_FEMALE_FLAT_FEMALE = { 
  center: [300, 500], 
  width: 600, 
  height: 800, 
  viewBox:"296.3 655.19 231.18 308.17", 
  path: "M449.99,847.77c0,16.62,13.48,30.11,30.1,30.11c19.31,0,21.82-12.8,32.39-12.8c4.99,0,14.5,1.35,14.5,12.57  v85.21H296.8v-85.21c0-11.22,9.51-12.57,14.5-12.57c10.57,0,13.07,12.8,32.4,12.8c16.61,0,30.09-13.49,30.09-30.11  c0-16.62-13.48-30.11-30.09-30.11c-19.33,0-21.83,12.8-32.4,12.8c-4.99,0-14.5-1.34-14.5-12.57v-85.2h85.21  c11.22,0,12.57-9.53,12.57-14.5c0-10.57-12.8-13.07-12.8-32.4c0-16.62,13.49-30.1,30.11-30.1c16.61,0,30.1,13.48,30.1,30.1  c0,19.33-12.79,21.83-12.79,32.4c0,4.98,1.34,14.5,12.57,14.5h85.2v85.2c0,11.23-9.51,12.57-14.5,12.57  c-10.57,0-13.07-12.8-32.39-12.8C463.46,817.66,449.99,831.15,449.99,847.77z"
}
const FEMALE_MALE_FLAT_MALE = { 
  center: [500, 300], 
  width: 1000, 
  height: 600, 
  viewBox: "299.47 246.14 385.16 231.17", 
  path: "M684.13,361.72c0,16.62-13.48,30.11-30.1,30.11c-19.32,0-21.83-12.8-32.4-12.8c-4.98,0-14.5,1.35-14.5,12.57  v85.21H376.96V391.6c0-11.22-9.51-12.57-14.5-12.57c-10.57,0-13.07,12.8-32.39,12.8c-16.62,0-30.1-13.49-30.1-30.11  c0-16.62,13.48-30.11,30.1-30.11c19.31,0,21.82,12.8,32.39,12.8c4.99,0,14.5-1.34,14.5-12.57v-85.2h85.21c4.21,0,7.03,1.34,8.9,3.27  c3.13,3.22,3.66,8.11,3.66,11.22c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1c16.62,0,30.1-13.48,30.1-30.1  c0-19.33-12.79-21.83-12.79-32.4c0-3.11,0.52-7.99,3.65-11.22c1.88-1.94,4.7-3.27,8.92-3.27h85.2v85.2  c0,11.23,9.53,12.57,14.5,12.57c10.57,0,13.07-12.8,32.4-12.8C670.65,331.61,684.13,345.1,684.13,361.72z"
}
const FEMALE_FLAT_FLAT_MALE = {
  center: [500, 300], 
  width: 800, 
  height: 600,  
  viewBox:"567.06 767.91 308.16 231.17", 
  path:"M874.72,768.41v230.17H644.55v-85.21c0-11.22-9.51-12.57-14.5-12.57c-10.57,0-13.07,12.8-32.39,12.8  c-16.62,0-30.1-13.49-30.1-30.11c0-16.62,13.48-30.11,30.1-30.11c19.31,0,21.82,12.8,32.39,12.8c4.99,0,14.5-1.34,14.5-12.57v-85.2  h85.21c4.21,0,7.03,1.34,8.9,3.27c3.13,3.22,3.66,8.11,3.66,11.22c0,10.57-12.8,13.07-12.8,32.4c0,16.62,13.49,30.1,30.11,30.1  c16.62,0,30.1-13.48,30.1-30.1c0-19.33-12.79-21.83-12.79-32.4c0-3.11,0.52-7.99,3.65-11.22c1.88-1.94,4.7-3.27,8.92-3.27H874.72"
}

// 0 , 1 , 2 , 3 , 4 
// 5 , 6 , 7 , 8 , 9
// 10, 11, 12, 13, 14
// 15, 16, 17, 18, 19
export const initAdjacents = [ 
  [1, 5], // 0 
  [2, 6, 0], // 1
  [3, 7, 1],  //2
  [4, 8, 2], //3
  [9, 3], //4
  [0, 6, 10], //5
  [1, 7, 11, 5], // 6 
  [2, 8, 12, 6], // 7
  [3, 9, 13, 7],  //8
  [4, 14, 8], //9
  [5, 11, 15], //10
  [6, 12, 16, 10], //11
  [7, 13, 17, 11], // 12
  [8, 14, 18, 12], // 13
  [9, 19, 13],  //14
  [10, 16], //15
  [11, 17, 15], //16
  [12, 18, 16], //17
  [13, 19, 17], // 18 
  [14, 18], // 19
]

export const puzzleInfo = [ 
  // 0 
  { 
    matrix: [0, 0], 
    data: FLAT_FEMALE_MALE_FLAT, 
  },
  // 1
  { 
    matrix: [0, 1], 
    data: FLAT_MALE_FEMALE_MALE, 
  },
  // 2
  { 
    matrix: [0, 2], 
    data: FLAT_FEMALE_MALE_FEMALE, 
  },
  // 3
  { 
    matrix: [0, 3], 
    data: FLAT_MALE_FEMALE_MALE,  
  },
  // 4
  { 
    matrix: [0, 4], 
    data: FLAT_FLAT_MALE_FEMALE,  
  },
  // 5
  { 
    matrix: [1, 0], 
    data: FEMALE_MALE_FEMALE_FLAT,  
  },
  // 6
  { 
    matrix: [1, 1], 
    data: MALE_FEMALE_MALE_FEMALE,  
  },
  // 7
  { 
    matrix: [1, 2], 
    data: FEMALE_MALE_FEMALE_MALE,  
  },
  // 8
  { 
    matrix: [1, 3], 
    data: MALE_FEMALE_MALE_FEMALE,  
  },
  // 9
  { 
    matrix: [1, 4], 
    data: FEMALE_FLAT_FEMALE_MALE,  
  },
  // 10
  { 
    matrix: [2, 0], 
    data: MALE_FEMALE_MALE_FLAT,  
  },
  // 11
  { 
    matrix: [2, 1], 
    data: FEMALE_MALE_FEMALE_MALE,  
  },
  // 12
  { 
    matrix: [2, 2], 
    data: MALE_FEMALE_MALE_FEMALE,  
  },
  // 13
  { 
    matrix: [2, 3], 
    data: FEMALE_MALE_FEMALE_MALE,  
  },
  // 14
  { 
    matrix: [2, 4], 
    data: MALE_FLAT_MALE_FEMALE,  
  },
  // 15
  { 
    matrix: [3, 0], 
    data: FEMALE_MALE_FLAT_FLAT,  
  },
  // 16
  { 
    matrix: [3, 1], 
    data: MALE_FEMALE_FLAT_FEMALE,  
  },
  // 17
  { 
    matrix: [3, 2], 
    data: FEMALE_MALE_FLAT_MALE,  
  },
  // 18
  { 
    matrix: [3, 3], 
    data: MALE_FEMALE_FLAT_FEMALE,  
  },
  // 19
  { 
    matrix: [3, 4], 
    data: FEMALE_FLAT_FLAT_MALE,  
  },
]

export const svgInfo = [ 
  FLAT_FEMALE_MALE_FLAT, 
  FLAT_MALE_FEMALE_MALE,
  FLAT_FEMALE_MALE_FEMALE,
  FLAT_MALE_FEMALE_MALE, 
  FLAT_FLAT_MALE_FEMALE, 
  FEMALE_MALE_FEMALE_FLAT, 
  MALE_FEMALE_MALE_FEMALE, 
  FEMALE_MALE_FEMALE_MALE, 
  MALE_FEMALE_MALE_FEMALE, 
  FEMALE_FLAT_FEMALE_MALE, 
  MALE_FEMALE_MALE_FLAT, 
  FEMALE_MALE_FEMALE_MALE, 
  MALE_FEMALE_MALE_FEMALE, 
  FEMALE_MALE_FEMALE_MALE, 
  MALE_FLAT_MALE_FEMALE, 
  FEMALE_MALE_FLAT_FLAT, 
  MALE_FEMALE_FLAT_FEMALE, 
  FEMALE_MALE_FLAT_MALE, 
  MALE_FEMALE_FLAT_FEMALE, 
  FEMALE_FLAT_FLAT_MALE
]