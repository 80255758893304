import React, { Component } from 'react';
import { connect } from 'react-redux';

import { itemsInfo } from './FindOutData'

import '../../../stylesheets/gameSetting.css';

class Item extends React.Component {
  constructor(props) {
    super(props);
  }

  item(){ 
    if(this.props.isSelected){ 
      return <div/> 
    }

    let itemData = itemsInfo[this.props.gameId][this.props.index]
    return(
      <div> 
        <div 
          className="z-20" 
          style = {{
            position: 'absolute',    
            top: `${itemData.top * this.props.bgSizeRate + 150 * this.props.sizeRate}px`,
            left: `${itemData.left * this.props.bgSizeRate + 120 * this.props.sizeRate}px`,
            width: `${itemData.width * this.props.bgSizeRate}px`, 
            height: `${itemData.height * this.props.bgSizeRate}px`,
          }}
        >
          <img 
            draggable="false"
            className="unselectable"
            style={{
            objectFit: "fill",
            pointerEvents: "none"}} 
            src={(`/images/games/find_out/${this.props.folderName}/items/${this.props.index}.webp`)}  />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div onClick={this.props.clickHandler}>
        {this.item()}
      </div>
    );
  }
}


export default connect()(Item);
