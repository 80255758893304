import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react'
import { SpeakerXMarkIcon, SpeakerWaveIcon, PlayIcon } from '@heroicons/react/24/outline'

import MinifigureImage from '../../../assets/games/mf_teacher.png'
import '../../../stylesheets/gameSetting.css';


function volumeIcon(audio){ 
  if(audio == true){ 
    return <SpeakerWaveIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }else{
    return <SpeakerXMarkIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }
}

export default function GameTitleModal(props) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef()
  return (

    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/*}
            style={{backgroundImage: "url(/images/the_creation/cover.jpg)", backgroundSize: "auto", backgroundColor: "rgba(255,255,255,0.4)", backgroundBlendMode:"overlay", backgroundRepeat: "no-repeat", backgroundPosition:"center"}} 
            {*/}
            <div className="bg-primary-dark inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle w-full w-1/2 max-w-md">
              <div className="px-2 py-2 md:px-3 md:py-3 bg-primary-color">
                <div className="mb-4 content-between">
                  <div className="text-center">
                    <Dialog.Title as="h3" className="mt-2 text-white modal-text-title text-3xl md:text-4xl leading-6 font-bold">
                       Game Intro
                    </Dialog.Title>
                  </div>
                </div>
              </div>
              <div className="justify-items-center pt-2 my-4 grid grid-cols-2">
                <button className="w-24 md:w-32 fancy-button orange"
                  onClick={props.audioToggleHandler}
                >
                  <span className="flex items-center justify-center h-10 w-10 md:h-12 md:w-12 ">
                    {volumeIcon(props.audio)}
                  </span>
                 
                </button>
                <button className="w-24 md:w-32 fancy-button green grid grid-cols-2"
                  onClick={props.startPlayHandler}
                >
                  <span> 
                    <PlayIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                  </span> 
                  <span className="my-auto hidden md:block">
                    Watch
                  </span>
                </button>
              </div>
              <div className="p-2 md:p-4 text-center text-white game-desc ">
                <Link className="plan-link"
                  onClick={(e) => window.location = `/users/sign_in` } 
                >Login </Link>
                to skip game intro
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}