import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { VscBook } from "react-icons/vsc";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { MdOutlineVideoCameraBack } from "react-icons/md"
import { BiSolidBible } from "react-icons/bi";

import '../../stylesheets/userSettings.css';

const refConstant = { "story": 1, "lesson": 2, "others": 3} 
const tabNameConstant = { 1: "story", 2: "lesson", 3: "others"}

const otherVideos = [
  { id: 1, video_id: "JAMmueuIldk", topic: "All About Christmas"}, 
  { id: 2, video_id: "27XBaQlYoHQ", topic: 'A "Secret" Christmas' }
]

class VideoTabs extends React.Component{
  constructor(props) {
    super(props);
    let activeTab = 1
    let defaultTab = queryString.parse(props.location.search).tab
    if(defaultTab){ 
      activeTab = refConstant[defaultTab]
    }
    this.state = { 
      activeTab: activeTab, 
      selectedItem: null
    };
  }
  
  onToggleTab = (index) => { 
    this.setState({activeTab: index})
    if(index == 1){ 
      this.props.history.push({
        search: "" 
      })
    }else{ 
      let tabName = tabNameConstant[index]
      this.props.history.push({
        search: "?" + new URLSearchParams({tab: tabName}).toString()
      })

    } 
  }

  getActiveTabClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-tabs"
    }
    return "inactive-avatar-tabs"
  }

  getActiveContentClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-content"
    }
    return ""
  }

  // activity used in progress owner to track user watching video
  videoItem = (video, activity) => { 
    return(
      <li key={video.id} className="relative">
        <div className="bg-white aspect-square flex h-full items-stretch flex-col rounded-lg cursor-pointer hover:opacity-75"
          onClick={this.props.ClickVideoHandler.bind(this, video.video_id, video.topic_id, video.duration, activity)}
        >
          <div> 
            <img src={`http://img.youtube.com/vi/${video.video_id}/0.jpg`} alt="" className="rounded-t-lg object-contain pointer-events-none" />
          </div> 
          <div className ="relative m-auto w-full">             
            <div className="flex text-center">
              <div className="m-auto">
                <h2 style={{fontFamily: 'Luckiest Guy'}} className="lg:px-4 mt-2 leading-4 text-1xl text-primary-dark font-extrabold font-sans tracking-wide md:text-2xl lg:text-3xl"> 
                  {video.topic}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }
 
  storiesItem(){
    let videoList = this.props.videoList
    if(Object.keys(videoList).length){
      return(
        <div className="flex flex-col">
          <div className="min-h-0 flex-1 flex overflow-auto">
            <div className="w-full mx-auto">
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">

                { videoList.stories.map((story) => (
                  this.videoItem(story, refConstant["story"])
                ))
                }
              </ul>
            </div>
          </div> 
        </div> 
      )
    }
  } 
  lessonsItem(){
    let videoList = this.props.videoList
    if(Object.keys(videoList).length){
      return(
        <div className="flex flex-col">
          <div className="min-h-0 flex-1 flex overflow-auto">
            <div className="w-full mx-auto">
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">

                { videoList.lessons.map((lesson) => (
                  this.videoItem(lesson, refConstant["lesson"])
                ))
                }
              </ul>
            </div>
          </div> 
        </div> 
      )
    }
  } 

  othersItem(){
    return(
      <div className="flex flex-col">
        <div className="min-h-0 flex-1 flex overflow-auto">
          <div className="w-full mx-auto">
            <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">

              { otherVideos.map((video) => (
                this.videoItem(video, refConstant["others"])
              ))
              }
            </ul>
          </div>
        </div> 
      </div> 
    )
  } 

  render(){
    return(
      <div className="sm:px-1 lg:px-8 ">
        <div className="flex px-1 md:px-0 gap-x-1 md:gap-x-4 avatar-tab-list">
          <div className={`md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(1)}`}
            onClick={() => this.onToggleTab(1)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Story</h3>
            </div>
            <div className="mt-0"> 
              <MdOutlineOndemandVideo className="text-3xl text-white"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(2)}`}
            onClick={() => this.onToggleTab(2)}
          >  
            <div className="-mt-1 sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-bold leading-10 tracking-wider text-white">Lesson</h3>
            </div>
            <div className="mt-0"> 
              <BiSolidBible className="text-3xl text-white"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(3)}`}
            onClick={() => this.onToggleTab(3)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Others</h3>
            </div>
            <div className="mt-0"> 
              <MdOutlineVideoCameraBack className="text-3xl text-white"/>
            </div>
          </div>
        </div>
        <div>
          <div className={`avatar-content ${this.getActiveContentClass(1)}`}>
            <div className="w-full py-5">
              {this.storiesItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(2)}`}>
            <div className="w-full py-5">
              {this.lessonsItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(3)}`}>
            <div className="w-full py-5">
              {this.othersItem()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
  }
};

export default connect(mapStateToProps, {
})(VideoTabs);