import {
  FIND_OUT_UPDATE,
  FIND_OUT_INIT
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    isSelectedArray: [],
    selectedIds: [],
  };
  
export default (state = INITIAL_STATE, action) => { 
  switch(action.type) {   
    case FIND_OUT_INIT: 
      return { ...state, isSelectedArray:action.isSelectedArray, selectedIds: []}      
    case FIND_OUT_UPDATE:  
      const newArray = [...state.isSelectedArray]; //making a new array
      newArray[action.index] = true//changing value in the new array
      return { 
        ...state, //copying the original state
        isSelectedArray: newArray, //reassigning to new array
        selectedIds: [...state.selectedIds, action.index]
      }
    default:  
      return state; 
  } 
}; 