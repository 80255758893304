import {
    ARTICLE_FETCH_SUCCESS, ARTICLE_SAVE_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    data: {
     ArticleType: "Craft"
    }, 
    updatedId: 0
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case ARTICLE_FETCH_SUCCESS: 
        return { ...state, data: action.payload };  
      case ARTICLE_SAVE_SUCCESS:   
        state = INITIAL_STATE; 
        return { ...state, updatedId: action.payload.id};
      default:
        return state;
    }
  };

