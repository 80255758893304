import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import NavBar from '../../elements/NavBar'
import Footer from '../../elements/Footer'
import QuizQuestion from './QuizQuestion'
import WellDoneModal from "../Games/Modals/WellDoneModal"
import GameOverModal from "../Games/Modals/GameOverModal"

import { quizFetch } from '../../actions/quizActions';
import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';

import { shuffleArray } from '../../helpers/MathHelper'
import { trophyConstant } from '../../helpers/ConstantHelper'

import '../../stylesheets/quiz.css';


class QuizRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      windowHeight: window.innerHeight, 
      windowWidth: window.innerWidth, 
      answerDivRefArray: [],
      qnNumber: 0, 
      answerSequences: [], 
      answerIndex: [],
      selectedAnswer: null, 
      selectedCorrect: false, 
      correctCount: 0, 
      sequenceUpdated: false, 
      wellDoneModal: false, 
      tryAgainModal: false,
      trophy: "", 
      activeUser: false
    }
    for(var i = 0; i < 5; i++ ){ 
      this.state.answerDivRefArray.push(createRef())
    }
  }

  onWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    if(this.state.correctCount == this.props.questions.length){ 
      window.location = "/quizzes"
    }else{ 
      this.setState({qnNumber: 0, selectedAnswer: null, correctCount: 0})
    }
  }

  onTryAgainComplete(){ 
    this.setState({tryAgainModal: false})
    this.setState({qnNumber: 0, selectedAnswer: null})
  }

  onWindowSizeChange(){
    this.setState({windowHeight: window.innerHeight, windowWidth: window.innerWidth })
  };

  onSelectAnswer(index){ 
    let correct = this.state.answerIndex[this.state.qnNumber] == index
    let correctCount = this.state.correctCount
    let trophy = ""

    this.setState( {selectedAnswer: index, selectedCorrect: correct} ) 
    if(correct) { correctCount++ }
    this.setState( {correctCount: correctCount} )
    if(this.state.qnNumber == this.props.questions.length - 1){ 
      if(correctCount > 0){ 
        // All correct
        if(correctCount == this.props.questions.length){
          if(!this.props.trophy || this.props.trophy.gold != true){ 
            trophy = "gold" 
          }
        }else if(correctCount > this.props.questions.length/2){ 
          if(!this.props.trophy || this.props.trophy.silver != true){ 
            trophy = "silver" 
          }
        }else{ 
          if(!this.props.trophy || this.props.trophy.bronze != true){ 
            trophy = "bronze" 
          }
        }
        if(this.state.activeUser && trophy){ 
          this.props.userTrophyCreate("quiz", this.props.match.params.topic, trophyConstant[trophy]) 
        }
        this.setState({wellDoneModal: true, trophy: trophy})
      }else{ 
        this.setState({tryAgainModal: true})
      }
      return
    }
    setTimeout(() => {
      let number = this.state.qnNumber
      number++
      this.setState( {qnNumber: number, selectedAnswer: null} )
    }, 800);
  }

  onLoad(){ 
    return
    if(this.state.answerDivRefArray[0].current == null){ return; }
    
    let container = this.state.answerDivRefArray[0].current
    // Reset font-size to 100% to begin
    container.style.fontSize = "100%";
    // Check if the text is wider than its container,
    // if so then reduce font-size
    if (container.scrollWidth > container.clientWidth) {
        container.style.fontSize = "95%";
    }
  }

  answerWidth(){ 
    if(this.state.windowWidth > 1200){ 
      return `${100/this.state.answerSequences[this.state.qnNumber].length}%`
    }else{ 
      return `100%`
    }
  }

  answerHeight(){ 
    if(this.state.windowWidth > 1200){ 
      return `100%`
    }else{ 
      return `${100/this.state.answerSequences[this.state.qnNumber].length}%`
    }
  }

  answerArray(){ 
    let answer = [ "Day 7", "Day 3", "Day 1", "Day 5"]
    let shuffled = shuffleArray(this.state.answerArray)
    let arrays = shuffled.map((array) => (
        answer[array]
      ))
    return arrays
  }

  generateParagraph(index) {
    let paragraph = this.answerDivRefArray[index].current
    let paragraphWordArray = this.paragraphWordArray(answerArray[index])
    for(let i = 0; i < paragraphWordArray.length; i++){
        paragraphWordArray[i] = ' ' + paragraphWordArray[i];
    }

    let paragraphSpans = '';

    for (let i = 0; i < paragraphWordArray.length; i++) {

      paragraphSpans += `<span class='spans' id='spanID${i}'>${paragraphWordArray[i]}</span>`;

    };
     
    paragraph.innerHTML = `${paragraphSpans}`;

};

  paragraphWordArray(paragraph){ 
    return( 
      paragraph.replace(/\s+/g, " ").toLowerCase().replace(/\,|\?|\!|\:|\./g,'').trim().split(' ')
    )
  }

  scaleFontSize() {
    if(this.state.answerDivRefArray[0].current == null){ return; }
    let container = this.state.answerDivRefArray[0].current
    // Reset font-size to 100% to begin
    container.style.fontSize = "100%";

    // Check if the text is wider than its container,
    // if so then reduce font-size
    if (container.scrollWidth > container.clientWidth) {
        container.style.fontSize = "70%";
    }
}
  scaleFontVW(){ 
    let paragraph = document.getElementById("paragraph");
    let paragraphContainer = document.getElementById("pc")
    let spans = document.getElementsByTagName("span");

    let style = window.getComputedStyle(spans[0], null).getPropertyValue('font-size');
    let fontSize = parseFloat(style); 

    while(paragraph.scrollHeight >= paragraphContainer.clientHeight) {
      fontSize -= 1;
      paragraph.style.fontSize = fontSize+"px";
      paragraph.style.lineHeight = fontSize*0.2 + fontSize + "px";
    }
  }

  question(){ 
    return(
      this.props.questions[this.state.qnNumber]
    )
  }

  answerOptions(){ 
    let options = this.props.answers[this.state.qnNumber]
    let answers = this.state.answerSequences[this.state.qnNumber].map((array) => (options[array]))
  
    return answers
  }

  quizItem(){ 
    if(this.props.questions.length && this.state.sequenceUpdated){ 
      return(
        <div className="m-auto flex flex-col">
          <div className="flex-1 flex overflow-auto">
            <QuizQuestion { ...this.props } 
              parent = { this }
              question = { this.question() }
              answerOptions = { this.answerOptions() }
              handleSelectAnswer = { this.onSelectAnswer }
              answerDivRefArray = { this.state.answerDivRefArray }
              divHeight = { 0.87 * this.state.windowHeight }
              answerWidth = { this.answerWidth() }
              answerHeight = { this.answerHeight() }
              selectedAnswer = { this.state.selectedAnswer }
              selectedCorrect = { this.state.selectedCorrect }
            />
          </div> 
        </div>
      )
    }
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { `${this.state.correctCount} / ${this.props.questions.length}` }
          descriptions = { [] }
          trophy = { this.state.trophy }
          searchParams = { '?tab=quiz'}
          quiz = { this.props.topic }
          activeUser = { this.state.activeUser }
          endHandler = { this.onWellDoneComplete.bind(this) }
        />
      )
    }
    return <div/>
  }

  tryAgainModal(){
    if(this.state.tryAgainModal==true){ 
      return( 
        <GameOverModal
          open = { true }
          endHandler = { this.onTryAgainComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  componentDidUpdate(prevProps) {
    let sequences = []
    let indexes = []
    if (this.props.questions != prevProps.questions){
      for(var i = 0; i < this.props.questions.length; i++ ){
        let shuffled = shuffleArray([0, 1, 2, 3])
        sequences.push(shuffled)
        indexes.push(shuffled.indexOf(0))
      }
      this.setState({answerSequences: sequences, answerIndex:indexes, sequenceUpdated: true })
    }
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true})
        this.props.userTrophyFetch("quiz", this.props.match.params.topic)
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowSizeChange.bind(this));
    window.addEventListener('load', this.onLoad.bind(this));
    this.props.quizFetch(this.props.match.params.topic)
    this.props.userFetch()
  }

  render() {
    // user loaded only render NavBar avoid double calling userFetch
    if(this.props.userLoaded){
      return(
        <>
          <div className="bg-primary-color"
            style={{height:`${1.05 * this.state.windowHeight}px`}}
          > 
            {this.wellDoneModal()}
            {this.tryAgainModal()}
            <NavBar/>
            { this.quizItem() }
          </div>
          <Footer/>
        </>
      )
    }
    return <div/>
  }
}

const mapStateToProps = (state) => {
  return { 
    questions: state.quiz.questions,
    answers: state.quiz.answers, 
    topic: state.quiz.topic,
    user: state.user.user,
    userLoaded: state.user.userLoaded,
    trophy: state.user.trophy
  }
};

export default connect(mapStateToProps, {
  quizFetch, 
  userTrophyFetch, 
  userFetch, 
  userTrophyCreate
}) (QuizRenderer);