import {
  SPOT_DIFF_UPDATE,
  SPOT_DIFF_INIT
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    items: [], 
    selectedIds: [],
  };
  
export default (state = INITIAL_STATE, action) => { 
  switch(action.type) {   
    case SPOT_DIFF_INIT: 
      return { ...state, items: action.payload, selectedIds: []}      
    case SPOT_DIFF_UPDATE:  
      console.log(action.index)
      const newArray = [...state.items]; //making a new array
      newArray[action.index].selected = action.value//changing value in the new array
      return { 
        ...state, //copying the original state
        items: newArray, //reassingning cards to new array
        selectedIds: [...state.selectedIds, action.index]
      }
    default:  
      return state; 
  } 
}; 