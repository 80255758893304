import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import YouTube from 'react-youtube'
import { Link } from 'react-router-dom';

import GameRuleModal from "./Modals/GameRuleModal"
import HintModal from "./PaintTheWorld/HintModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"
import Notification from "../../elements/Notification"
import GameIntroVideo from '../../elements/GameIntroVideo'
import gameRule from '../../assets/games/paint_the_world/game_rule.json'

import { ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon, SpeakerWaveIcon, SpeakerXMarkIcon, LightBulbIcon } from '@heroicons/react/24/outline'

import { paintTheWorldUpdate, paintTheWorldFetch } from '../../actions/paintTheWorldActions';
import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';
import { trophyConstant } from '../../helpers/ConstantHelper'
import { introVideoText, videoFrameWidth } from '../../helpers/GameHelper'
import Frame from '../../assets/games/paint_the_world/game_frame.png'
import PauseGif from '../../assets/animations/pause.gif'
import WrongGif from '../../assets/animations/wrong.gif'
import ClapGif from '../../assets/animations/clap.gif'
import WellDoneGif from '../../assets/animations/well_done.gif'

import '../../stylesheets/gameSetting.css';

const actualBgWidth = 1600 
const actualBgHeight = 900 
const actualFrameWidth = 1609 
const actualFrameHeight = 1056
const actualImageWidth = 695
const actualImageHeight = 600

const buttonTop = 20
const hintButtonLeft = 949
const firstButtonLeft = 1349
const secondButtonLeft = 1482
const scoreTop = 4 
const scoreLeft = 639 
const bgTop = 152 
const bgLeft = 4.5
const dayTop = 4
const dayLeft = 60 

const goldScore = 375
const silverScore = 320

const frameInfo = [ 
  { // landscape
    flex: "flex-col",
    divFlex: "flex flex-row"
  }, 
  { // portrait
    flex: "flex-row",
    divFlex: ""
  }
]
const frameTypeString = ["landscape", "portrait"]
const frameType = {"landscape": 0, "portrait": 1}

const intervalStatements = [ 
                    ["God called the light - 'day' and the darkness - 'night'.", "That was a good start. Keep it going!"], 
                    [ "Sky separates the waters of heavens from the waters of the earth.", "Nice. Now moving on to day 3!"], 
                    [ "The land now produced all sorts of vegetation.", "Nice job, you are half way through!"], 
                    [ "Lights in the sky govern the day and night.", "Wow, the world starting to look beautiful!"], 
                    [ "God saw everything was good.", "Fantastic, we are entering the final day!"], 
                    [ "God had finished his work of creation", "Well Done, you made it"]
                  ]
const complete2 = [ "That was a good start. Keep it going!", 
                    "Now moving on to day 3!", 
                    "Nice job, you are half way done!", 
                    "The world starting to look beautiful!", 
                    "Fantastic, we are entering the final day!"
                    ]
const statement1 = [ "The earth was formless and empty, and darkness covered the deep waters", 
                    "God called the light 'day' and the darkness 'night'", 
                    "Sky separates the waters of heavens from the waters of the earth", 
                    "The land produced all sorts of vegetation now", 
                    "Lights in the sky govern the day and night", 
                    "God saw everything was good", 
                    "God had finished his work of creation"]
const statement2 = [ "Day 1 of Creation - First Day", 
                    "Day 2 of Creation", 
                    "Day 3 of Creation", 
                    "Day 4 of Creation", 
                    "Day 5 of Creation", 
                    "Day 6 of Creation - Last Day", 
                    "Well Done" ]
const hintTitle = [ "Genesis 1:3", 
                    "Genesis 1:6,8", 
                    "Genesis 1:9-10", 
                    "Genesis 1:11", 
                    "Genesis 1:14", 
                    "Genesis 1:20", 
                    "Genesis 1:24", 
                    "Genesis 1:26" ]      
const hintDescription = [ "God said, 'Let there be light' and there was light", 
                          "God said, 'Let there be a space between the waters, to separate the waters of the heavens from the waters of the earth. God called the space - sky'", 
                          "God said, 'Let the waters beneath the sky flow together into one place, so dry ground may appear. God called the dry ground - land and the waters - seas'.",
                          "God said, 'Let the land sprout with vegetation-every sort of seed-bearing plant, and trees that grow seed-bearing fruit'", 
                          "God said, 'Let lights appear in the sky to separate the day from the night. Let them be signs to mark the seasons, days and years'", 
                          "God said, 'Let the water swarm with fish and other life. Let the skies be filled with birds of every kind'", 
                          "God said, 'Let the earth produce every sort of animal, each producing offspring of the same kind - livestock, small animals that scrury along the ground and wild animals'", 
                          "God said, 'Let us make human beings in our image, to be like us'"]                 
class PaintTheWorld extends React.Component {

  constructor(props) {
    super(props);
    let randomList = this.randomNum(27)
    this.state = {
      day: 1,
      randomList: randomList,
      hint: false, 
      sequence: 1, 
      audioOn: true, 
      bgMusic: new Audio('/audio/background/life_of_riley.mp3'), 
      gameRule: false, 
      pause: false, 
      notification: false,
      notificationDesc: "", 
      intervalModal: false, 
      score: 0,
      bonusScore: 5, 
      error: false,
      errorOnDay: false,
      frameType: 0, 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      pauseAnimation: PauseGif,
      wrongAnimation: WrongGif, 
      clapAnimation: ClapGif, 
      gameState: "pending",
      wellDoneModal: false, 
      endVidRef: React.createRef(),
      imageWidth: 0,
      imageHeight: 0, 
      totalWidth: null, 
      trophy: "", 
      activeUser: false, 
      introVideoStarted: false
    }
  }

  onWindowSizeChange(){
    setTimeout(() => {
      var bgWidth = this.backgroundWidth(window.outerWidth, window.outerHeight)
      var bgHeight = this.backgroundHeight(bgWidth)
      var sizeRate = bgWidth/actualBgWidth
      this.setState({ bgWidth: bgWidth, bgHeight:bgHeight });
      this.setState({sizeRate: bgWidth/actualBgWidth})   
    }, 100) 
  };

  handleExit(){ 
    this.props.history.goBack()
  }

  handleIntroPlay(){
    this.setState({introVideoStarted: true})
  }

  handleIntroEnd(){ 
    this.setState({gameState: "ready", gameRule: true})
  }
  
  handleStartPlay(){
    this.setState({gameState: "running"})
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    }
    this.setState({gameRule: false})
  }

  handleSelectImage(id, ev) {
    ev.preventDefault();
    var entry = this.props.images.find(x => x.id == id)
    if(entry.selected){ 
      return
    }
    if(entry.day == this.state.day){ 
      if(entry.sequence == this.state.sequence){
        var score = 10
        if(this.state.audioOn && entry.sound){ 
          var audio = new Audio('/audio/paint_the_world/' + entry.sound)
          audio.play()
        }
        this.props.paintTheWorldUpdate(entry.id, true)
        if(this.evaluateDayCompleted()){ 
          if(this.state.errorOnDay == false){ 
            // bonus points for no error for a day
            score = score + this.state.bonusScore
            this.setState({bonusScore: this.state.bonusScore + 5})
          }
          this.setState({ sequence: 1 });
          this.setState({ errorOnDay: false})

          setTimeout(() => { 
            if(entry.day < 6){
              this.setState({intervalModal: true})
            }else{ 
              // > goldScore -- GOLD 
              // > silverScore -- SILVER
              // Finish Game -- BRONZE
              let trophy = null
              if(this.state.score >= goldScore){
                if(!this.props.trophy || this.props.trophy.gold != true){ 
                  trophy = "gold" 
                }
              }else if(this.state.score >= silverScore){ 
                if(!this.props.trophy || this.props.trophy.silver != true){ 
                  trophy = "silver" 
                }
              }else{ 
                if(!this.props.trophy || this.props.trophy.bronze != true){ 
                  trophy = "bronze" 
                }
              }
              if(this.state.activeUser && trophy){ 
                this.props.userTrophyCreate("game", "paint-the-world", trophyConstant[trophy]) 
              }
              this.setState({gameState: "done", wellDoneModal: true, trophy: trophy})
              if(this.state.audioOn){
                this.state.bgMusic.pause()
                this.state.cheerAudio.play()
              }
            }
          }, 400)
          
        }else{ 
          this.evaluateSequenceCompleted()
        }
        this.setState({error: false}) 
        this.setState({score: this.state.score + score})
      }
      else{ 
        this.setState({error: true})
        this.setState({bonusScore: 5}) // reset bonus score once wrong
        this.setState({errorOnDay: true})
        this.setState({notification:true})
        this.setState({notificationDesc: ["Sorry, something else were created first.", "Pick another one or try the hint."]})
      }
      
    }
    else{ 
      this.setState({error: true})
      this.setState({errorOnDay: true})
      this.setState({notification:true})
      this.setState({notificationDesc: ["Oh no, this is not created on this day.","Pick another one or try the hint."]})
    }
  }

  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
    this.setState({gameState: "endVideo"})
    this.state.endVidRef.current.play();
  }

  handleHint(){
    this.setState({hint: true})
  }

  handlePause(){ 
    this.state.bgMusic.pause();
    this.setState({pause: true})
  } 

  handleTabInactive(){
    this.state.bgMusic.pause();
    switch(this.state.gameState){ 
      case "running": 
        this.setState({pause: true})
        break;
      case "endVideo":
        this.state.endVidRef.current.pause();
        break;
      default:
    }
  };

  handleContextMenu(e){ 
    if(isMobile){ 
      e.preventDefault()
    }
  }

  handleUnpause(){ 
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleRestart(){ 
    this.setState({gameState: "running"})
    for(var i = 1; i < this.props.images.length; i++ ){ 
      this.props.paintTheWorldUpdate(i, false)
    }
    this.setState({day: 1})
    this.setState({sequence: 1})
    this.setState({pause: false})
    this.setState({score: 0})
    if(this.state.audioOn == true){ 
      this.state.bgMusic.play()
    }
    //Randomize images 
    this.setState({randomList: this.randomNum(27)})
  }

  handleCloseHintModal(){ 
    this.setState({hint: false})
  }

  handleCloseNotification(){ 
    this.setState({wrongAnimation: ""})
    setTimeout(() => { 
      this.setState({notification: false})
      this.setState({wrongAnimation: WrongGif})
    }, 0)
  }

  handleCloseIntervalModal(){ 
    this.setState({clapAnimation: ""})
    this.setState({day: this.state.day + 1})
    setTimeout(() => { 
      this.setState({intervalModal: false})
      this.setState({clapAnimation: ClapGif})
      
    }, 0)
  }

  handleNotificationHint(){ 
    this.setState({wrongAnimation: ""})
    setTimeout(() => { 
      this.setState({notification: false})
      this.setState({wrongAnimation: WrongGif})
    }, 0)
    setTimeout(() => { 
      this.setState({hint: true})
    }, 1)
  }

  randomNum(max){ 
    var arr = [];
    while(arr.length < max){
      var r = Math.floor(Math.random() * max) + 1;
      if(arr.indexOf(r) === -1) arr.push(r);
    }
    return arr
  }

  getImagesOnDay(images, day) {
    return images.map((elm, idx) => elm.day == day ? elm.id : '').filter(String);
  }

  evaluateDayCompleted(){ 
    var images = this.getImagesOnDay(this.props.images, this.state.day)
    for(var i = 0; i < images.length; i++ ){ 
      var index = images[i]
      // Complete the day if only all the images of that day has been selected
      if(this.props.images[index].selected == false){ 
        return false
      }
    }
    return true
  }

  getImagesOnSequence(seq) {
    var images = [] 
    for(var i = 0; i < this.props.images.length; i++ ){ 
      if(this.props.images[i].day == this.state.day){ 
        images.push(this.props.images[i])
      }
    }
    return images.map((elm, idx) => elm.sequence == seq ? elm.id : '').filter(String);
  }

  evaluateSequenceCompleted(){ 
    var images = this.getImagesOnSequence(this.state.sequence)
    for(var i = 0; i < images.length; i++ ){ 
      var index = images[i]
      // Complete the day if only all the images of that sequence has been selected
      if(this.props.images[index].selected == false){ 
        return
      }
    }
    var sequence = this.state.sequence + 1
    this.setState({ sequence: sequence });
  }


  imagesToBeSelected(range){ 
    var images = [] 
    let total = this.state.randomList.length - 1
    const start = range[0]
    const end = range[1]

    let indexes = this.state.randomList
    let list = indexes.slice(start, end)
    for (const index of list) {
      var entry = this.props.images.find(x => x.id == index)
      images.push(entry)
    } 
    return images 
  }

  compositeImages(){ 
    var images = [] 
    for(var i = 0; i < this.props.images.length; i++ ){ 
      if(this.props.images[i].selected == true){
        images.push(this.props.images[i])
      }
    }
    return images
  }

  imageClass(selected){ 
    if(selected){ 
      return "opacity-30"
    }
    return "cursor-pointer"
  }

  imageDivClass(selected){ 
    if(selected){ 
      return "bg-gray-400"
    }
    return ""
  }

  compositeImagesItem(){ 
    var images = this.compositeImages()
    return images.map((image, index) => {
      return(
        <img className={image.z_index + " absolute top-0 left-0 "}
          src={`/games/images/paint_the_world/dropped/`+ image.pic + `.webp`}
        />
      )
    })
  }

  droppableContainer(){ 
    return(
      <div className="bg-red"
      >
        {this.compositeImagesItem()}
      </div>
    )
  }

  hintIndex(){ 
    switch(this.state.day){ 
    case 1:
        return 0
      break
    case 2:
        return 1
      break
    case 3:
        if(this.state.sequence == 1){ 
          return 2
        }
        else{ 
          return 3
        }
      break
    case 4: 
        return 4
      break
    case 5: 
        return 5
      break 
    case 6: 
        if(this.state.sequence == 1){ 
          return 6
        }
        else{ 
          return 7
        }
      break 
    } 
  }

  notification(){
    if(this.state.notification == true){ 
      return <Notification 
              hint = {true}
              gifImage = {this.state.wrongAnimation}
              clickHandler = {this.handleCloseNotification.bind(this)}
              hintHandler = {this.handleNotificationHint.bind(this)}
              open = {this.state.notification}
              description = {this.state.notificationDesc}
            />
    }
    return <div/>
  }

  intervalModal(){
    if(this.state.intervalModal == true){ 
      return <Notification 
              hint = {false}
              gifImage = {this.state.clapAnimation}
              clickHandler = {this.handleCloseIntervalModal.bind(this)}
              hintHandler = {this.handleNotificationHint.bind(this)}
              open = {this.state.intervalModal}
              description = {intervalStatements[this.state.day-1]}
            />
    }
    return <div/>
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          trophy = { this.state.trophy }
          searchParams = { '?tab=game'}
          game = "Paint The World"
          activeUser = { this.state.activeUser }
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  hintModal(){
    var index = this.hintIndex()
    if(this.state.hint == true){ 
      return <HintModal 
              title={hintTitle[index]}
              description={hintDescription[index]}
              clickHandler= {this.handleCloseHintModal.bind(this)}
              open = {this.state.hint}
            />
    }
    return <div/>
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  hintButton(){ 
    if(this.state.day < 7){ 
      return <button style={{width:"110px"}} className="fancy-button sm:big"
        onClick={() => this.setState({hint: true})}
      >
        Hint
      </button>
    }
    return ( 
      <button className="fancy-button red"
        onClick={(e) => window.location.href = "/games" } 
      >
       <ArrowRightOnRectangleIcon className="h-12 w-12 text-white" aria-hidden="true"/> 
      </button>
    )
  }

  pauseOrRestartButton(){ 
    if(this.state.day < 7){ 
      return <button style={{width:"110px"}} className="-center fancy-button orange"
               onClick={() => this.setState({pause: true})}
             >
               ||
             </button>
    }
    return <button className="fancy-button green"
            onClick={(e) => window.location.href = "/games/paint-the-world" } 
           >
             <ArrowPathIcon className="h-12 w-12 text-white" aria-hidden="true"/> 
           </button>
  }

   volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  backgroundWidth(width, height){ 
    let type = frameType["portrait"]
    let imageWidth = "auto"
    let imageHeightNum = 0
    let imageWidthNum = 0
    let imageHeight = "auto"
    let screenRatio = width/height
    let totalWidth = "auto"

    // Mobile landscape or PC beyond 2200px landscape 
    // For landscape, images on the left
    if(screenRatio > 1  &&  (isMobile || width > 2200) ){  
      type = frameType["landscape"]
      if(isMobile){ 
        width = 0.6 * width
      }else{
        width = 0.75 * width
      }
      // mobile have 4 columns, each columns have 7 pic 
      // PC have 3 columns, each column 9 pics 
      // For landscape, need to calculate the selection div width
      height = actualFrameHeight/actualFrameWidth * width
      if(isMobile){ 
        imageHeightNum = height/7
        imageWidthNum = imageHeightNum * actualImageWidth/actualImageHeight
        totalWidth = `${imageWidthNum * 4}px`
      }else{
        imageHeightNum = height/9
        imageWidthNum = imageHeightNum * actualImageWidth/actualImageHeight
        totalWidth = `${imageWidthNum * 3}px`
      }
      imageWidth = `${imageWidthNum}px`
      imageHeight = `${imageHeightNum}px`
    }else{ 
      let portraitFrameRatio = actualFrameWidth/actualFrameHeight
      if(isMobile){ 
        width = 0.95 * width
        imageWidth = `${width/6}px`
      }else{
        if(screenRatio < 1.05){
          width = 0.98 * width 
        }else{ 
          width = height/1.05
        }
        imageWidth = `${width/9}px`
      }
    }
    this.setState({frameType: type, imageWidth: imageWidth, imageHeight: imageHeight, totalWidth: totalWidth})
    return width
  }

  backgroundHeight(width){ 
   var height = parseFloat(width) * 9 / 16
    return (parseFloat(width) * 9 / 16)
  }

  componentDidUpdate(prevProps) {
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true, gameState:"ready", gameRule: true})
        this.props.userTrophyFetch("game", "paint-the-world")
      }
    }
  }

  componentDidMount(){
    for(var i = 1; i < this.props.images.length; i++ ){ 
      let image = this.props.images[i]
      let url = `/games/images/paint_the_world/to_drag/`+ image.pic + `.webp`
      console.log(`fetch: ${url}`)
      this.props.paintTheWorldFetch(url, i)
    } 
    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    var bgWidth = this.backgroundWidth(window.outerWidth, window.outerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    window.addEventListener('resize', this.onWindowSizeChange.bind(this));
    window.addEventListener('contextmenu', this.handleContextMenu.bind(this))
    window.addEventListener('blur', this.handleTabInactive.bind(this));
    this.setState({sizeRate: bgWidth/actualBgWidth})
    this.setState({bgWidth: bgWidth})
    this.setState({bgHeight: bgHeight})
    const img = new Image()
    img.src = WellDoneGif 
    this.props.userFetch()
  } 
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowSizeChange.bind(this));
    window.removeEventListener('contextmenu', this.handleContextMenu.bind(this));
    window.removeEventListener('blur', this.handleTabInactive.bind(this));
  }

  correctionLeft(){ 
    if(frameTypeString[this.state.frameType] == "portrait"){ 
      return "2px"
    }
    return 0
  }

  correctionTop(){ 
    if(frameTypeString[this.state.frameType] == "landscape"){ 
      return "2px"
    }
    return 0
  }

  imageSelectorItems(){ 
    return(
      this.imagesToBeSelected([0, 27]).map((image) => { 
        return(
          <div className={`h-auto flex flex-wrap ${frameInfo[this.state.frameType].flex} flex-shrink overflow-hidden ${this.imageDivClass(image.selected)}`}
            style = {{position: 'relative', 
              display: "flex",
              top: `${this.correctionTop()}`,
              left: `${this.correctionLeft()}`,
              width: `${this.state.imageWidth}`, 
              height: `${this.state.imageHeight}`
            }}
          >
            <img className={`unselectable object-contain border border-black ${this.imageClass(image.selected)}`}
              style={{
                display: "inline-block",
                width:"auto",
              }}
              src={`data:image/jpeg;charset=utf-8;base64,${image.rawImage}`}
              onClick={this.handleSelectImage.bind(this, image.id)}
            />
          </div>
        )
      })
    )
  }

  itemCount(){ 
    let images = this.getImagesOnDay(this.props.images, this.state.day)
    let selectedCount = 0
    for(var i = 0; i < images.length; i++ ){ 
      var index = images[i]
      // Complete the day if only all the images of that day has been selected
      if(this.props.images[index].selected == true){ 
        selectedCount++;
      }
    }
    return `${selectedCount}/${images.length}`
  }

  doneDiv(){ 
    return(
      <div> 
        <div className="p-4 flex justify-center justify-items-center mx-auto"> 
          <video style={{width:`${1600 * this.state.sizeRate}px`}} ref={this.state.endVidRef} controls>
            <source src={`/videos/paint_the_world.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className="mx-auto justify-items-center mt-2 grid grid-cols-2"
          style={{width:`${1600 * this.state.sizeRate}px`}} 
        >
          <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
            onClick={(e) => this.props.history.goBack() } 
          >
            <span> 
              <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                style={{transform: "rotateY(180deg)"}} 
              />
            </span> 
            <span className="my-auto hidden md:block">
              Exit
            </span>
           
          </button>
          <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
            onClick={ this.handleRestart.bind(this) }
          >
            <span> 
              <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
            </span> 
            <span className="my-auto hidden md:block">
              Play Again
            </span>
          </button>
        </div>
      </div> 
    )
  }

  selectionTotalWidth(){ 
    if(frameTypeString[this.state.frameType] == "portrait"){ 
      return( `${actualFrameWidth * this.state.sizeRate}px` )
    }else{ 
      return this.state.totalWidth
    }
  }
  selectionTotalHeight(){ 
    if(frameTypeString[this.state.frameType] == "landscape"){ 
      return( `${actualFrameHeight * this.state.sizeRate + 1}px` )
    }else{ 
      return "auto"
    }
  }

  introVideoDiv(){ 
    if(this.props.userLoaded && !this.state.activeUser){ 
      return(
        <GameIntroVideo
          introEndHandler = { this.handleIntroEnd.bind(this)}
          introPlayHandler = { this.handleIntroPlay.bind(this)}
          introTextEnabled = { !this.state.introVideoStarted && this.props.userLoaded }
          videoId = { "TQ17s7UrTt8" }
        />
      )
    }
  }

  gameDiv(){ 
    if(this.state.gameState=="done" || this.state.gameState == "endVideo"){ 
      return this.doneDiv()
    }
    if(this.state.gameState == "pending"){ 
      return this.introVideoDiv()
    }
    return(
      <div className={`${frameInfo[this.state.frameType].divFlex}`}>
        <div className={`relative flex ${frameInfo[this.state.frameType].flex} flex-wrap z-30 bg-white border border-black `}
          style={{ 
            width: `${this.selectionTotalWidth()}`,
            height: `${this.selectionTotalHeight()}`
          }}
        >
          { this.imageSelectorItems() }
        </div>
        {/* Virtual Frame Container */}
        <div className="mx-auto"
          style={{ 
            position: 'relative',
            width: `${actualFrameWidth * this.state.sizeRate}px`,
            height: `${actualFrameHeight * this.state.sizeRate}px`,
          }}
        > 
          {/* Actual Frame */}
          <div className="z-40 mx-auto unselectable" 
            style={{position: 'absolute', 
                  top: `0px`,
                  left: `0px`,
                  width: `${actualFrameWidth * this.state.sizeRate}px`,
                  height: `${actualFrameHeight * this.state.sizeRate}px`,
            backgroundImage: `url(${Frame})`,
            backgroundSize: `${actualFrameWidth * this.state.sizeRate}px ${actualFrameHeight * this.state.sizeRate}px`, 
            backgroundRepeat: 'no-repeat'
            }}
          >
            
            {/* Buttons */}
            <button className="focus:outline-none fancy-round-button orange"
              style={{position: 'absolute', 
                top: `${buttonTop * this.state.sizeRate}px`,
                left: `${hintButtonLeft * this.state.sizeRate}px`,
                width: `${105 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handleHint.bind(this)}   
            >
              <LightBulbIcon className="mx-auto"
                style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} 
              />
            </button>
            <button className="focus:outline-none fancy-round-button red"
              style={{position: 'absolute', 
                top: `${buttonTop * this.state.sizeRate}px`,   
                left: `${firstButtonLeft * this.state.sizeRate}px`,
                width: `${105 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handlePause.bind(this)}   
            >
              <p className="text-white font-black">
                <PauseCircleIcon className="mx=auto"/>
              </p>
            </button>
            <button class="focus:outline-none fancy-round-button green"
              style={{position: 'absolute', 
                top: `${buttonTop * this.state.sizeRate}px`,
                left: `${secondButtonLeft * this.state.sizeRate}px`,
                width: `${105 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handleAudioToggle.bind(this)}                
            >
              {this.volumeIcon(100 * this.state.sizeRate )}
            </button>
            {/* Score */}
            <div
              style = {{
                position: 'absolute',    
                top: `${scoreTop * this.state.sizeRate}px`,
                left: `${scoreLeft * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className="mr-3 text-right game-desc text-white"
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${65 * this.state.sizeRate}px` }}
                >
                  Score
                </p>
              </div>
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${(scoreTop + 80) * this.state.sizeRate}px`,
                left: `${scoreLeft * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className="mr-3 mt-0 sm:-mt-2 text-right game-desc text-white"
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${50 * this.state.sizeRate}px` }}
                >
                 {this.state.score}
                </p>
              </div>
            </div>
            {/* Day */}
            <div className="my-auto flex"
              style = {{
                position: 'absolute',    
                top: `${dayTop * this.state.sizeRate}px`,
                left: `${dayLeft * this.state.sizeRate}px`,
                width: `${330 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className="mr-3 text-right game-desc text-white"
                  style = {{ lineHeight:`${80 * this.state.sizeRate}px`, fontSize: `${65 * this.state.sizeRate}px` }}
                >
                 Day {this.state.day}
                </p>
              </div>
            </div>
            <div
              style = {{
                position: 'absolute',    
                top: `${(dayTop + 80) * this.state.sizeRate}px`,
                left: `${dayLeft * this.state.sizeRate}px`,
                width: `${330 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className="mr-3 mt-0 sm:-mt-2 text-right game-desc text-white"
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${50 * this.state.sizeRate}px` }}
                >
                 {this.itemCount()}
                </p>
              </div>
            </div>
          </div> 
          {/* Actual Image selector Container */} 
          
          {/* Actual Bg Container */} 
          <div className='bg-yellow z-0' 
            style = {{position: 'absolute',    
              top: `${bgTop * this.state.sizeRate}px`,
              left: `${bgLeft * this.state.sizeRate}px`,
              width: `${this.state.bgWidth}px`,
              height: `${this.state.bgHeight}px`,
            }}
          >
            <div className='bg-red z-0' 
              style = {{
                position: 'relative',    
                  top: `0px`,
                  left: `0px`,
                  width: `${this.state.bgWidth}px`, 
                  height: `${this.state.bgHeight}px`,
                  overflow: 'hidden'}}
            
            >  
              {this.droppableContainer()}
            </div>
          </div>
        </div> 
      </div> 
    )
  }
  render() {
    return (
      <>
        {this.hintModal()}
        {this.pauseModal()}
        {this.notification()}
        {this.intervalModal()}
        {this.wellDoneModal()}
        <GameRuleModal
          open = { this.state.gameRule }
          audioToggleHandler = { this.handleAudioToggle.bind(this)}
          startPlayHandler = { this.handleStartPlay.bind(this)}
          audio = { this.state.audioOn }
          lottieJson = { gameRule }
          heading = { "Imagine yourself as God's helper, helping Him with the creation." }
          instructions = { ["Click on the picture according to God's order of creation. "] }
        />
        <div className={`mx-auto my-auto grid justify-items-center bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-w-screen min-h-screen py-2 sm:py-4`}
          
        > 
          {this.gameDiv()}     
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    images: state.paintTheWorld.images,
    user: state.user.user,
    userLoaded: state.user.userLoaded,
    trophy: state.user.trophy
  }
};

export default connect(mapStateToProps, {
  paintTheWorldUpdate,
  paintTheWorldFetch,
  userTrophyFetch, 
  userFetch, 
  userTrophyCreate
}) (PaintTheWorld);