import {
    AVATAR_SKINS_FETCH_SUCCESS, 
    AVATAR_FACES_FETCH_SUCCESS, 
    AVATAR_HEADS_FETCH_SUCCESS, 
    AVATAR_SHIRTS_FETCH_SUCCESS
  } from '../actions/types.js';
  
const INITIAL_STATE = {
  skins: [], 
  faces: [],
  hairs: [], 
  headwears: [], 
  shirts: []
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case AVATAR_SKINS_FETCH_SUCCESS: 
      return { ...state, skins: action.payload };  
    case AVATAR_FACES_FETCH_SUCCESS: 
      return { ...state, faces: action.payload };  
    case AVATAR_HEADS_FETCH_SUCCESS: 
      return { ...state, hairs: action.payload.hairs, headwears: action.payload.headwears };  
    case AVATAR_SHIRTS_FETCH_SUCCESS: 
      return { ...state, shirts: action.payload };  
    default:
      return state;
  }
};
