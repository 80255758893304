import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import NavBar from '../../elements/NavBar'
import Footer from '../../elements/Footer'

import { lessonFetch } from '../../actions/lessonActions';

class LessonRenderer extends React.Component {
  constructor(props) {
    super(props);
  }

  lessonItem(){ 
    if(Object.keys(this.props.lesson).length){ 
      return(
        <div className="bg-primary-color overflow-hidden">
         
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            { ReactHtmlParser(this.props.lesson.article) }
          </div>
        </div>
      )
    }
  }

  componentDidMount() {
    this.props.lessonFetch(this.props.match.params.topic)
  }

  render() {
    return(
      <div> 
        <div className="bg-primary-color min-h-screen"> 
          <NavBar/>
          {this.lessonItem()}
        </div>
        <Footer/>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return { 
    lesson: state.lesson.lesson
  }
};

export default connect(mapStateToProps, {
  lessonFetch
}) (LessonRenderer);