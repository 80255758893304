import React, { Component } from 'react';
import { Jodit } from 'jodit'
import JoditEditor from "jodit-react";
import { connect } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { articleFetch, articleCreate, articleUpdate } from '../../actions/articleActions';
import { topicListFetch } from '../../actions/topicActions';

import "../../stylesheets/article.css"

const buttons = [
  "paragraph",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  //"|",
  //"ul",
  //"ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "|",
  "video",
  "link",
  "table",
  "|",
  "hr",
  "copyformat",
  "|",
  "fullsize",
  "print",
  "|",
  "undo",
  "redo",
]

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  extraButtons: ["insertOneImage", "insertTwoImages", "insertTextImage"],
  controls: {
    font: {
      command: "fontname",
      list: {
        "": "Default",
        "PT Serif": "Sans Serif",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Bitter": "Bitter"
      }
    }
  },
};

class ArticleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      articleType: "Craft",
      topic: "",
      queryId: queryString.parse(props.location.search).id, 
      fetchedArticle: false, 
      newArticle: false, 
      content: ""
    }
  } 
  onBlur = (value) => {
    value = value.replaceAll('<a href=', '<a class="article-link" href=')
    this.setState({content: value})
    console.log(value)
  }

  onChangeTopic = (e) => { 
    this.setState({topic: e.target.value})
    if(this.state.content.includes("<p") == false ){ 
      if(this.state.articleType == "Craft"){ 
        this.setState({content: `<h1>Lego Build: ${e.target.value}</h1>`
      })
      }else{ 
        this.setState({content: `<h1>${this.state.articleType}: ${e.target.value}</h1>`})
      }
    }
  }

  onChangeArticleType = (e) => { 
    this.setState({articleType: e.target.value})
    if(e.target.value == "Lesson"){ 
      const initString = '<div><h1> Lesson: </h1><table class="border-0 mt-4 table-auto"><tr><td style="width: 30%;"><p>Amazing Truth:</p></td><td style="width: 70%;"><p class="font-bold">Truth</p></td></tr><tr><td><p>Key Passage: </p></td><td><p>Genesis 1:1-27</p></td></tr><tr><td><p>Memory Verse: </p></td><td><p> Memory Verse PlaceHolder. ~Genesis 1:1 </p></td></tr></table></div>'
      this.setState({content:initString})
    }
  }

  onSubmit = (e) => {
    e.preventDefault()

    if(this.state.newArticle){ 
      this.props.articleCreate(this.state.content, this.state.articleType, this.state.topic)
    }else{ 
      this.props.articleUpdate(this.props.article.id, this.state.content)
    }
  }

  insertOneImageButton = () => {
    Jodit.defaultOptions.controls.insertOneImage = {
        name: '1',
        iconURL: "https://biblefunclub.com/images/article_editor/one_image.png",
        tooltip: 'Insert 1 image',
        exec: (async (editor) => {
          editor.value = editor.value.concat( 
            '<div class="md:px-24 my-6 lg:my-8"><img class="mx-auto my-auto w-full md:w-3/4 rounded-lg shadow-lg object-cover object-center" src="/images/placeholder.jpeg"></img></div><p></p>'
          )
        })
    };
  }

  insertTwoImagesButton = () => {
    Jodit.defaultOptions.controls.insertTwoImages = {
      name: '2',
      iconURL: "https://biblefunclub.com/images/article_editor/two_images.png",
      tooltip: 'Insert 2 images',
      exec: (async (editor) => {
          editor.value = editor.value.concat(
            '<div class="mt-2 lg:grid lg:grid-cols-2 lg:gap-8"><div class="md:px-24 lg:px-0 my-4 relative lg:col-start-1"> <img class="mx-auto rounded-lg shadow-lg object-cover object-center" src="/images/placeholder.jpeg"/></div> <div class="md:px-24 lg:px-0 my-4 lg:row-start-1 lg:col-start-2"><img class="mx-auto rounded-lg shadow-lg object-cover object-center" src="/images/placeholder.jpeg"/></div></div><p></p>'
          )
      }),
    };
  }

  insertTextImageButton = () => {
    Jodit.defaultOptions.controls.insertTextImage = {
      name: '3',
      iconURL: "https://biblefunclub.com/images/article_editor/text_image.png",
      tooltip: 'Insert Text + Image',
      exec: (async (editor) => {
          editor.value = editor.value.concat(
            '<div class="lg:grid lg:grid-cols-2 lg:gap-8"> <div class="my-auto relative lg:row-start-1 lg:col-start-1"> <p class="text-justify"> Text Placeholder </p></div><div class="md:px-24 lg:px-0 relative lg:row-start-1 lg:col-start-2"><img class="mx-auto rounded-lg shadow-lg object-cover object-center" src="/images/placeholder.jpeg" /></div></div><p></p>' 
          )
      }),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.article != prevProps.article) {
      console.log(`article type: ${this.props.article.article_type}`)
      console.log(`topic: ${this.props.article.topic}`)
      this.setState({articleType: this.props.article.article_type, topic: this.props.article.topic})
      console.log(`fetched content: ${this.props.article.content}`)
      this.setState({content: this.props.article.content})
    }
    if ( this.props.updatedId !=0 ){ 
      window.location = "/admin/articles"
    }
  }

 
  topicSelectOptions = () => { 
    let array = []; 
    this.props.topicList.forEach(topic => array.push(topic.name));
    return( 
      array.map(option => {
        return (
          <option key={option} value={option} label={option}>
            {option}
          </option>
        );
      })
    )
  }


  configureArticleElements(){ 
    if(this.state.newArticle){
      return(
        <div className="mt-8 flex mb-4">
          <div className="w-full md:w-1/2">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Select Article Type
            </label>
          </div>
          <div className="w-full md:w-1/2">
            <select className="w-full md:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
              name={"Type"}
              value={this.state.articleType}
              onChange={this.onChangeArticleType.bind(this)}
            >
              <option value="" disabled></option>
              <option value="Lesson">Lesson</option>
              <option value="Craft">Craft</option>
            </select>
          </div>
          <div className="w-full md:w-1/2">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Select Topic 
            </label>
          </div>
          <div className="w-full">
            <select className="w-full md:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
              name={"Topic"}
              value={this.state.topic}
              onChange={this.onChangeTopic.bind(this)}
            >
              <option value=""></option>
            {this.topicSelectOptions()}
            </select>
          </div>
        </div>
      )
    }
  }

  joditEditorElement(){ 
    if(this.state.fetchedArticle || Object.keys(this.props.article).length){ 
      console.log(`content: ${this.state.content}`)
      return( 
        <JoditEditor
          value={this.state.content}
          config={ editorConfig }
          onBlur={this.onBlur.bind(this)}
        />
      )
    }
  }

 componentDidMount() {
    this.insertOneImageButton();
    this.insertTwoImagesButton();
    this.insertTextImageButton();
    if(this.state.queryId){ 
      this.props.articleFetch(this.state.queryId);
    }else{ 
      this.setState({fetchedArticle: true, newArticle: true})
    }
    this.props.topicListFetch();
  }

  render() {
    return(
      <div className="bg-primary-color overflow-hidden">
        <div className="mx-auto sm:max-w-screen lg:max-w-7xl">
          {this.configureArticleElements()}
          <br/>
          {this.joditEditorElement()}
          <div className="my-8 flex items-center justify-center"> 
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick = {this.onSubmit.bind(this)}
            >
              Submit
            </button>
          </div>
        </div> 
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    article: state.article.data, 
    updatedId: state.article.updatedId, 
    topicList: state.topic.topicList
  }
};

export default connect(mapStateToProps, {
  articleFetch, 
  articleCreate, 
  articleUpdate, 
  topicListFetch
})(ArticleEditor);
