import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import TrophyModal from './TrophyModal'
import { IoGameControllerOutline } from 'react-icons/io5';
import { MdOutlineQuiz } from "react-icons/md";
import { TbLock } from "react-icons/tb"

import '../../stylesheets/userSettings.css';

const tabConstant = { "game": 1, "quiz": 2} 
const linkConstant = { "game": "games", "quiz": "quizzes"} 
class TrophyTabs extends React.Component{
  constructor(props) {
    super(props);
    let activeTab = 1
    let defaultTab = queryString.parse(props.location.search).tab
    if(defaultTab){ 
      activeTab = tabConstant[defaultTab]
    }
    this.state = { 
      activeTab: activeTab, 
      modalOpen: false, 
      selectedItem: null
    };
  }
  
  onToggleTab = (index) => { 
    this.setState({activeTab: index})
  }

  onCloseModal = () => { 
    this.setState({modalOpen: false})
  }

  getActiveTabClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-tabs"
    }
    return "inactive-avatar-tabs"
  }

  getActiveContentClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-content"
    }
    return ""
  }

  clickCallBack(item){
    if(item.id != this.profileItem(item).id){ 
      return this.props.handleSelectItem.bind(this.props.parent, item)
    }
  }

  lockedItem = (className) => { 
    if(className != ""){ 
      return <TbLock className="z-20 h-6 w-6 sm:h-8 sm:w-8 lg:h-12 lg:w-12 text-primary-dark absolute inset-0 m-auto object-contain pointer-events-none opacity-30" />
    }
  }

  thumbnailItem = (className, game) => { 
    if(className == ""){ 
      return <img src={game.thumbnail} alt="" className={`z-10 w-1/5 absolute inset-0 m-auto object-cover`} />
    }
  }

  gameItem(game){
    let gameTrophy = this.props.gameTrophies.find(trophy => trophy.scope_id == game.id)
    let goldClass, silverClass, bronzeClass
    goldClass = silverClass = bronzeClass = "opacity-50"
    if(gameTrophy){ 
      if(gameTrophy.gold){ goldClass = "" }
      if(gameTrophy.silver){ silverClass = "" }
      if(gameTrophy.bronze){ bronzeClass = "" }
    }
    return(
      <div className="grid grid-cols-5 py-1 sm:py-2 md:py-4">  
        <div className="my-auto col-start-1 col-span-2"> 
          <div className="flex flex-col">
            <img src={game.thumbnail} alt=""className="w-1/2 lg:w-5/12 m-auto object-cover cursor-pointer hover:opacity-75"
              onClick={(e) => this.setState({selectedItem: game, modalOpen: true}) } 
            />
            <p className="mt-1 text-center text-primary-dark font-bold text-sm sm:text-lg md:text-xl lg:text-2xl opacity-75">{game.name}</p>
          </div>
        </div>
        <div className="col-start-3 col-span-3"> 
          <div className="grid grid-cols-3 place-items-center">
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-2 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img src="/images/trophies/gold.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${goldClass}`} />
                { this.thumbnailItem(goldClass, game) }
                {this.lockedItem(goldClass)}
              </div>
            </div>
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-4 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img src="/images/trophies/silver.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${silverClass}`} />
                { this.thumbnailItem(silverClass, game) }
                {this.lockedItem(silverClass)}
              </div>
            </div>
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-6 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img  src="/images/trophies/bronze.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${bronzeClass}`} />
                { this.thumbnailItem(bronzeClass, game) }
                {this.lockedItem(bronzeClass)}
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  } 

  quizItem(quiz){ 
    let quizTrophy = this.props.quizTrophies.find(trophy => trophy.scope_id == quiz.id)
    let goldClass, silverClass, bronzeClass
    goldClass = silverClass = bronzeClass = "opacity-25"
    if(quizTrophy){ 
      if(quizTrophy.gold){ goldClass = "" }
      if(quizTrophy.silver){ silverClass = "" }
      if(quizTrophy.bronze){ bronzeClass = "" }
    }
    return(
      <div className="grid grid-cols-5 py-1 sm:py-2 md:py-4">  
        <div className="my-auto col-start-1 col-span-2"> 
          <div className="flex flex-col">
            <img src={quiz.thumbnail} alt=""className="w-1/2 lg:w-5/12 m-auto object-cover cursor-pointer hover:opacity-75" 
              onClick={(e) => this.setState({selectedItem: quiz, modalOpen: true}) } 
            />
            <p className="mt-1 text-center text-primary-dark font-bold text-sm sm:text-lg md:text-xl lg:text-2xl">{quiz.name}</p>
          </div>
        </div>
        <div className="col-start-3 col-span-3"> 
          <div className="grid grid-cols-3 place-items-center">
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-2 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img  src="/images/trophies/gold.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${goldClass}`} />
                { this.thumbnailItem(goldClass, quiz) }
                {this.lockedItem(goldClass)}
              </div>
            </div>
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-4 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img  src="/images/trophies/silver.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${silverClass}`} />
                { this.thumbnailItem(silverClass, quiz) }
                {this.lockedItem(silverClass)}
              </div>
            </div>
            <div className="mx-auto object-center block rounded-lg">
              <div className={`-ml-6 lg:-mt-16 sm:-mt-8 -mt-8 relative h-24 w-24 sm:w-36 sm:h-36 md:h-42 md:w-42 lg:h-52 lg:w-52 rounded-lg`}>
                <img  src="/images/trophies/bronze.webp" alt="" className={`z-0 h-full absolute inset-0 m-auto object-cover ${bronzeClass}`} />
                { this.thumbnailItem(bronzeClass, quiz) }
                {this.lockedItem(bronzeClass)}
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }

  quizzesItem(){
    if(this.props.quizList.length){
      return(
        this.props.quizList.map((quiz) => (
          this.quizItem(quiz)
        ))
      )
    }
  } 
  gamesItem(){
    if(this.props.gameList.length){
      return(
        this.props.gameList.map((game) => (
          this.gameItem(game)
        ))
      )
    }
  } 

  trophyModal(){ 
    if(this.state.modalOpen){ 
      let item = this.state.selectedItem
      return(
        <TrophyModal
          open = { true }
          image = { item.thumbnail }
          description = { [ `${item.name} ${item.type}`]}
          targetLink = { `${linkConstant[item.type]}/${item.url}` }
          handleClose = { this.onCloseModal.bind(this) }
        />
      )
    }
  }

  render(){
    return(
      <div className="sm:px-1 lg:px-8 ">
        {this.trophyModal()}
        <div className="flex px-1 md:px-0 gap-x-1 md:gap-x-4 avatar-tab-list">
          <div className={`z-40 md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(1)}`}
            onClick={() => this.onToggleTab(1)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Game </h3>
            </div>
            <div className="mt-0"> 
              <IoGameControllerOutline className="text-3xl text-white"/>
            </div>
          </div>
          <div className={`z-40 md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(2)}`}
            onClick={() => this.onToggleTab(2)}
          >  
            <div className="-mt-1 sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-bold leading-10 tracking-wider text-white">Quiz </h3>
            </div>
            <div className="mt-0"> 
              <MdOutlineQuiz className="text-3xl text-white"/>
            </div>
          </div>
        </div>
        <div>
          <div className={`avatar-content ${this.getActiveContentClass(1)}`}>
            <div className="w-full py-5">
              {this.gamesItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(2)}`}>
            <div className="w-full py-5">
              {this.quizzesItem()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
  }
};

export default connect(mapStateToProps, {
})(TrophyTabs);