import axios from 'axios';

import { LESSON_LIST_FETCH_SUCCESS, LESSON_FETCH_SUCCESS } from './types';

export const lessonListFetch = () => {
  const url = '/api/lessons' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: LESSON_LIST_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const lessonFetch = (id) => {
  const url = '/api/lessons/' + `${id}`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: LESSON_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};
