import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'
import PlanModal from './Plan/PlanModal'
import PlanZoomTheme from './Plan/PlanZoomTheme'

import { topicListFetch } from '../actions/topicActions';
import { progressOwnerListFetch } from '../actions/progressOwnerActions';

const bridgeWidth = 940 
const bridgeHeight = 335
const bridgeLeft = 2030 
const bridgeTop = 50

const islandWidth = 5000 
const islandHeight = 1600
const heightRatio = islandHeight/islandWidth

const starWidth = 92 
const starHeight = 116 

const areaWidth = 270
const areaHeight = 200

// Reference to the entire map image
const themeInfo = [ 
  { 
    left: 700,
    top: 540, 
    width: 1535,
    height: 720, 
    points: "360 0,920 0,1500 280, 1500 380, 930 690,550 690, -40 230, 0 120"  
  },
  { 
    top: 45, 
    left: 3800, 
    width: 860,
    height: 500,
    points: "300 0,650 0, 900 500, 600 600, 0, 400, 0 240"
  }
]

const dataInfo = [ 
  { 
    top: 907, 
    left: 1437, 
    starTop: 950, 
    starLeft: 1581, 
    theme: 0
  },
  { 
    top: 705, 
    left: 1824, 
    starTop: 750, 
    starLeft: 1967,
    theme: 0
  },
  { 
    top: 535, 
    left: 1485, 
    starTop: 570, 
    starLeft: 1645,
    theme: 0
  },
  { 
    top: 535,  
    left: 1180, 
    starTop: 557, 
    starLeft: 1272,
    theme: 0
  },
  { 
    top: 18, 
    left: 4132, 
    starTop: 49, 
    starLeft: 4232,
    theme: 1
  },
]


const imageRef = { 1: "star1", 2: "star2", 3: "star3"} 
const themeRef = { 
  "In the Beginning": 0, 
  "Jesus' Ministry": 1
} 

class Plan extends React.Component {
  constructor(props) {
    super(props);
    let bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    let phoneLandscape = false 

    if(isMobile){ 
      let aspectRatio = window.innerWidth/window.innerHeight
      if(aspectRatio > 1){ 
        phoneLandscape = true
      } 
    }
    this.state = { 
      bgWidth: bgWidth,
      bgHeight: this.backgroundHeight(bgWidth), 
      sizeRate: bgWidth/islandWidth,
      planModal: false, 
      phoneLandscape: phoneLandscape,
      selectedIndex: -1,
      selectedTheme: -1, 
    }
  }

  handleWindowSizeChange(){
    let bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)

    this.setState({ bgWidth: bgWidth, bgHeight:  this.backgroundHeight(bgWidth) });
    this.setState({ sizeRate: bgWidth/(islandWidth) });
  };

  handleClick(index){ 
    this.setState({selectedIndex: index, planModal: true})
    //window.location.href = `/plan/${this.props.topicList[index].url}`
  }

  handleClickTheme(index){ 
    this.setState({selectedTheme: index})
  }

  handleClearZoom(){ 
    this.setState({selectedTheme: -1})
  }

  handleCloseModal(url){ 
    this.setState({planModal: false})
    if(url){ 
      window.location = `/plan/${url}`
    }
  }

  backgroundWidth(){ 
    let aspectRatio = window.innerWidth/window.innerHeight
    // phone
    if(isMobile && (aspectRatio > 1.6 || aspectRatio < 0.6 )){ 
      // landscape
      if(aspectRatio > 1){ 
         this.setState({phoneLandscape: true})
         return 0.98 * window.innerWidth
      }
      // portrait 
      this.setState({phoneLandscape: false})
      return 2 * window.innerWidth
    }
    // portrait tablet
    else if(isMobile && aspectRatio < 1){ 
      return 2 * window.innerWidth
    }
    return 0.98 * window.innerWidth
  }

  backgroundHeight(width){ 
    return width * heightRatio
  }

  dataIndex(progressOwner){ 
    let topicId = progressOwner.topic_id
    return this.props.topicList.findIndex(x => x.id == topicId)
  }

  generateZoomData(theme){ 
    let list = dataInfo.filter(x => x.theme == theme)
    let zoomData = []
    for(var i = 0; i < dataInfo.length; i++ ){    
      let data = {}
      let owner = null
      let topicId = 0 
      let starImage = "star0"
      if(dataInfo[i].theme == theme){ 
        if(this.props.progressOwnerList){ 
          topicId = this.props.topicList[i].id
          owner = this.props.progressOwnerList.find(x => x.topic_id == topicId)
          if(owner){ 
            starImage = imageRef[owner.star_count]
          }
        }
        data = { 
          info: dataInfo[i], 
          starImage: starImage, 
          index: i
        }
        zoomData.push(data)
      }
    }
    return zoomData
  }

  starImage(topicId){ 
    let owner = null
    if(this.props.progressOwnerList){ 
      owner = this.props.progressOwnerList.find(x => x.topic_id == topicId)
    }
    if(owner){ 
      return imageRef[owner.star_count]
    }
    return "star0"
  }

  clickableItem(){ 
    // If it is zoomed, disable all clickable
    if(this.state.selectedTheme == -1){ 
      return dataInfo.map((data, index) => {
        return(
          <div className={`z-20 cursor-pointer`}
              style = {{position: 'absolute',    
                        top: `${data.top * this.state.sizeRate}px`,
                        left: `${data.left * this.state.sizeRate}px`,
                        width: `${areaWidth * this.state.sizeRate}px`,
                        height: `${areaHeight * this.state.sizeRate}px`,
                        background: 'transparent',
                        overflow: 'hidden'}}
              onClick={this.handleClick.bind(this, index) }
          />
        )
      })
    }
  }

  clickableTheme(){ 
    // If it is zoomed, disable all clickable
    if(this.state.selectedTheme == -1){ 
      return themeInfo.map((data, index) => {
        return(
          <svg className={`z-20`}
            style = {{position: 'absolute',    
                      top: `${data.top * this.state.sizeRate}px`,
                      left: `${data.left * this.state.sizeRate}px`,
                      width: `${data.width * this.state.sizeRate}px`,
                      height: `${data.height * this.state.sizeRate}px`,
                      background: 'transparent',
                      overflow: 'hidden'}}
          >
            <g fillOpacity="0" transform={`scale(${this.state.sizeRate} ${this.state.sizeRate})`}>
              <polygon 
                className={`z-20 cursor-pointer`} points={`${data.points}`}
                onClick={this.handleClickTheme.bind(this, index) } 
              />
            </g>   
          </svg>
        )
      })
    }
  }

  starItem(){ 
    if(this.props.topicList.length){
      return this.props.topicList.map((data, index) => {
        let starName = this.starImage(data.id) 
        return(
          <div className={`z-10`}
              style = {{position: 'absolute',    
                        top: `${dataInfo[index].starTop * this.state.sizeRate}px`,
                        left: `${dataInfo[index].starLeft * this.state.sizeRate}px`,
                        width: `${starWidth * this.state.sizeRate}px`,
                        height: `${starHeight * this.state.sizeRate}px`,
                        background: 'transparent',
                        overflow: 'hidden'}}
          >
            <img className="unselectable w-full h-full" style={{objectFit: "fill"}}  src={`/images/plan/${starName}.webp`} alt="" /> 
          </div>
        )
      })
    }
  }

  loginItem(){ 
    if(this.props.userLoaded && Object.keys(this.props.user).length == 0){ 
      return( 
        <div className ="grid grid-cols-2"> 
          <div className="my-auto text-right">
            <h3 style={{fontFamily: 'Bitter'}}  className="text-base sm:text-xl"> Don't lose your progress </h3>
          </div>
          <div>
            <button className="w-40 lg:w-48 fancy-button orange"
              onClick={(e) => window.location = "/users/sign_in" } 
            >
              Login Now
            </button>
          </div>
        </div>
      )
    }
  }

  zoomItem(){ 
    let theme = this.state.selectedTheme
    if(theme != -1){ 
      return <PlanZoomTheme 
        theme = { this.state.selectedTheme }
        zoomData = { this.generateZoomData(theme) }
        clickHandler = { this.handleClick.bind(this) }
        closeHandler = { this.handleClearZoom.bind(this) }
      />
    }
    return <div/>
  }

  starInfo(){ 
    let owner = this.props.progressOwnerList.find(x => x.topic_id == this.props.topicList[this.state.selectedIndex].id)
    let starInfo = []
    let info = {}
    let topic = this.props.topicList[this.state.selectedIndex]

    if(topic.story_video_id){ 
      info["text"] = "Story Video"
      info["url"] = `/plan/${topic.url}?video=story`
      if(owner){ 
        info["done"] = owner.story_video
      }else{ 
        info["done"] = false
      }
      starInfo.push(Object.assign({}, info))
    }
    if(topic.lesson_video_id){ 
      info["text"] = "Lesson Video"
      info["url"] = `/plan/${topic.url}?video=lesson`
      if(owner){ 
        info["done"] = owner.lesson_video
      }else{ 
        info["done"] = false
      }
      starInfo.push(Object.assign({}, info))
    }
    if(topic.quiz_thumbnail){ 
      info["text"] = "Quiz"
      info["url"] = `/quizzes/${topic.url}`
      if(owner){ 
        info["done"] = owner.quiz
      }else{ 
        info["done"] = false
      }
      starInfo.push(Object.assign({}, info))
    }
      
    return starInfo
  }

  planModal(){ 
    if(this.state.planModal){ 
      return(
        <PlanModal
          open = { this.state.planModal }
          topic = { this.props.topicList[this.state.selectedIndex] }
          phoneLandscape = { this.state.phoneLandscape }
          starInfo = { this.starInfo() }
          activeUser = { Object.keys(this.props.user).length > 0 }
          closeHandler = { this.handleCloseModal.bind(this) }
        />
      )
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.topicListFetch()
    this.props.progressOwnerListFetch();
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
  }

  render() {
    return (
      <div> 
        {this.planModal()}
        <div className="z-0 min-h-screen max-w-screen bg-primary-color"> 
          <NavBar/>
          
          <div className="bg-yellow relative z-0"> 
            {this.zoomItem()}
            <div className="mt-2 md:mt-6 bg-sea-color relative overflow-auto w-full"
                  style = {{
                            height: `${1.05 * this.state.bgHeight}px`}}
            >  
              <div className={`z-0`}
                    style = {{position: 'absolute',    
                              top: 0,
                              left: 0,
                              width: `${this.state.bgWidth}px`,
                              height: `${this.state.bgHeight}px`,
                              background: 'transparent'}}
                onClick={this.handleClearZoom.bind(this) }
              >
                <img className="unselectable w-full h-full" style={{objectFit: "fill"}}  src="/images/plan/plan_map.webp" alt="" />       
              </div>               
              {this.starItem()}
              {this.clickableTheme()}
              {this.clickableItem()}
            </div>
            {this.loginItem()}
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    user: state.user.user,
    profile: state.user.profile,
    userLoaded: state.user.userLoaded,
    topicList: state.topic.topicList, 
    progressOwnerList: state.progressOwner.ownerList
  }
};

export default connect(mapStateToProps, {
  topicListFetch, 
  progressOwnerListFetch
})(Plan);