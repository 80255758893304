import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';

import Card from "./NoahQuest/Card"
import GameRuleModal from "./NoahQuest/GameRuleModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"

import { ArrowRightOnRectangleIcon, ArrowPathIcon, SpeakerWaveIcon, SpeakerXMarkIcon, PauseIcon, CursorArrowRaysIcon, ClockIcon } from '@heroicons/react/24/outline'
import PauseGif from '../../assets/animations/pause.gif'
import FrameImage from '../../assets/games/top_frame.jpg'

import { noahQuestInit, noahQuestUpdate } from '../../actions/noahQuestActions';
import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';
import { trophyConstant } from '../../helpers/ConstantHelper'
import { shuffleArray } from '../../helpers/MathHelper'
import { buttonSize } from '../../helpers/GameHelper'

import '../../stylesheets/noahQuest.css';
import '../../stylesheets/fancyButton.css';

let timeLoop
const frameWidth = 1609
const frameRatio = 1609/152
const buttonRatio = 1609/120 
const timeBonusScore = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]

const goldScore = 800
const silverScore = 500

class NoahQuest extends React.Component {
  constructor(props) {
    super(props);
    var frameWidth = this.frameWidth(window.innerWidth)
    this.state = { 
      gameRule: true, 
      wellDoneModal: false, 
      audioOn: true, 
      bgMusic: new Audio('/audio/background/sneaky_snitch.mp3'), 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      correctBuzzer: new Audio('/audio/correct_buzzer.mp3'),
      pause: false, 
      level: 1, 
      pairNum: 10,
      pairDone: 0,
      gameState: "pending", 
      pauseAnimation: PauseGif, 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight, 
      frameWidth: frameWidth, 
      frameHeight: frameWidth /frameRatio, 
      error: false,
      score: 0, 
      bonusScore: 5, 
      time: 0,
      result: 0, 
      trophy: ""
    }
    this.refArray = [] 
    for(var i = 0; i < 34; i++ ){ 
      this.refArray.push(createRef())
    }
    this.openedCard = []
  }

  handleWindowSizeChange(){
    var frameWidth = this.frameWidth(window.innerWidth)
    var frameHeight = frameWidth /frameRatio
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.setState({frameWidth: frameWidth, frameHeight: frameHeight})
  };

  handleClick(index, e) {
    // do nothing if already been clicked 
    if(this.props.cards[index].invisible == true){ 
      return
    }
    const newCards = this.props.cards
    if(this.openedCard.length > 1){ 
      var cardOne = this.openedCard[0]
      var cardTwo = this.openedCard[1]
      if(newCards[cardOne].pic == newCards[cardTwo].pic){ 
        this.props.noahQuestUpdate(cardOne, true)
        this.props.noahQuestUpdate(cardTwo, true)
        this.setState({pairDone: this.state.pairDone + 1})
      }
      this.turnCard(this.refArray[cardOne].current)
      this.turnCard(this.refArray[cardTwo].current)
      this.openedCard = [] 
      this.openedCard.push(index)
      this.turnCard(this.refArray[index].current)
    }
    else{ 
      if(this.openedCard.length && this.openedCard[0] == index){ 
        return
      }
      // Opening second card
      if(this.openedCard.length == 1){ 
        var cardOne = this.openedCard[0]
        // Matching card
        if(newCards[cardOne].pic == newCards[index].pic){ 
          if(this.state.audioOn){ 
            this.state.correctBuzzer.currentTime = 0
            this.state.volume = 0.2
            this.state.correctBuzzer.play()
          }
          var self = this
          
          var score = this.state.error? 10 : this.state.bonusScore + 10
          let timeBonus = 0
          let time = this.state.time
          if(time < 100){ 
            timeBonus = timeBonusScore[parseInt(time/10)]
          }
          this.setState({score: this.state.score + score + timeBonus})
          this.setState({error: false, result: this.state.result + 1})
          this.setState({bonusScore: this.state.bonusScore + 5})
          if(this.state.pairDone == this.state.pairNum - 1){ 
            clearInterval(timeLoop)
            let trophy = null
            if(this.state.score >= goldScore){
              if(!this.props.trophy || this.props.trophy.gold != true){ 
                trophy = "gold" 
              }
            }else if(this.state.score >= silverScore){ 
              if(!this.props.trophy || this.props.trophy.silver != true){ 
                trophy = "silver" 
              }
            }else{ 
              if(!this.props.trophy || this.props.trophy.bronze != true){ 
                trophy = "bronze" 
              }
            }
            if(this.state.activeUser && trophy){ 
              this.props.userTrophyCreate("game", "noah-quest", trophyConstant[trophy]) 
            }
            this.setState({gameState: "done"})
            this.setState({wellDoneModal: true})
            this.setState({trophy: trophy})
            this.state.bgMusic.pause() 
            self.props.noahQuestUpdate(cardOne, true)
            self.props.noahQuestUpdate(index, true)
            if(this.state.audioOn){
              this.state.bgMusic.pause()
              this.state.cheerAudio.play()
            }
          }
          setTimeout(function(){ 
            self.props.noahQuestUpdate(cardOne, true)
            self.props.noahQuestUpdate(index, true)
          }, 800)
        }else{ 
          this.setState({error: true})
          this.setState({bonusScore: 5})
        }
      }
      this.turnCard(this.refArray[index].current)
      this.openedCard.push(index)
    }
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
  }

  handleExit(){ 
    this.props.history.goBack()
  }

  handleStartPlay(){
    if(this.state.audioOn){
      this.state.bgMusic.volume = 0.3
      this.state.bgMusic.play() 
    }
    this.setState({gameRule: false})
    this.initCards()
    this.initState()
  }

  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }
  
  handlePause(){ 
    this.state.bgMusic.pause();
    this.setState({pause: true})
  } 

  handleUnpause(){ 
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleRestart(){ 
    this.setState({gameState: "pending"})
    this.initCards()
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    }
    var self = this
    setTimeout(function(){ 
      self.openedCard = []
      self.setState({pause: false})
      self.initCards()
      self.initState()
    }, 100)
    
  }

  handleLevelSelect(e){ 
    switch(e.id){ 
      case 0: 
        this.setState({pairNum: 6})
        break;
      case 1:
        this.setState({pairNum: 10})
        break; 
      case 2: 
        this.setState({pairNum: 15})
        break;
    }
    this.setState({level: e.id})
  }

  turnCard(element){ 
    element.classList.toggle("flipped")
  }

  initCards(){ 
    var images = shuffleArray(this.props.images)
    var array = []
    // randomize the images, then pick numbers of card according to level 
    for(var i = 0; i < this.state.pairNum; i++ ){ 
      array.push(i)
    }
    for(var i = 0; i < this.state.pairNum; i++ ){ 
      array.push(i)
    }
    array = shuffleArray(array)
    var cards = [] 
    for(var i = 0; i < array.length; i++ ){ 
      cards.push({id: this.props.images[array[i]].id,  
                  pic: this.props.images[array[i]].pic, invisible:false})
    }
    this.props.noahQuestInit(cards)
  }

  initState(){ 
    this.setState({gameState: "running"})
    this.setState({score: 0, bonusScore: 10, time: 0, result: 0, pairDone: 0})
    let thisObj = this
    timeLoop = setInterval(() => { 
      if(!thisObj.state.pause){ 
        if(thisObj.state.gameState == "running" && thisObj.state.time < 600000){
          thisObj.setState({time: thisObj.state.time + 1})
        }
      }
    }, 1000)
  }

  resultDisplay(){ 
    return `${this.state.result}/${this.state.pairNum}`
  }

  timeDisplay(){ 
    var minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  frameItem(){ 
    return( 
      <div className="mx-auto my-auto"
        style={{ 
          position: 'relative',
          width: `${this.state.frameWidth}px`,
          height: `${this.state.frameHeight}px`,
        }}
      >
        {/* Actual Frame */}
        <div className="z-0 my-auto mx-auto" 
          style={{position: 'absolute', 
              top: `${10/frameWidth * this.state.frameWidth}px`,
              left: `0px`,
              width: `${this.state.frameWidth}px`,
              height: `${this.state.frameHeight}px`,
              backgroundImage: `url(${FrameImage})`,
              backgroundSize: `${this.state.frameWidth}px ${this.state.frameHeight}px`, 
              backgroundRepeat: 'no-repeat'
          }}
        >
          <button class="focus:outline-none fancy-round-button green"
            style={{position: 'relative', 
              top: `${10/frameWidth * this.state.frameWidth}px`,
              left: `${1340/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/buttonRatio}px`,
              height: `${this.state.frameWidth/buttonRatio}px`,
            }}
            onClick={this.handleAudioToggle.bind(this)}                
          >
            {this.volumeIcon(this.state.frameWidth/buttonRatio)}
          </button>
          <button className="focus:outline-none fancy-round-button red"
            style={{position: 'relative', 
            top: `${10/frameWidth * this.state.frameWidth}px`,
            left: `${1355/frameWidth * this.state.frameWidth}px`,
            width: `${this.state.frameWidth/buttonRatio}px`,
            height: `${this.state.frameWidth/buttonRatio}px`,
          }}
            onClick={this.handlePause.bind(this)}
          >
            <p className="text-white font-black" 
              style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
              <PauseIcon className="mx=auto"/>
            </p>
          </button>
          <div
            style = {{
              position: 'absolute',  
              top: `${12/frameWidth * this.state.frameWidth}px`,  
              left: `${20/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/4.5}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="grid grid-cols-2">           
              <CursorArrowRaysIcon className="mx-auto text-white" 
                style={{
                  width: `${this.state.frameWidth/9}px`,
                  height: `${this.state.frameHeight/2.5}px`,
                }} 
                aria-hidden="true"
              />
              <div>
                <p className="text-right frame-font text-white unselectable"
                  style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                >
                  {this.resultDisplay()}
                </p>
              </div>
            </div>              
          </div> 
          <div
            style = {{
              position: 'absolute',    
              top: `${75/frameWidth * this.state.frameWidth}px`,
              left: `${20/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/4.5}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="grid grid-cols-2">  
              <ClockIcon className="mx-auto text-white pt-1" 
                style={{
                  width: `${this.state.frameWidth/15}px`,
                  height: `${this.state.frameHeight/2.5}px`,
                }} 
                aria-hidden="true"
              />
              <div className="my-auto">
                <div className="text-right frame-font text-white unselectable"
                  style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                >
                  {this.timeDisplay()}
                </div> 
              </div>
            </div>              
          </div> 
          {/* Score */}
          <div className="unselectable flex"
            style = {{
              position: 'absolute',    
              top: `${15/frameWidth * this.state.frameWidth}px`,
              left: `${670/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/6}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="h-full m-auto">
              <p className="text-center game-desc text-white"
                style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
              >
                Score  
              </p>  
            </div>      
          </div> 
          <div
            style = {{
              position: 'absolute',    
              top: `${75/frameWidth * this.state.frameWidth}px`,
              left: `${670/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/6}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="text-center frame-font text-white unselectable"
              style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
            >
              {this.state.score}
            </div>              
          </div> 
        </div>
      </div>  
    )
  }

  cards(){ 
    return(
      this.props.cards.map((card, index) => {
        return( 
          <Card 
            key={index} 
            refer={this.refArray[index]}
            card={card}
            clickHandler={this.handleClick.bind(this, index)}
          />
        )
      })
    )
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          trophy = { this.state.trophy }
          game = "Noah Quest"
          searchParams = { '?tab=game'}
          activeUser = { this.state.activeUser }
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  gameDiv(){ 
    return  <div className="noah-quest-container">
              {this.cards()}
            </div>
  }

  doneDiv(){ 
    return(
      <div> 

        <div className="justify-items-center mt-2 grid grid-cols-2">
          <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
            onClick={(e) => window.location.href = "/games" } 
          >
            <span> 
              <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                style={{transform: "rotateY(180deg)"}} 
              />
            </span> 
            <span className="my-auto hidden md:block">
              Exit
            </span>
           
          </button>
          <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
            onClick={ this.handleRestart.bind(this) }
          >
            <span> 
              <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
            </span> 
            <span className="my-auto hidden md:block">
              Play Again
            </span>
          </button>
        </div>
      </div> 
    )
  }

  frameWidth(width){ 
    if(width > 1000){ 
      return 0.75 * 1000
    }
    return 0.95 * width
  }

  componentDidUpdate(prevProps) {
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true})
        this.props.userTrophyFetch("game", "noah-quest")
      }
    }
  }

  componentDidMount(){
    this.initCards()
    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.addEventListener('blur', this.handlePause.bind(this));
    this.props.userFetch()
  } 
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.removeEventListener('blur', this.handlePause.bind(this));
  }

  levelSelectionItems(){ 
    return (
      <div class="mx-auto max-w-sm">    
        <div class="pl-12">
        
          <div class="flex items-center mr-4 mb-4">
            <input id="radio1" type="radio" name="radio" class="hidden" checked />
            <label for="radio1" class="flex items-center cursor-pointer text-xl">
            <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
            Easy - 6 pairs</label>
          </div>

          <div class="flex items-center mr-4 mb-4">
            <input id="radio2" type="radio" name="radio" class="hidden" />
            <label for="radio2" class="flex items-center cursor-pointer text-xl">
            <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
            Medium - 10 pairs</label>
          </div>
          
            <div class="flex items-center mr-4 mb-4">
            <input id="radio3" type="radio" name="radio" class="hidden" />
            <label for="radio3" class="flex items-center cursor-pointer text-xl">
            <span class="w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
            Hard 15 pairs</label>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div> 
        {this.pauseModal()}
        <GameRuleModal
          open = { this.state.gameRule }
          levelSelectHandler = { this.handleLevelSelect.bind(this)}
          audioToggleHandler = { this.handleAudioToggle.bind(this)}
          startPlayHandler = { this.handleStartPlay.bind(this)}
          audio = { this.state.audioOn }
          level = { this.state.level }
        />
        {this.wellDoneModal()}
        <div className="bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-h-screen py-3 md:py-8">
          <div className="grid justify-items-center ">
            {this.frameItem()}
          </div>
          {this.state.gameState == "done"?this.doneDiv(): this.gameDiv()}
        </div>
      </div> 
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    images: state.noahQuest.images,
    cards: state.noahQuest.cards,
    user: state.user.user,
    userLoaded: state.user.userLoaded,
    trophy: state.user.trophy
  }
};

export default connect(mapStateToProps, {
  noahQuestInit, 
  noahQuestUpdate,
  userTrophyFetch, 
  userFetch, 
  userTrophyCreate
}) (NoahQuest);