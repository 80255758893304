import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon, CursorArrowRaysIcon, ClockIcon } from '@heroicons/react/24/outline'
import { findOutInit, findOutUpdate } from '../../actions/findOutActions';
import { buttonSize } from '../../helpers/GameHelper'

import Item from "./FindOut/Item"
import Cover from "./FindOut/Cover"
import GameRuleModal from "./Modals/GameRuleModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"
import GameIntroVideo from '../../elements/GameIntroVideo'
import FrameImage from '../../assets/games/frame4.png'
import TopFrameImage from '../../assets/games/top_frame.jpg'
import PauseGif from '../../assets/animations/pause.gif'
import WellDoneGif from '../../assets/animations/well_done.gif'
import { lookupTable, coverPresent, folderName, instructions, heading, videoId, gameName } from './FindOut/FindOutData'
import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';
import { trophyConstant } from '../../helpers/ConstantHelper'

import '../../stylesheets/gameSetting.css';

let timeLoop
const actualDisplayWidth = 1720
const actualBgWidth = 3000  
const actualBgHeight = 1688 
const actualFrameWidth = 1609
const actualFrameHeight = 1056
const actualTopFrameHeight = 152
const menuWidth = 120 
const timeBonusScore = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]

const goldScore = 1250
const silverScore = 900

class SpotHidden extends React.Component {
  constructor(props) {
    super(props);
    var displayWidth = this.displayWidth(window.outerWidth)
    var sizeRate = displayWidth/actualDisplayWidth
    var bgWidth = displayWidth - menuWidth * sizeRate
    var bgSizeRate = bgWidth/actualBgWidth
    var bgHeight = this.backgroundHeight(bgWidth)
    var frameHeight = actualFrameHeight/actualFrameWidth * bgWidth
    var displayHeight = frameHeight 
    let game = props.match.params.game
    let gameId = lookupTable[game]
    let gameState = "pending"
    let gameRule = false 

    if(videoId[gameId] == ""){ 
      gameState = "ready"
      gameRule = true
    }
    this.state = { 
      audioOn: true, 
      gameRule: gameRule,
      wellDoneModal: false, 
      bgMusic: new Audio('/audio/background/fluffing_a_duck.mp3'), 
      windowWidth: window.outerWidth, 
      windowHeight: window.outerHeight, 
      displayWidth: displayWidth,
      displayHeight: displayHeight, 
      frameHeight: frameHeight, 
      bgWidth: bgWidth, 
      bgHeight: bgHeight, 
      sizeRate: sizeRate,
      bgSizeRate: bgSizeRate,
      pauseAnimation: PauseGif,
      score: 0, 
      bonusScore: 5, 
      error: false, 
      gameId: null, 
      gameState: gameState, 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      correctBuzzer: new Audio('/audio/correct_buzzer.mp3'),
      wrongBuzzer: new Audio('/audio/wrong_buzzer.mp3'),
      imageLoaded: 0,
      result: 0,
      time: 0,
      trophy: "", 
      activeUser: false, 
      gameStateintroVideoStarted: false
    };
    this.refArray = [] 
    for(var i = 0; i < 10; i++ ){ 
      this.refArray.push(createRef())
    }
  }

  handleCorrectClick(index, e) {
    if(this.state.audioOn){ 
      this.state.correctBuzzer.currentTime = 0
      this.state.correctBuzzer.volume = 0.2
      this.state.correctBuzzer.play()
    }
    this.props.findOutUpdate(index, true)
    var score = this.state.error? 10 : this.state.bonusScore + 10
    let timeBonus = 0
    let time = this.state.time
    if(time < 50){ 
      timeBonus = timeBonusScore[parseInt(time/5)]
    }
    this.setState({score: this.state.score + score + timeBonus})
    this.setState({error: false, result: this.state.result + 1})
    this.setState({bonusScore: this.state.bonusScore + 5})

    if(this.props.selectedIds.length + 1 == 10){ 
      var thisObject = this
      setTimeout(function(){ 
        let trophy = null
        if(thisObject.state.score >= goldScore){
          if(!thisObject.props.trophy || thisObject.props.trophy.gold != true){ 
            trophy = "gold" 
          }
        }else if(thisObject.state.score >= silverScore){ 
          if(!thisObject.props.trophy || thisObject.props.trophy.silver != true){ 
            trophy = "silver" 
          }
        }else{ 
          if(!thisObject.props.trophy || thisObject.props.trophy.bronze != true){ 
            trophy = "bronze" 
          }
        }
        if(thisObject.state.activeUser && trophy){ 
          thisObject.props.userTrophyCreate("game", thisObject.props.match.params.game, trophyConstant[trophy]) 
        }
        thisObject.setState({gameState: "done", wellDoneModal: true, trophy: trophy})
        if(thisObject.state.audioOn){
          thisObject.state.bgMusic.pause()
          thisObject.state.cheerAudio.play()
        }
        clearInterval(timeLoop)
      }, 500)  
    }
  }

  handleWrongClick(e){ 
    if(this.state.audioOn){ 
      this.state.wrongBuzzer.currentTime = 0
      this.state.wrongBuzzer.volume = 0.3
      this.state.wrongBuzzer.play()
    }
    this.setState({bonusScore: 5})
    this.setState({error: true})
  }

  handleWindowSizeChange(){
    var displayWidth = this.displayWidth(window.outerWidth)

    var sizeRate = displayWidth/actualDisplayWidth
    var bgWidth = displayWidth - menuWidth * sizeRate
    var bgHeight = this.backgroundHeight(bgWidth)
    var frameHeight = actualFrameHeight/actualFrameWidth * bgWidth
    var displayHeight = frameHeight
    this.setState({ windowWidth: window.outerWidth, windowHeight: window.innerHeight });
    this.setState({ bgWidth: bgWidth, bgHeight:bgHeight });
    this.setState({ displayWidth: displayWidth, displayHeight:displayHeight});
    this.setState({ frameHeight: frameHeight, sizeRate: sizeRate})   
  };


  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }
  handlePause(){ 
    this.state.bgMusic.pause();
    this.setState({pause: true})    
  }
  handleUnpause(){ 
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleTabInactive(){
    this.state.bgMusic.pause();
    switch(this.state.gameState){ 
      case "running": 
        this.setState({pause: true})
        break;
      case "endVideo":
        this.state.endVidRef.current.pause();
        break;
      default:
    }
  };

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
  }
  handleRestart(){ 
    let game = this.props.match.params.game
    let gameId = lookupTable[game]
    if(!gameId){ 
      gameId = 0
    }
    this.initItems(gameId)
    this.setState({gameState: "pending"}) 
    if(this.state.audioOn == true){ 
      this.state.bgMusic.currentTime = 0
      this.state.bgMusic.play()
    }
    let thisObj = this
    setTimeout(() => { 
      thisObj.initStates()
    }, 300)
  }

  handleStartPlay(){
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    } 
    this.setState({gameRule: false})
    this.initStates()
  }

  handleExit(){ 
    this.props.history.goBack()
  }

  handleIntroPlay(){
    this.setState({introVideoStarted: true})
  }

  handleIntroEnd(){ 
    this.setState({gameState: "ready", gameRule: true})
  }

  resultDisplay(){ 
    return `${this.state.result}/10`
  }

  timeDisplay(){ 
    var minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  initStates(){ 
    this.setState({gameState: "running"})
    this.setState({score: 0, bonusScore: 10, time: 0, result: 0})
    let thisObj = this
    timeLoop = setInterval(() => { 
      if(!thisObj.state.pause){ 
        if(thisObj.state.gameState == "running" && thisObj.state.time < 600000){
          thisObj.setState({time: thisObj.state.time + 1})
        }
      }
    }, 1000)
  }

  displayWidth(width){ 
    if(width > 2000 ){ 
      return 0.9 * 2000
    }
    if(width > 900){ 
      return 0.88 * width
    }
    return width
  }

  buttonTop(){ 
    return (this.state.frameHeight - 132/1763 * this.state.bgWidth)/2
  }


  backgroundHeight(width){ 
   var height = parseFloat(width) * 9 / 16
    return (parseFloat(width) * 9 / 16)
  }

  componentDidUpdate(prevProps) {
    var gameString = "find-out-at" + this.props.match.params.game
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true, gameState:"ready", gameRule: true})
        this.props.userTrophyFetch("game", gameString)
      }
    }
  }

  componentDidMount() {
    let game = this.props.match.params.game
    var gameId = lookupTable[game]
    if(!gameId){ 
      window.history.replaceState(null, "find-out-at-garden-of-eden", "/games/find-out/garden-of-eden")
      gameId = 0
    }
    this.initItems(gameId)
    this.setState({gameId: gameId})
    this.props.userFetch();
    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.addEventListener('blur', this.handleTabInactive.bind(this));
    //window.history.replaceState(null, "find-out-in-garden", "/games/find-out/test")

    let folder = folderName[gameId]
    const images = [] 
    for(var i = 0; i < 10; i++ ){ 
      const img = new Image()
      img.src = `/images/games/find_out/${folder}/items/${i}.webp`;
      img.onload = () => {
        this.setState({ imageLoaded: this.state.imageLoaded + 1 });
      }
      images.push(img)
    } 
    const img = new Image()
    img.src = `/images/games/find_out/${folder}/background.webp`;
    img.onload = () => {
      this.setState({ imageLoaded: this.state.imageLoaded + 1 });
    }
    images.push(img)
    // Load gif files 
    var thisObject = this
    setTimeout(function(){ 
      const img = new Image()
      img.src = WellDoneGif
    }, 2000)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.removeEventListener('blur', this.handleTabInactive.bind(this));
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          trophy = { this.state.trophy }
          searchParams = { '?tab=game'}
          game = { gameName[this.state.gameId] }
          activeUser = { this.state.activeUser }
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  initItems(gameId){ 
    var isSelectedArray = new Array(10).fill(false);
    this.props.findOutInit(isSelectedArray)
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  hiddenItems(){ 
    return this.props.isSelectedArray.map((isSelected, index) => {
      return( 
        <Item 
          index={index} 
          folderName={folderName[this.state.gameId]}
          gameId={this.state.gameId}
          isSelected={isSelected}
          sizeRate={this.state.sizeRate}
          bgSizeRate={this.state.bgSizeRate}
        />
      )
    })
  }

  hiddenItemCovers(){ 
    let folder = `/images/games/find_out/${folderName[this.state.gameId]}/items`
    if(coverPresent[this.state.gameId]){ 
      folder = `/images/games/find_out/${folderName[this.state.gameId]}/covers`
    }
    return this.props.isSelectedArray.map((isSelected, index) => {
      return( 
        <Cover
          index={index} 
          folder={folder}
          gameId={this.state.gameId}
          isSelected={isSelected}
          sizeRate={this.state.sizeRate}
          bgSizeRate={this.state.bgSizeRate}
          clickHandler={this.handleCorrectClick.bind(this, index)}
        />
      )
    })
  }

  selectedItems(){ 
    var height = this.state.displayHeight/10
    return this.props.selectedIds.map((id, index) => {
      return( 
        <div className="flex justify-center mx-auto my-auto"
          style={{
              height: `${height}px`,
              width:`${menuWidth*this.state.sizeRate}px`,
            }}  
        >
          <img className="object-contain"
            style={{
                maxWidth: "100%"
              }}  
            src={`/images/games/find_out/${folderName[this.state.gameId]}/items/${id}.webp`}  
          />
        </div> 
      )
    })
  }

  coverDiv(){ 
    if(this.state.gameState != "running" || this.state.pause == true){ 
      return (
        <div className={"bg-black z-40"}
              style = {{position: 'absolute',    
                        top: `${150 * this.state.sizeRate}px`,
                        left: `${menuWidth * this.state.sizeRate}px`,
                        width: `${this.state.bgWidth}px`,
                        height: `${this.state.bgHeight}px`, 
                        overflow: 'hidden'}}                        
        >
        </div>
      )
    }
    return <div/>
  }

  framePicDiv(){ 
    return( 
      <div className="${this.framePicClass()}"
        style={{ 
          position: 'relative',
          width: `${this.state.bgWidth}px`,
          height: `${this.state.frameHeight}px`,
        }}
      >
        <div className="z-20 my-auto mx-auto" 
          style={{position: 'absolute', 
              top: `0px`,
              left: `${menuWidth * this.state.sizeRate}px`,
              width: `${this.state.bgWidth}px`,
              height: `${this.state.frameHeight}px`,
              backgroundImage: `url(${FrameImage})`,
              backgroundSize: `${this.state.bgWidth}px ${this.state.frameHeight}px`, 
              backgroundRepeat: 'no-repeat'
          }}
          onClick={this.handleWrongClick.bind(this)}
        />
        {/* Actual Frame */}
        <div className="z-40 my-auto mx-auto" 
          style={{position: 'absolute', 
              top: `0px`,
              left: `${menuWidth * this.state.sizeRate}px`,
              width: `${this.state.bgWidth}px`,
              height: `${this.state.bgWidth * actualTopFrameHeight/actualFrameWidth}px`,
              backgroundImage: `url(${TopFrameImage})`,
              backgroundSize: `${this.state.bgWidth}px ${this.state.bgWidth * actualTopFrameHeight/actualFrameWidth}px`, 
              backgroundRepeat: 'no-repeat'
          }}
          
        >
          <button class="focus:outline-none fancy-round-button green"
              style={{position: 'relative', 
                top: `${20 * this.state.sizeRate}px`,
                left: `${1343 * this.state.sizeRate}px`,
                width: `${100 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handleAudioToggle.bind(this)}                
            >
              {this.volumeIcon(100 * this.state.sizeRate )}
            </button>
            <button className="focus:outline-none fancy-round-button red"
              style={{position: 'absolute', 
                top: `${20 * this.state.sizeRate}px`,
                left: `${1475 * this.state.sizeRate}px`,
                width: `${100 * this.state.sizeRate}px`,
                height: `${105 * this.state.sizeRate}px`,
              }}
              onClick={this.handlePause.bind(this)}
            >
              <p className="text-white font-black" 
                style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
                <PauseCircleIcon className="mx=auto"/>
              </p>
            </button>
            <div
              style = {{
                position: 'absolute',    
                top: `${5 * this.state.sizeRate}px`,
                left: `${30 * this.state.sizeRate}px`,
                width: `${350 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="grid grid-cols-2">           
                <CursorArrowRaysIcon className="mx-auto text-white" 
                  style={{
                    width: `${65 * this.state.sizeRate}px`, 
                    height: `${65 * this.state.sizeRate}px`,
                  }} 
                  aria-hidden="true"
                />
                <div>
                  <p className="text-right frame-font text-white unselectable"
                    style = {{ lineHeight: `${70 * this.state.sizeRate}px`, fontSize: `${60 * this.state.sizeRate}px` }}
                  >
                    {this.resultDisplay()}
                  </p>
                </div>
              </div>              
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${70 * this.state.sizeRate}px`,
                left: `${30 * this.state.sizeRate}px`,
                width: `${350 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="grid grid-cols-2">  
                <ClockIcon className="mx-auto text-white pt-1" 
                  style={{
                    width: `${65 * this.state.sizeRate}px`, 
                    height: `${65 * this.state.sizeRate}px`,
                  }} 
                  aria-hidden="true"
                />
                <div className="my-auto">
                  <div className="text-right frame-font text-white unselectable"
                    style = {{ lineHeight: `${70 * this.state.sizeRate}px`, fontSize: `${60 * this.state.sizeRate}px` }}
                  >
                   {this.timeDisplay()}
                  </div> 
                </div>
              </div>              
            </div> 
            {/* Score */}
            <div className="unselectable flex"
              style = {{
                position: 'absolute',    
                top: `${0 * this.state.sizeRate}px`,
                left: `${676 * this.state.sizeRate}px`,
                width: `${250 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="h-full m-auto">
                <p className="text-center game-desc text-white"
                  style = {{ lineHeight: `${70 * this.state.sizeRate}px`, fontSize: `${60 * this.state.sizeRate}px` }}
                >
                  Score  
                </p>  
              </div>      
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${65 * this.state.sizeRate}px`,
                left: `${676 * this.state.sizeRate}px`,
                width: `${250 * this.state.sizeRate}px`, 
                height: `${70 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="text-center frame-font text-white unselectable"
                style = {{ fontSize: `${60 * this.state.sizeRate}px` }}
              >
                {this.state.score}
              </div>              
            </div> 
          </div>
          {this.coverDiv()}
          <div className={`z-0`}
                style = {{position: 'absolute',    
                          top: `${150 * this.state.sizeRate}px`,
                          left: `${menuWidth * this.state.sizeRate}px`,
                          width: `${this.state.bgWidth}px`,
                          height: `${this.state.bgHeight}px`,
                          background: 'transparent',
                          overflow: 'hidden'}}                        
          >
            <img className="unselectable w-full h-full" style={{objectFit: "fill"}} 
              src={`/images/games/find_out/${folderName[this.state.gameId]}/background.webp`} />
          </div>
          {this.hiddenItems()}
          {this.hiddenItemCovers()}
        </div>
    )
  }

  menuDiv(){ 
    return( 
      <div> 
        <div 
          className="z-0 bg-white border-l-2 border-t-2 border-b-2 border-black" 
          style = {{
            position: 'absolute',    
            top: `0px`,
            left: `0px`,
            width: `${menuWidth * this.state.sizeRate}px`, 
            height: `${this.state.displayHeight}px`,
          }}
        >   
          {this.selectedItems()}
        </div> 
      </div> 
    ) 
  }

  gameRuleModal(){ 
    let gameId = lookupTable[this.props.match.params.game]
    if(!gameId){ 
      gameId = 0
      window.history.replaceState(null, "find-out-at-garden-of-eden", "/games/find-out/garden-of-eden")
    }
    return( 
      <GameRuleModal
        open = { this.state.gameRule }
        audioToggleHandler = { this.handleAudioToggle.bind(this)}
        startPlayHandler = { this.handleStartPlay.bind(this)}
        audio = { this.state.audioOn }
        thumbnail = { `/images/games/find_out/${folderName[this.state.gameId]}/rule_thumb.webp` }
        heading = { heading[gameId] }
        instructions = { instructions[gameId] }
      />
    )
  }

  doneDiv(){ 
    return(
      <div className="grid justify-items-center ">
        <div className="mx-auto"
          style={{ 
            position: 'relative',
            width: `${this.state.displayWidth}px`,
            height: `${this.state.displayHeight}px`,
          }}
        > 
          <img className="object-contain w-full h-full"
            src={`/images/games/find_out/${folderName[this.state.gameId]}/background.webp`}
          />
          <div className="justify-items-center mt-2 grid grid-cols-2">
            <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
              onClick={(e) => window.location.href = "/games" } 
            >
              <span> 
                <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                  style={{transform: "rotateY(180deg)"}} 
                />
              </span> 
              <span className="my-auto hidden md:block">
                Exit
              </span>
             
            </button>
            <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
              onClick={ this.handleRestart.bind(this) }
            >
              <span> 
                <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
              </span> 
              <span className="my-auto hidden md:block">
                Play Again
              </span>
            </button>
          </div>
        </div>
      </div> 
    )
  }

  introVideoDiv(){ 
    let gameId = lookupTable[this.props.match.params.game]
    let id = videoId[gameId]
    if(this.props.userLoaded && !this.state.activeUser){ 
      return(
        <GameIntroVideo
          introEndHandler = { this.handleIntroEnd.bind(this)}
          introPlayHandler = { this.handleIntroPlay.bind(this)}
          introTextEnabled = { !this.state.introVideoStarted && this.props.userLoaded }
          videoId = { id }
        />
      )
    }
  }

  gameDiv(){ 
    if(this.state.gameState=="done" ){ 
      return this.doneDiv()
    }
    if(this.state.gameState == "pending"){ 
      return this.introVideoDiv()
    }
    return(
      <div className="grid justify-items-center ">
        <div className="mx-auto"
          style={{ 
            position: 'relative',
            width: `${this.state.displayWidth}px`,
            height: `${this.state.displayHeight}px`,
          }}
        > 
          {this.menuDiv()}
          {this.framePicDiv()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div> 
        {this.pauseModal()}
        {this.gameRuleModal()}
        {this.wellDoneModal()}
        <div className="bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-h-screen py-3 md:py-5">
          {this.gameDiv()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    isSelectedArray: state.findOut.isSelectedArray,
    selectedIds: state.findOut.selectedIds,
    user: state.user.user,
    userLoaded: state.user.userLoaded,
    trophy: state.user.trophy
  }
};

export default connect(mapStateToProps, { 
  findOutInit, 
  findOutUpdate,
  userTrophyFetch, 
  userFetch, 
  userTrophyCreate
}) (SpotHidden);