import {
  PAINT_THE_WORLD_UPDATE,
  PAINT_THE_WORLD_FETCH
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    images: [
      {id:0,  pic: "darkness",     day: 0, sequence:1, selected: true,  z_index: "z-0", sound:"" },
      {id:1,  pic: "light",        day: 1, sequence:1, selected: false, z_index: "z-10", sound: "light.mp3"},
      {id:2,  pic: "sky",          day: 2, sequence:1, selected: false, z_index: "z-20", sound: "whoosh.mp3" },
      {id:3,  pic: "sea",          day: 3, sequence:1, selected: false, z_index: "z-30", sound: "sea.mp3" },
      {id:4,  pic: "land",         day: 3, sequence:1, selected: false, z_index: "z-40", sound: "whoosh.mp3" },
      {id:5,  pic: "bush",         day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:6,  pic: "grass",        day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:7,  pic: "flower",       day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:8,  pic: "apple_tree",   day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:9,  pic: "banana_tree",  day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:10, pic: "coconut_tree", day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:11, pic: "pumpkin",      day: 3, sequence:2, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:12, pic: "moon",         day: 4, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:13, pic: "sun",          day: 4, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:14, pic: "star",         day: 4, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:15, pic: "fish",         day: 5, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:16, pic: "whale",        day: 5, sequence:1, selected: false, z_index: "z-50", sound: "whale.mp3" },
      {id:17, pic: "lobster",      day: 5, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:18, pic: "dolphin",      day: 5, sequence:1, selected: false, z_index: "z-50", sound: "dolphin.mp3" },
      {id:19, pic: "owl",          day: 5, sequence:1, selected: false, z_index: "z-50", sound: "owl.mp3" },
      {id:20, pic: "seagull",      day: 5, sequence:1, selected: false, z_index: "z-50", sound: "seagull.mp3" },
      {id:21, pic: "hedgehog",     day: 6, sequence:1, selected: false, z_index: "z-50", sound: "hedgehog.mp3" },
      {id:22, pic: "tiger",        day: 6, sequence:1, selected: false, z_index: "z-50", sound: "tiger.mp3" },
      {id:23, pic: "monkey",       day: 6, sequence:1, selected: false, z_index: "z-50", sound: "monkey.mp3" },
      {id:24, pic: "butterfly",    day: 6, sequence:1, selected: false, z_index: "z-50", sound: "whoosh.mp3" },
      {id:25, pic: "bulldog",      day: 6, sequence:1, selected: false, z_index: "z-50", sound: "bulldog.mp3" },
      {id:26, pic: "man",          day: 6, sequence:2, selected: false, z_index: "z-50", sound: "man.mp3" },
      {id:27, pic: "woman",        day: 6, sequence:2, selected: false, z_index: "z-50", sound: "woman.mp3" },
    ], 
  };
  
export default (state = INITIAL_STATE, action) => { 
  switch(action.type) {     
    case PAINT_THE_WORLD_UPDATE:  
      const newArray = [...state.images]; //making a new array
       newArray[action.index].selected = action.value//changing value in the new array
       return { 
        ...state, //copying the original state
        images: newArray, //reassingning cards to new array
       }
    case PAINT_THE_WORLD_FETCH: 
      const newFetchArray = [...state.images];
      newFetchArray[action.index].rawImage = action.payload
      
      return { 
         ...state, //copying the original state
        images: newFetchArray, //reassingning rawImage to new array
      }
    default:  
      return state; 
  } 
}; 