import React, { Component } from 'react';
import { connect } from 'react-redux';

import { svgInfo } from './JigsawData'

import '../../../stylesheets/jigsawPuzzles.css';

class Shadow extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    const puzzle = this.props.puzzle
    return (
      <div className="z-10">

           <svg className="puzzles-active" viewBox={svgInfo[puzzle.id -1].viewBox}
            style={{ 
              position: 'absolute',
              left: `${puzzle.left}px`,
              top: `${puzzle.top}px`,
              width: `${puzzle.width}px`,
              height: `${puzzle.height}px`,
            }}
          >        
            <path fill="black" d={svgInfo[puzzle.id -1].path}
              onDragStart={this.props.dragStartHandler}
              onDragEnd={this.props.dragEndHandler}
              onMouseMove={this.props.mouseMoveHandler}
              onMouseDown={this.props.mouseDownHandler}
            />
          </svg> 
        </div> 
    );
  }
}


export default connect()(Shadow);