import React, { createRef, Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/congrats.json'

import ShareModal from "../../../elements/ShareModal"

import '../../../stylesheets/fancyButton.css';

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowPathIcon, ArrowRightOnRectangleIcon, XMarkIcon, PlayIcon } from '@heroicons/react/24/outline'

class WellDoneModal extends React.Component { 
  constructor(props) {
    super(props);
    var completeButtonRef = createRef();

    this.state = { 
      open: true,
      shareModal: false
    }
  }

  handleOpenShareModal(){ 
    this.setState({shareModal: true})
  }

  handleCloseShareModal(){ 
    this.setState({shareModal: false})
  }
  /// Dialog Title 
  // Without Trophy: Well Done 
  // With Trophy: Congratulation
  headingTitle(){ 
    if(this.props.trophy){ return "Congratulation"}
    return "Well Done"
  }

  shareModal(){ 
    let twitterTitle = `${this.props.topic}: Play this game at BibleFunClub`
    let WhatsappTitle = `Check out this game I am playing -`
    if(this.props.game){ 
      twitterTitle = `${this.props.topic}: Play this quiz at BibleFunClub`
      WhatsappTitle = `Check out this quiz I am playing -`
    }
    if(this.state.shareModal){ 
      return <ShareModal 
        open = { this.state.shareModal }
        twitterTitle = { twitterTitle }
        WhatsappTitle = { WhatsappTitle }
        url = { window.location }
        closeHandler = { this.handleCloseShareModal.bind(this) }
      />
    }
  }

  //  Heading title, img and score
  modalTopLayout(){ 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };

    if(isMobile && window.innerWidth/window.innerHeight > 1){ 
      return( 
        <div className="grid grid-cols-2 gap-2">
          <div>
            <Lottie options={defaultOptions} 
              isStopped={false}
              isPaused={false}
            />
          </div>
          <div className="grid grid-rows-2 gap-2 justify-items-center">
            <div className="w-full h-full bg-primary-color rounded-lg">
              <Dialog.Title as="h3" className="mt-4 text-center text-white modal-text-title text-3xl md:text-4xl leading-normal font-bold">
                { this.headingTitle() }
              </Dialog.Title>
            </div> 
            <div className="my-auto">
              <p className="game-desc text-2xl md:text-3xl text-white">
                Your score: {this.props.score} 
              </p> 
            </div>
          </div>
        </div>
      )
    }else{
      return( 
        <div> 
          <div className="px-2 py-2 md:px-3 md:py-3 bg-primary-color">
            <div className="mb-4 content-between">
              <div className="text-center">
                <Dialog.Title as="h3" className="mt-2 text-white modal-text-title text-3xl md:text-4xl leading-6 font-bold">
                   { this.headingTitle() }
                </Dialog.Title>
              </div>
            </div>
          </div>
          <div className="pt-4"> 
            <Lottie options={defaultOptions} 
              width={ isMobile? 300: 380 }
              isStopped={false}
              isPaused={false}
            />
            <div className="px-3 pt-3 md:px-4 md:pt-4">
              <div className="text-center">
                <p className="game-desc text-2xl md:text-3xl text-white">
                  Your score: {this.props.score} 
                </p> 
              </div>
            </div>
          </div>
        </div>
      ) 
    }
  }

  trophyText(){ 
    let text = `You've earned a ${this.props.trophy} trophy.`
    if(!this.props.activeUser){ 
      text = text + " Login to collect!"
    }
    return text
  }

  trophyButtonTextLink(){ 
    let text = "Login"
    let link = "/users/sign_in"
    if(this.props.activeUser){ 
      text = "View"
      link = `/user/trophies${this.props.searchParams}`
    }
    return [text, link]
  }

  // with login: View
  // without login: Login
  trophy(){ 
    let imgSource = null
    if(this.props.trophy){
      switch(this.props.trophy){ 
        case "gold": 
          imgSource = "/images/trophies/gold.webp"
          break;
        case "silver": 
          imgSource = "/images/trophies/silver.webp"
          break;
        case "bronze":
          imgSource = "/images/trophies/bronze.webp"
          break;
      }
      let buttonTextLink = this.trophyButtonTextLink()
      return(
        <div className="bg-primary-color mx-0 md:mx-2 md:rounded-lg my-2 px-2 py-2 md:px-4 md:py-4">
          <div className="my-auto mx-auto justify-items-center grid grid-cols-4 grid-rows-2">
            <div className="-mt-8 md:-mt-7 row-start-1 row-span-2 col-start-1">
              <img  src={imgSource} alt="" className={`z-0 h-32 md:h-36 inset-0 m-auto object-cover`} />
            </div> 
            <div className= "row-start-1 col-start-2 col-span-3 my-auto">
              <p className="game-desc mx-2 text-center text-1xl text-primary-dark">
                { this.trophyText() }
              </p> 
            </div> 
            <div className= "row-start-2 col-start-2 col-span-3">
              <button className="w-18 md:w-28 fancy-button orange"
                ref={this.completeButtonRef}
                onClick={(e) => window.location.href = buttonTextLink[1] } 
                onTouchStart={(e) => window.location.href = buttonTextLink[1] } 
              >
               { buttonTextLink[0] }
              </button>
            </div> 
          </div>
        </div>
      )
    }
  }

  // Button text: Ok unless overriden with props.okText
  buttons(){ 
    let buttonText = "Ok"
    if(this.props.okText) { buttonText = this.props.okText }
    return(
      <div className="my-auto mx-auto justify-items-center grid grid-cols-2">
        <button className="w-24 md:w-36 fancy-button orange"
          ref={this.completeButtonRef}
          onClick={ this.handleOpenShareModal.bind(this) }
          onTouchStart={ this.handleOpenShareModal.bind(this) } 
        >
          Share
        </button>
        <button className="w-24 md:w-36 fancy-button green"
          ref={this.completeButtonRef}
          onClick={ this.props.endHandler }
          onTouchStart={ this.props.endHandler } 
        >
          { buttonText }
        </button>
      </div>
    )
  }

  render(){ 
    return (
      <Transition.Root show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-30 inset-0 overflow-y-auto"
          initialFocus={this.completeButtonRef}
          open={this.state.open}
          onClose={this.props.endHandler}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-12 md:pb-20 text-center sm:block sm:p-0">
            { this.shareModal() }
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="bg-primary-dark inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm lg:max-w-md sm:w-full sm:p-6">
                { this.modalTopLayout() }
                { this.trophy() }
                <div className="px-1 py-1 md:px-4 md:py-4">
                  { this.buttons()}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
}

export default connect()(WellDoneModal)
