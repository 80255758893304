import React from 'react';

let maze 

export function initArray(value, rows, cols) {
  return new Array(rows).fill().map(() => new Array(cols).fill(value));
}

export function rand(min, max) {
	return min + Math.floor(Math.random() * (1 + max - min));
}

export function posToSpace(x) {
  return 2 * (x-1) + 1;
}

export function posToWall(x) {
  return 2 * x;
}

export function inBounds(maze, r, c) {
  if((typeof maze[r] == "undefined") || (typeof maze[r][c] == "undefined")) {
    return false; // out of bounds
  }
  return true;
}

export function shuffle(array) {
  // sauce: https://stackoverflow.com/a/12646864
  for(let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function partition(maze, r1, r2, c1, c2) {
  // create partition walls
  // ref: https://en.wikipedia.org/wiki/Maze_generation_algorithm#Recursive_division_method

  let horiz, vert, x, y, start, end;

  if((r2 < r1) || (c2 < c1)) {
    return false;
  }

  if(r1 == r2) {
    horiz = r1;
  } else {
    x = r1+1;
    y = r2-1;
    start = Math.round(x + (y-x) / 4);
    end = Math.round(x + 3*(y-x) / 4);
    horiz = rand(start, end);
  }

  if(c1 == c2) {
    vert = c1;
  } else {
    x = c1 + 1;
    y = c2 - 1;
    start = Math.round(x + (y - x) / 3);
    end = Math.round(x + 2 * (y - x) / 3);
    vert = rand(start, end);
  }

  for(let i = posToWall(r1)-1; i <= posToWall(r2)+1; i++) {
    for(let j = posToWall(c1)-1; j <= posToWall(c2)+1; j++) {
      if((i == posToWall(horiz)) || (j == posToWall(vert))) {
        maze[i][j] = ["wall"];
      }
    }
  }

  let gaps = shuffle([true, true, true, false]);

  // create gaps in partition walls

  if(gaps[0]) {
    let gapPosition = rand(c1, vert);
    maze[posToWall(horiz)][posToSpace(gapPosition)] = [];
  }

  if(gaps[1]) {
    let gapPosition = rand(vert+1, c2+1);
    maze[posToWall(horiz)][posToSpace(gapPosition)] = [];
  }

  if(gaps[2]) {
    let gapPosition = rand(r1, horiz);
    maze[posToSpace(gapPosition)][posToWall(vert)] = [];
  }

  if(gaps[3]) {
    let gapPosition = rand(horiz+1, r2+1);
    maze[posToSpace(gapPosition)][posToWall(vert)] = [];
  }

  // recursively partition newly created chambers

  partition(maze, r1, horiz-1, c1, vert-1);
  partition(maze, horiz+1, r2, c1, vert-1);
  partition(maze, r1, horiz-1, vert+1, c2);
  partition(maze, horiz+1, r2, vert+1, c2);

  return maze
}

export function isWall(maze, row, col) {

  if(maze[row][col].length > 0) {
    if(maze[row][col].includes("wall")) {
      return true;
    }
  }
  return false
}

export function countSteps(maze, array, r, c, val, stop) {
  //console.log(`countStep: ${r} ${c} ${val}`)
  if(!inBounds(maze, r, c)) {
    //debugger
    return false; // out of bounds
  }

  if(array[r][c] != "" && array[r][c] <= val) {
    //debugger
    return false; // shorter route already mapped
  }

  if(isWall(maze, r, c)){ 
    return false;
  }
  //console.log(`record: ${r} ${c} ${val}`)
  array[r][c] = val;

  if(maze[r][c].includes(stop)) {
    return true; // reached destination
  }

  countSteps(maze, array, r-1, c, val+1, stop);
  countSteps(maze, array, r, c+1, val+1, stop);
  countSteps(maze, array, r+1, c, val+1, stop);
  countSteps(maze, array, r, c-1, val+1, stop);
}

export function getSheepLocation(maze, rows, cols) {

  let fromEntrance = initArray("", rows, cols);
  let fromExit = initArray("", rows, cols);

  let totalSteps = -1;

  for(let j = 1; j < cols-1; j++) {
    if(maze[rows-1][j].includes("exit")) {
      countSteps(maze, fromExit, rows-1, j, 0, "entrance");
    }
    if(maze[0][j].includes("entrance")) {
      countSteps(maze, fromEntrance, 0, j, 0, "exit");
    }
  }
  console.dir(fromEntrance)
  console.dir(fromExit)
  let fc = -1, fr = -1;

  maze.forEach((row, r) => {
    row.forEach((cell, c) => {
      if(typeof fromEntrance[r][c] == "undefined") {
        return;
      }
      let stepCount = fromEntrance[r][c] + fromExit[r][c];
      if(stepCount > totalSteps) {
        fr = r;
        fc = c;
        totalSteps = stepCount;
      }
    });
  });
  console.log(`sheep location: ${fr} ${fc}`)
  return [fr, fc];
}

export function placeSheep(maze, rows, cols) {

  let fr, fc;
  [fr, fc] = getSheepLocation(maze, rows, cols);

  maze[fr][fc] = ["sheep"];

  return maze
}

export function mazeGenerator(width, height){ 
	const cols = 2 * width + 1 
	const rows = 2 * height + 1 
	var maze = initArray([], rows, cols)

	maze.forEach((row, r) => {
    row.forEach((cell, c) => {
      switch(r)
      {
        case 0:
        case rows - 1:
          maze[r][c] = ["wall"];
          break;

        default:
          if((r % 2) == 1) {
            if((c == 0) || (c == cols - 1)) {
              maze[r][c] = ["wall"];
            }
          } else if(c % 2 == 0) {
            maze[r][c] = ["wall"];
          }
      }
    });

    if(r == 0) {
      // place entrance in top row
      let doorPos = posToSpace(4);
      maze[r][doorPos] = ["entrance"];
    }
    if(r == rows - 1) {
      // place entrance in bottom row
      let doorPos = posToSpace(rand(1, width));
      maze[r][doorPos] = ["exit"];
    }
  });

  // start partitioning
  partition(maze, 1, height - 1, 1, width - 1);
  return maze
}
