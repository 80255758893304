import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'

class Construction extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 

    }
  }


  render() {
    return (
      <div> 
        <div className="min-h-screen bg-primary-color"> 
          <NavBar/>
          <div className="text-center mx-auto py-2 sm:py-5">
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-5xl">
              Under Construction
            </h2>
          </div>
          <div className="lg:mx-auto lg:max-w-7xl h-screen overflow-hidden flex flex-col">
            <div className="min-h-0 flex-1 flex overflow-auto">
              <div className="w-full bg-primary-color mx-auto sm:px-6 lg:px-8 py-5">
                <img src="/images/in_construction.jpg" alt="" className="mx-auto w-5/6 md:w-1/2 object-contain pointer-events-none" />   
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default connect() (Construction);