import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'

class TermsOfService extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div> 
        <div className="min-h-screen bg-primary-dark"> 
          <NavBar/>
          <div className="overflow-hidden">
            <div className="min-h-screen m-auto relative max-w-7xl bg-primary-color py-6 md:py-10 px-4 sm:px-6 lg:px-8">
              <div>
                <h3 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-4xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-4xl">
                  Terms Of Service
                </h3>
              </div> 
              <div>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                 BibleFunClub.com provides its service to you subject to the following terms and conditions (the "Terms of Service"). 
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Services
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  BibleFunClub.com provides a collection of content, including lessons, quizzes, videos, games, crafts that enable users to enjoy bible through Lego and its surrounding culture (the “Service”). 
                  You understand and agree that the Service is provided “AS-IS” and that BibleFunClub.com does not assume any liability for personal information stored through our Service. 
                  BibleFunClub.com reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. 
                  You agree that BibleFunClub.com shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.

                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Registration
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  In consideration of your use of the Service, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form 
                  and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. 
                  If you provide any information that is untrue, inaccurate, not current or incomplete, or if BibleFunClub.com has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, 
                  BibleFunClub.com has the right to suspend or terminate your account and refuse any and all current or future use of the Service. 
                  BibleFunClub.com is concerned about the safety and privacy of all its users, particularly children. 
                  For this reason, parents, guardians, or school officials of children under the age of 13 who wish to allow their children or students access to the Service must create an account that is maintained by a legal guardian or school representative of at least 18 years old and monitor the child's usage. 
                  By allowing a child access to their account, you also give your child permission to access all areas of the Service. 
                  Please remember that the Service is designed to appeal to a broad audience. Accordingly, as the legal guardian or school representative, it is your responsibility to determine whether any of the Service areas and/or 
                  Content are appropriate for your child.

                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Intellectual property Rights
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  <span> 
                    BibleFunClub.com is the owner or the licensee of all intellectual property rights in our site, and in the material published on it.  
                    Those works are protected by copyright laws and treaties around the world.  All such rights are reserved. 
                    You may print off one copy, and may download extracts, of any page(s) from BibleFunClub.com for your personal reference and you may draw the attention of others within your organisation to material posted on BibleFunClub.com. 
                    You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any photographs, video or any graphics separately from any accompanying text. 
                    Our status (and that of any identified contributors) as the authors of material on our site must always be acknowledged. 
                    You must not use any part of the materials on BibleFunClub.com for commercial purposes without obtaining a licence to do so from us or our licensors. 
                    If you print off, copy or download any part of our site in breach of these terms of use, your right to use BibleFunClub.com will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. 
                    Any  designs created by us belong to us and we reserve the right to sell or distribute images of them at any time.
                  </span>
                  <span>
                    <a onClick={(e) => window.location.pathname = "/contact-us" } class="link-text"> here</a>
                  </span>
                </p>
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Liability
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  To the maximum extent permitted by law, in no event shall company be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, 
                  or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, 
                  or incapability to use the site even if company has been advised of the possibility of such damages. 
                  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Contact Us
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  <span>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us 
                  </span> 
                  <span>
                    <a onClick={(e) => window.location.pathname = "/contact-us" } class="link-text"> here</a>
                  </span>
                </p> 
                <br/>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default connect() (TermsOfService);