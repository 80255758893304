import React from "react";
import { render } from "react-dom";
import $ from 'jquery';
//import Popper from 'popper.js';
import App from "../components/App";

import { BrowserRouter } from 'react-router-dom'

import '../stylesheets/application.css';

document.addEventListener("DOMContentLoaded", () => {
  render(
    <BrowserRouter>
    <App />
    </BrowserRouter>,
    document.body.appendChild(document.createElement("div"))
  );
});
