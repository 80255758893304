import React, { Component } from 'react';
import { connect } from 'react-redux';

import { noahQuestUpdate } from '../../../actions/noahQuestActions';

import cover from '../../../assets/games/noah_quest/cover.jpg'

class Card extends React.Component {
  constructor(props) {
    super(props);
  }

  image(){ 
    return <img style={{maxWidth: "100%", maxHeight: "100%", objectFit: "fill"}} src={`/games/images/noah_quest/`+ this.props.card.pic}/>
  }

  card(){ 
    if(this.props.card.invisible){ 
      return(
        <div className="invisible">
        </div> 
      )
    }
    else{ 
      return(
        <div className="double-sided-card">
          <div className="front">
            <img style={{maxWidth: "100%", maxHeight: "100%", objectFit: "fill"}} src={cover} />
          </div>
          <div className="back">
            {this.image()}
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div ref={this.props.refer} className="card" onClick={this.props.clickHandler}>
        {this.card()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    images: state.noahQuest.images,
    cards: state.noahQuest.cards,
  }
};

export default connect(mapStateToProps, {noahQuestUpdate})(Card);
