import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import queryString from 'query-string';

import NavBar from '../../elements/NavBar'
import Footer from '../../elements/Footer'

import { articleFetch } from '../../actions/articleActions';

const cssStyle = `
    p { @apply text-red; }
`

class ArticleViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryId: queryString.parse(props.location.search).id
    }
  }

  componentDidMount() {
    this.props.articleFetch(this.state.queryId);
  }

  render() {
    if(Object.keys(this.props.article).length){
      const html = this.props.article.content
      return(
        <div className="bg-primary-color overflow-hidden">
         
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
             <style>{cssStyle}</style>
            { ReactHtmlParser(html) }
          </div>
        </div>
      )
    }
    else{ 
      return <div/>
    }
  }
}



const mapStateToProps = (state) => {
  return { 
    article: state.article.data
  }
};

export default connect(mapStateToProps, {
  articleFetch
})(ArticleViewer);
