import { combineReducers } from 'redux'
import NoahQuestReducer from './NoahQuestReducer'
import PaintTheWorldReducer from './PaintTheWorldReducer'
import FindOutReducer from './FindOutReducer'
import SpotDiffReducer from './SpotDiffReducer'
import JigsawPuzzlesReducer from './JigsawPuzzlesReducer'
import LostSheepReducer from './LostSheepReducer'

import JonahGameReducer from './saveJonah/GameReducer'
import WhaleReducer from './saveJonah/WhaleReducer'
import PipeReducer from './saveJonah/PipeReducer'
import ObstacleReducer from './saveJonah/ObstacleReducer'
import BackgroundReducer from './saveJonah/BackgroundReducer'

import ArticleReducer from './ArticleReducer'
import ArticleListReducer from './ArticleListReducer'

import UserReducer from './UserReducer'
import ProgressOwnerReducer from './ProgressOwnerReducer'
import AvatarReducer from './AvatarReducer'

import VideoReducer from './VideoReducer'
import QuizReducer from './QuizReducer'
import GameReducer from './GameReducer'
import LessonReducer from './LessonReducer'
import CraftReducer from './CraftReducer'
import TopicReducer from './TopicReducer'

const RootReducer = combineReducers({
  noahQuest: NoahQuestReducer,
  paintTheWorld: PaintTheWorldReducer, 
  jonahGame: JonahGameReducer, 
  jonahWhale: WhaleReducer, 
  jonahPipe: PipeReducer, 
  jonahBackground: BackgroundReducer,
  jonahObstacle: ObstacleReducer, 
  findOut: FindOutReducer,
  spotDiff: SpotDiffReducer,
  jigsawPuzzles: JigsawPuzzlesReducer,
  lostSheep: LostSheepReducer, 
  article: ArticleReducer, 
  articleList: ArticleListReducer, 
  user: UserReducer, 
  progressOwner: ProgressOwnerReducer,
  avatar: AvatarReducer, 
  video: VideoReducer,
  quiz: QuizReducer, 
  game: GameReducer, 
  lesson: LessonReducer, 
  craft: CraftReducer, 
  topic: TopicReducer
});

export default RootReducer;