import axios from 'axios';

import { GAME_LIST_FETCH_SUCCESS } from './types';

export const gameListFetch = (category) => {
  const url = '/api/games'
  const params = new URLSearchParams([['category', category]]); 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url, {params})
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: GAME_LIST_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};
