import {
  JONAH_OBSTACLE_GENERATE,
  JONAH_OBSTACLE_INIT, 
  JONAH_GAME_RUNNING,
  JONAH_GAME_OVER, 
  JONAH_OBSTACLE_REMOVE, 
  JONAH_OBSTACLE_UPDATE
  } from '../../actions/types.js';

const initialState = {
  obstacles: [], // [{id: 0, type: "bottom"/"top", x: 0, speed: 0}]
  bgWidth: 0 
}

export default (state = initialState, action) => {
  const newArray = [...state.obstacles]
  switch (action.type) {
    case JONAH_OBSTACLE_INIT: 
      return { // returning a copy of orignal state 
              ...state, //copying the original state
              bgWidth: action.bgWidth //new obstacles array 
     }
    case JONAH_OBSTACLE_GENERATE:
     console.log(action.payload)
      return { // returning a copy of orignal state 
              ...state, //copying the original state
              obstacles: [...state.obstacles, action.payload] //new obstacles array 
     }
    case JONAH_OBSTACLE_UPDATE: 
      for(var i = 0; i < newArray.length; i++ ){
        if(newArray[i].type == "middle"){ 

          if(newArray[i].up){ 
            //newArray[i].y = newArray[i].y - 50
          }else{ 
            //newArray[i].y = newArray[i].y + 50
          }
          //console.log(`middle y: ${newArray[i].y}`)
        }
      } 
      return {...state, //copying the orignal state
        obstacles: newArray, //reassingning todos to new array
      }
    case JONAH_GAME_OVER:
      return initialState
    case JONAH_OBSTACLE_REMOVE: 
      newArray.splice(action.index,1)
      return {...state, //copying the orignal state
          obstacles: newArray, //reassingning todos to new array
        }
    default:
      return state
  }
}
