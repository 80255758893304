import { 
         MAZE_HEAD_UPDATE, 
         MAZE_PIC_UPDATE, 
         MAZE_SHEEP_UPDATE
          } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const mazeHeadUpdate = (x, y) => {
  return {
    type: MAZE_HEAD_UPDATE,
    x: x, 
    y: y
  }
};

export const mazePicUpdate = ({ prop, value }) => {
  return {
    type: MAZE_PIC_UPDATE,
    payload: { prop, value }
  }
};

export const mazeSheepUpdate = (x, y) => {
  return {
    type: MAZE_SHEEP_UPDATE,
    x: x, 
    y: y
  }
};