import {
    LESSON_LIST_FETCH_SUCCESS, 
    LESSON_FETCH_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    lessonList: [],
    lesson: {}
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case LESSON_FETCH_SUCCESS: 
        return { ...state, lesson: action.payload };  
      case LESSON_LIST_FETCH_SUCCESS: 
        return { ...state, lessonList: action.payload };  
      default:
        return state;
    }
  };
