import React, { Fragment, useRef, useState } from 'react'
import { Listbox, Dialog, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/24/outline'
import { RadioGroup } from '@headlessui/react'
import { SpeakerXMarkIcon, SpeakerWaveIcon, PlayIcon } from '@heroicons/react/24/outline'
import { isMobile } from "react-device-detect";
import Lottie from 'react-lottie';

import gameRuleAnim from '../../../assets/games/noah_quest/game_rule.json'

import '../../../stylesheets/gameSetting.css';

const levels = [
  { id: 0, name: 'Easy', description: '6 pairs' },
  { id: 1, name: 'Medium', description: '10 pairs'  },
  { id: 2, name: 'Hard', description: '15 pairs' },
]

function volumeIcon(audio){ 
  if(audio == true){ 
    return <SpeakerWaveIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }else{
    return <SpeakerXMarkIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function gameLevel(props){ 
  const [selected, setSelected] = useState(levels[1])
  return(
    <div className="p-4"> 
      <RadioGroup value={levels[props.level]} onChange={props.levelSelectHandler} className="mt-2 cursor-pointer">
      <RadioGroup.Label className="sr-only"> Choose a level </RadioGroup.Label>
      <div className="grid grid-cols-3 gap-3">
        {levels.map((level) => (
          <RadioGroup.Option
            key={level.name}
            value={level}
            className={({ active, checked }) =>
                classNames(
                  active ? 'ring-2 ring-primary-light ring-offset-2' : '',
                  checked
                    ? 'bg-orange-dark text-white'
                    : 'ring-1 ring-inset ring-primary-dark bg-white text-gray-900 hover:bg-primary-color',
                  'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                )
              }
          >
        <RadioGroup.Label as="span">{level.name}</RadioGroup.Label>
        </RadioGroup.Option>
      ))}
      </div>
      </RadioGroup>
    </div>
  )
}

export default function GameRuleModal(props) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef()
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: gameRuleAnim,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg px-4 pt-4 md:pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <Lottie options={defaultOptions} 
                isStopped={false}
                isPaused={false}
              />
              <div className="mt-3 mb-3 text-center">
                <div className="p-2"> 
                </div> 
                <div>
                  <p className="game-desc text-lg md:text-xl text-white mb-2">
                    Click and find the pairs. 
                  </p>
                </div>
              </div>
              
              {gameLevel(props)}
              <div className="justify-items-center mt-3 grid grid-cols-2">
                <button className="w-24 md:w-32 fancy-button orange"
                  onClick={props.audioToggleHandler}
                  onTouchStart={props.audioToggleMobileHandler}
                >
                  <span className="flex items-center justify-center h-10 w-10 md:h-12 md:w-12 ">
                    {volumeIcon(props.audio)}
                  </span>
                 
                </button>
                <button className="w-24 md:w-32 fancy-button green grid grid-cols-2"
                  onClick={props.startPlayHandler}
                  onTouchStart={props.startPlayMobileHandler}
                >
                  <span> 
                    <PlayIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                  </span> 
                  <span className="my-auto hidden md:block">
                    Play
                  </span>
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}