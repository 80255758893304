import axios from 'axios';

import { TOPIC_FETCH_SUCCESS, TOPIC_LIST_FETCH_SUCCESS, TOPIC_INIT } from './types';

export const topicFetch = (id) => {
  const url = '/api/topics/' + `${id}`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: TOPIC_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const topicListFetch = () => {
  const url = '/api/topics/'
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: TOPIC_LIST_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const topicInit = (index) => {
  return {
    type: TOPIC_INIT,
    index: index
  }
};

