import {
    QUIZ_FETCH_SUCCESS, 
    QUIZ_LIST_FETCH_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    questions: [],
    answers: [],
    quizList: []
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case QUIZ_LIST_FETCH_SUCCESS: 
        return { ...state, quizList: action.payload };  
      case QUIZ_FETCH_SUCCESS: 
        return { ...state, questions: JSON.parse(action.payload.questions), answers: JSON.parse(action.payload.answers) };  
      default:
        return state;
    }
  };

