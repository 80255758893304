import {
  JONAH_BACKGROUND_INIT,
  JONAH_BACKGROUND_SCROLL,
  } from '../../actions/types.js';

const initialState = {
  x: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case JONAH_BACKGROUND_INIT: 
      return {...state, x: action.payload}
    case JONAH_BACKGROUND_SCROLL: 
    console.log("scroll")
      return {...state, x: 0}
    default:
      return state
  }
}
