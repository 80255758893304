import axios from 'axios';
import { PAINT_THE_WORLD_UPDATE, PAINT_THE_WORLD_FETCH } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const paintTheWorldUpdate = (index, value) => {
  return {
    type: PAINT_THE_WORLD_UPDATE,
    index: index,
    value: value
  }
};

export const paintTheWorldFetch = (url, index) => { 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url, { 
      responseType: "arraybuffer"
    })
    .then(function (response) {
      if(response.status == 200){ 
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
        dispatch({
          type: PAINT_THE_WORLD_FETCH,
          index: index,
          payload: base64,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}


