import axios from 'axios';

import { USER_FETCH_SUCCESS, 
          USER_SIGN_OUT_SUCCESS, 
          USER_SAVE_SUCCESS, 
          USER_PROFILE_SAVE_SUCCESS,
          USER_AVATAR_ASSETS_FETCH_SUCCESS,
          USER_TROPHY_FETCH_SUCCESS,
          USER_TROPHY_SAVE_SUCCESS,
          USER_TROPHIES_FETCH_SUCCESS,
          USER_UPDATE, 
          USER_AVATAR_OWNER_SAVE_SUCCESS } from './types';

export const userFetch = () => {
  const url = '/api/users/profile' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userAvatarAssetsFetch = () => {
  const url = '/api/users/avatar_assets' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_AVATAR_ASSETS_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSignout = () => { 
  const url = '/users/sign_out' 

  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.delete(url, { 
      headers: {
      'content-type': 'application/json',
      'Accept': 'application/json'
      }
    })
    .then(function (response) {
      if(response.status == 204){ 
        dispatch({
          type: USER_SIGN_OUT_SUCCESS,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSaveName = (name) => {
  const url = '/api/users/profile'
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { profile: {name: name} })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_PROFILE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSaveSkin = (id) => {
  const url = '/api/users/profile'
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { profile: {avatar_skin_id: id} })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_PROFILE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSaveFace = (id) => {
  const url = '/api/users/profile'
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { profile: {avatar_face_id: id} })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_PROFILE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSaveHead = (id) => {
  const url = '/api/users/profile'
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { profile: {avatar_head_id: id} })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_PROFILE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSaveShirt = (id) => {
  const url = '/api/users/profile'
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { profile: {avatar_shirt_id: id} })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_PROFILE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userSave = (id, user) => {
  const url = `/api/users/${id}`
  const token = document.querySelector('[name=csrf-token]').content
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, { user: user })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userUpdate = ({ prop, value }) => {
  return {
    type: USER_UPDATE,
    payload: { prop, value }
  }
};


export const userAvatarOwnersCreate = (item) => { 
  const url = `/api/avatar_owners`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.post(url, { type: item.type, id: item.id })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_AVATAR_OWNER_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}

export const userTrophiesFetch = () => {
  const url = '/api/trophy_owners' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_TROPHIES_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userTrophyFetch = (type, name) => {
  const url = `/api/trophy_owners/${name}`
  const params = new URLSearchParams([['type', type]]);
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url, {params})
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_TROPHY_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const userTrophyCreate = (type, name, trophy) => { 
  const url = `/api/trophy_owners`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.post(url, { name: name, type: type, trophy: trophy })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: USER_TROPHY_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}