import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import Lottie from 'react-lottie';

import calmJson from 'assets/animations/calm.json'

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  AnnotationIcon,
  ChatAlt2Icon,
  ChatAltIcon,
  DocumentReportIcon,
  HeartIcon,
  InboxIcon,
  Bars3Icon,
  PencilAltIcon,
  QuestionMarkCircleIcon,
  ReplyIcon,
  SparklesIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
  PhotoIcon,
  VideoCameraIcon,
  FilmIcon,
  PuzzleIcon, 
  ScissorsIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { VscBook } from "react-icons/vsc";
import { MdOutlineQuiz } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { MdOutlinePlayLesson } from "react-icons/md";
import { TiVideoOutline } from "react-icons/ti";
import { IoGameControllerOutline } from 'react-icons/io5';
import { AiOutlinePicture } from "react-icons/ai";
import { PiNotepad } from "react-icons/pi";
import { TbLego } from "react-icons/tb";
import "@fontsource/luckiest-guy";

const resources = [
  {
    name: 'Learning Plan',
    description: 'Track your progress on each lesson',
    icon: PiNotepad,
    url: "/plan"
  },
  {
    name: 'Story Videos',
    description: 'Bible story retold in Lego animation',
    icon: MdOutlineOndemandVideo,
    url: "/videos"
  },
  {
    name: 'Lesson Videos',
    description: 'Story video explained',
    icon: MdOutlinePlayLesson,
    url: "/videos?tab=lesson"
  },
  {
    name: 'Quizzes',
    description: 'Quizzes are simply enjoyable',
    icon: MdOutlineQuiz,
    url: "/quizzes"
  },
  {
    name: 'Games',
    description: 'Games make learning exciting',
    icon: IoGameControllerOutline,
    url: "/games"
  },
  {
    name: 'Lego Builds',
    description: 'Build, play and learn with Lego building instructions',
    icon: TbLego,
    url: "/builds"
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function paddingForLg(width){ 
  if(width < 1300){ 
    return "lg:py-24"
  }
  if(width < 1400){ 
    return "lg:py-32"
  }
  if(width < 1800){ 
    return "lg:py-40"
  }
  return "lg:py-60"
}

export default function HomePage(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: calmJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  };
  return (
    <div className="bg-primary-color">
      <NavBar/>
      <main>
        <div className="lg:mx-auto lg:max-w-screen-2xl bg-primary-color">
          <div className="pt-6 md:pt-8"> 
            <img
              className="mx-auto center h-14 md:h-24 object-cover"
              src="/images/homepage/logo_wording.webp"
              alt="brickAmaze"
            />
            
          </div>

          <div className="relative my-10">
            <div className="lg:mx-auto lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
              <div className="relative sm:py-16 lg:py-0">
                <div aria-hidden="true" className="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                  <div className="absolute inset-y-0 right-1/2 w-full bg-secondary-yellow rounded-r-3xl lg:right-72" />
                    <svg
                      className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                      width={404}
                      height={392}
                      fill="none"
                      viewBox="0 0 404 392"
                    >
                    <defs>
                      <pattern
                        id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect x={0} y={0} width={4} height={4} className="text-primary-dark" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                  </svg>
                </div>
                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                  {/* Testimonial card*/}
                  <div className="relative pt-64 pb-10 rounded-2xl shadow-xl">
                    <img
                      className="absolute inset-0 h-full w-full object-cover"
                      src="/images/homepage/manger.webp"
                      alt=""
                    />
                    <div className="absolute inset-0" style={{ mixBlendMode: 'multiply' }} />
                    
                    <div className="relative px-8">
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative mx-auto my-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                {/* Content area */}
                <div className="pt-12 sm:pt-16 lg:pt-20">
                  <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-4xl">
                    On a mission to share God's love
                  </h2>
                  <div className="mt-6 text-primary-dark space-y-6">
                    <p className="text-lg ">
                      For God loved the world, He gave his one and only Son, 
                      so that everyone who believes in him will not perish but have eternal life.
                      ~ John 3:16
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-full w-full md:w-8/12 mx-auto py-6 lg:py-12">
          <div className="bg-blue-700 sm:rounded-2xl cursor-pointer"
            onClick={(e) => window.location.pathname = "/plan" }
          >
            <img
                className="pt-4 h-full w-full object-cover"
                src="/images/homepage/map.webp"
                alt="Plan Map"
              />
            <div className="relative">
              <h2 style={{fontFamily: 'Luckiest Guy'}} className="py-4 text-center text-1xl md:text-2xl lg:text-3xl font-extrabold lesson-text tracking-widest text-white">
                Systematic plan to track your progress
              </h2>
            </div>
          </div>
        </div>

        <div className="cursor-pointer" 
          onClick={(e) => window.location.pathname = "/videos" }
        >
          <div className="relative shadow-xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <Lottie options={defaultOptions} 
                isStopped={false}
                isPaused={false}
                width = { props.width }
                height = { props.width * 50/120 }
              />
              <div
                className="absolute inset-0 bg-gradient-to-r from-blue-200 to-blue-300"
                style={{ mixBlendMode: 'multiply' }}
              />
            </div>
            <div className={`relative px-4 py-6 md:py-12 ${paddingForLg(props.width)} lg:px-8`}>
              <h4 style={{fontFamily: 'Luckiest Guy'}} className="text-center text-white font-thin tracking-wide text-1xl md:text-4xl lg:text-6xl">
                Explore the Bible with Lego
              </h4>
              <h2 style={{fontFamily: 'Bitter'}} className="my-2 md:my-6 lg:my-8 text-center text-1xl md:text-3xl lg:text-5xl font-extrabold tracking-wide text-white">
                A new & interesting way
              </h2>
              <div className="md:mt-6 lg:my-10 text-center">
                <a
                  href="/videos"
                  className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black bg-primary-color hover:bg-primary-light"
                >
                <p>
                  Watch Videos
                </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-primary-light pb-8">
          <div className="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
            <div className="px-4 max-w-xl mx-auto sm:px-6 py-8 lg:py-24 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div className="mt-2">
                  <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-2xl md:text-3xl lg:text-4x font-extrabold tracking-wider text-secondary-dark">
                    Games
                  </h2>
                  <div className="mt-8 border-t border-secondary-dark pt-6">
                    <div class="grid grid-cols-3 divide-x divide-secondary-dark">
                      <div><p className="text-center mt-4 text-lg text-secondary-dark">Action</p></div>
                      <div><p className="text-center mt-4 text-lg text-secondary-dark">Adventure</p></div>
                      <div><p className="text-center mt-4 text-lg text-secondary-dark">Puzzle</p></div>
                    </div>
                  </div> 
                  <div className="mt-8 border-t border-secondary-dark pt-6">
                  <p className="mt-4 max-w-3xl text-lg text-secondary-dark">
                    Game is the best way to ensure learning stays fun!
                  </p>
                    <div className="py-6 my-auto">
                      <a
                        href="/games"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-light bg-secondary-dark hover:bg-primary-dark"
                      >
                        <p>Play Games</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-start-1 my-auto">
              <div className="pt-4 my-auto relative">
                <img
                  className="w-full shadow-xl ring-1 ring-black ring-opacity-5 cursor-pointer"
                  src="/images/homepage/game.webp"
                  onClick={(e) => window.location.pathname = "/games" }
                />
              </div>
            </div>
          </div>

          <div className="px-4 mt-6 md:mt-0 lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-3 lg:grid-flow-col-dense lg:gap-12">
            <div className="lg:col-start-1 px-4 max-w-xl mx-auto sm:px-6 py-8 lg:py-24 lg:max-w-none lg:mx-0 lg:px-0">
              <div >
                <div className="mt-4">
                  <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-2xl md:text-3xl lg:text-4x font-extrabold tracking-wider text-primary-dark">
                    Lego Builds
                  </h2>
                  <div className="border-t">
                  <p className="max-w-3xl text-lg text-primary-dark">
                    Lego build project helps in remembering the lesson.
                  </p>
                    <div className="py-6 my-auto">
                      <a
                        href="/builds"
                        className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-light bg-primary-dark hover:bg-primary-color"
                      >
                        <p>Build Lego</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-start-2 lg:col-span-2 my-auto">
              <div className="my-auto relative">
                <img
                  className="w-full shadow-xl ring-1 ring-black ring-opacity-5 cursor-pointer"
                  src="/images/homepage/builds.webp"
                  onClick={(e) => window.location.pathname = "/builds" }
                />
              </div>
            </div>
          </div>
        </div>

        

        <div className="relative mt-16 sm:py-16 ">
          <div aria-hidden="true" className="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 left-0 w-3/4 bg-secondary-yellow to-secondary-color rounded-r-3xl" />
            <svg className="absolute top-8 left-3/4 -ml-36" width={404} height={392} fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-primary-dark" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
            </svg>
          </div>
          <div className="mt-16 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-2xl lg:px-8">
            <div className="relative rounded-2xl px-6 py-10 bg-secondary-pink overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                <svg
                  className="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    className="text-white text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  />
                  <path
                    className="text-secondary-light text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  />
                </svg>
              </div>
              <div className="relative">
                <div className="text-center">
                  <h2  style={{fontFamily: 'Luckiest Guy'}} className="text-4xl text-white tracking-wider sm:text-4xl">
                    Ready to start?
                  </h2>
                  <div className="items-center mt-4 mt-30 sm:mt-20">
                  <button
                    onClick={(e) => window.location.pathname = "/users/sign_up" }
                    className="lesson-text tracking-wide rounded-md border border-transparent px-5 py-3 text-2xl font-bold text-primary-dark shadow bg-white hover:bg-primary-dark hover:text-primary-light focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 sm:px-10"
                  >
                    <p>Let's Go</p>
                  </button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="bg-primary-dark pt-10 mt-20">
          <div className="mx-auto px-4 py-4 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-12 lg:px-8">
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="pb-5 text-3xl text-white font-sans tracking-wide">Bible Fun Club Resources</h2>
            <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-6">
              {resources.map((feature) => (
                <div key={feature.name}>
                  <div className=" h-20">
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                      <feature.icon className="text-white h-6 w-6 blue-100" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="my-auto">
                    <a href={feature.url} className="text-lg font-medium text-white">
                      <p className="mt-0 text-lg font-medium text-white">
                        {feature.name}
                      </p>
                    </a>
                    <p className="mt-0 text-base text-blue-200">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  )
}