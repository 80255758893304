import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import queryString from 'query-string';

import NavBar from '../../elements/NavBar'
import Footer from '../../elements/Footer'

import { topicFetch, topicListFetch, topicInit } from '../../actions/topicActions';

import VideoRenderer from '../Videos/VideoRenderer'

const STORY = 1 
const LESSON = 2

class Topic extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      playingVideo: false, 
      videoId: "", 
      topicName: this.props.match.params.topic, 
      selectedTopicId: -1, 
      topicId: 0, 
      activity: 0, 
      duration: 0,
      videoQuery: queryString.parse(props.location.search).video     
    }
  }

  handleBackButton(){ 
    this.setState({playingVideo: false})
    window.history.replaceState(null, "", window.location.pathname)
  }

  handleClickOnVideo(videoId, topicId, duration, activity){ 
    this.setState({playingVideo: true, videoId: videoId})
    this.setState({topicId: topicId, duration: duration, activity: activity})
  }

  handleLeftNavigation(){ 
    this.updateTopic(this.props.topicIndex - 1)
  }

  handleRightNavigation(){ 
    this.updateTopic(this.props.topicIndex + 1)
  }

  updateTopic(newIndex){ 
    window.history.replaceState(null, "", `/plan/${this.props.topicList[newIndex].url}`) 
    this.props.topicInit(newIndex)
    this.setState({topicIndex: newIndex})
  }

  videos(){ 
    let array = []
    let topic = this.props.topic
    if(topic.story_video_id){ 
      array.push({
        thumbnail: `http://img.youtube.com/vi/${topic.story_video_id}/0.jpg`, 
        videoId: topic.story_video_id, 
        topicId: topic.id,
        activity: STORY,
        duration: topic.story_duration,
        text: "Story Video"})
    }
    if(topic.lesson_video_id){ 
      array.push({
        thumbnail: `http://img.youtube.com/vi/${topic.lesson_video_id}/0.jpg`, 
        videoId: topic.lesson_video_id, 
        topicId: topic.id,
        activity: LESSON, 
        duration:topic.lesson_duration,
        text: "Lesson Video"})
    }
    return array
  }

  nonVideos(){ 
    let array = []
    let topic = this.props.topic
    if(topic.quiz_thumbnail){ 
      array.push({thumbnail: topic.quiz_thumbnail, url: `/quizzes/${topic.url}`, text: "Quiz"})
    }
    if(topic.game_thumbnail){ 
      array.push({thumbnail: topic.game_thumbnail, url: `/games/${topic.game_url}`, text: "Game"})
    }
    if(topic.craft_thumbnail){ 
      array.push({thumbnail: topic.craft_thumbnail, url: `/builds/${topic.url}`, text: "Lego Build"})
    }
    return array
  }

  content(){ 
    let videos = this.videos()
    let nonVideos = this.nonVideos()
    if(Object.keys(this.props.topic).length && this.props.userLoaded){
      return(
        <div className="lg:mx-auto lg:max-w-7xl bg-primary-color flex flex-col">
          <div className="min-h-0 flex-1 flex overflow-auto">
            <div className="w-full mx-auto px-2 py-2 sm:px-6 lg:px-8 sm:py-5">
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {videos.map((item) => (
                  <li key={item.text} className="relative">
                    <div className="bg-white aspect-square flex h-full items-stretch flex-col rounded-lg cursor-pointer hover:opacity-75"
                      onClick={this.handleClickOnVideo.bind(this, item.videoId, item.topicId, item.duration, item.activity)}
                    >
                      <div> 
                        <img src={item.thumbnail} alt="" className="rounded-t-lg object-contain pointer-events-none" />
                      </div> 
                      <div className ="relative m-auto w-full"> 
                          <div className="flex text-center">
                            <div className="m-auto">
                          <h2 style={{fontFamily: 'Luckiest Guy'}} className={`py-2 lg:px-4 mt-1 leading-4 text-primary-dark font-extrabold font-sans tracking-wide md:text-xl md:text-2xl lg:text-3xl`}> 
                                { item.text }
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>     
                  </li>
                ))}
              </ul>
              <br/>
              <br/>
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {nonVideos.map((item) => (
                  <li key={item.text} className="relative">
                    <div className="bg-white aspect-square flex h-full items-stretch flex-col rounded-lg cursor-pointer hover:opacity-75"
                      onClick={(e) => window.location.href = item.url }
                    >
                      <div> 
                        <img src={item.thumbnail} alt="" className="rounded-t-lg object-contain pointer-events-none" />
                      </div> 
                      <div className ="relative m-auto w-full"> 

                          <div className="flex text-center">
                            <div className="m-auto">
                          <h2 style={{fontFamily: 'Luckiest Guy'}} className={`py-2 lg:px-4 mt-1 leading-4 text-primary-dark font-extrabold font-sans tracking-wide md:text-xl md:text-2xl lg:text-3xl`}> 
                                { item.text }
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>     
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div> 
      )
    } 
    return <div/>
  }

  leftNavItem(){ 
    if(this.props.topicIndex >= 1){ 
      return(
        <BiSolidLeftArrow 
          className="cursor-pointer text-4xl md:text-5xl text-primary-dark"
          onClick={this.handleLeftNavigation.bind(this)}
        />
      )
    }
  }

  rightNavItem(){ 
    if(this.props.topicList && this.props.topicIndex != this.props.topicList.length - 1 ){ 
      return(
        <BiSolidRightArrow 
          className="cursor-pointer text-4xl md:text-5xl text-primary-dark "
          onClick={this.handleRightNavigation.bind(this)}
        />
      )
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.topic != prevProps.topic){ 
      this.props.topicListFetch();
      if(this.state.videoQuery){ 
        let topic = this.props.topic
        if(this.state.videoQuery == "story"){ 
          this.setState({playingVideo: true, videoId: topic.story_video_id})
          this.setState({topicId: topic.id, duration: topic.story_duration, activity: STORY})
        }
        else if(this.state.videoQuery == "lesson"){ 
          this.setState({playingVideo: true, videoId: topic.lesson_video_id})
          this.setState({topicId: topic.id, duration: topic.lesson_duration, activity: LESSON})
        }
      }
    }
    if(this.props.topicList != prevProps.topicList && this.props.topicList.length != prevProps.topicList.length ){
      let index = this.props.topicList.findIndex((topic) => topic.url === this.props.match.params.topic);
      this.props.topicInit(index)
      this.setState({selectedTopicId: this.props.topicList[index].id})
    }
  }

  componentDidMount() { 
    this.props.topicFetch(this.props.match.params.topic);
  }

  render() {
    if(this.state.playingVideo){ 
      return(
        <VideoRenderer  
          videoUrl = {`${this.state.videoId}`}
          topicId = {`${this.state.topicId}`}
          duration = {`${this.state.duration}`}
          activity = { `${this.state.activity}` }
          activeUser = { Object.keys(this.props.user).length > 0 }
          backButtonHandler = { this.handleBackButton.bind(this)}
        />
      )
    }
    return (
      <div> 
        <div className="min-h-screen bg-primary-color"> 
          <NavBar/>
          <div className="lg:mx-auto lg:max-w-7xl h-screen"> 
            <div className="flex flex-row mt-2
            ">
              <div className ="ml-4 md:ml-20 my-auto">
                {this.leftNavItem()}
              </div>
              <div className="mt-4 text-center mx-auto py-2 sm:py-5">
                <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-5xl">
                  {this.props.topic.name}
                </h2>
              </div>
              <div className ="mr-4 md:mr-20 my-auto">
                {this.rightNavItem()}
              </div>
            </div>
            <div className="overflow-hidden flex flex-col">
              <div className="min-h-0 flex-1 flex overflow-auto">
                <div className="w-full bg-primary-color mx-auto sm:px-6 lg:px-8 py-5">
                  {this.content()}    
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return { 
    topic: state.topic.topic, 
    topicList: state.topic.topicList, 
    topicIndex: state.topic.topicIndex,
    user: state.user.user,
    userLoaded: state.user.userLoaded
  }
};

export default connect(mapStateToProps, {
  topicFetch, 
  topicListFetch, 
  topicInit
})(Topic);