import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { RiReplyFill } from "react-icons/ri";
import YouTube from 'react-youtube';

import { progressOwnerCreate } from '../../actions/progressOwnerActions';

class VideoRenderer extends React.Component {
  constructor(props) {
    super(props);
    var frameWidth = this.frameWidth()
    this.state = {
      frameWidth: frameWidth, 
      frameHeight: this.frameHeight(frameWidth), 
      timeStarted: -1, 
      timePlayed: 0, 
      ownerCreated: false
    }
  }

  handleChangeState(){ 

  }

  handlePlay(){ 
    this.setState({timeStarted: new Date().getTime()/1000})
  }

  handlePause(){ 
    this.timeElapsed()
  }

  handleEnd(){ 
    this.timeElapsed()
  }

  timeElapsed(){ 
    if(this.state.timeStarted > 0) {
      var playedFor = new Date().getTime()/1000 - this.state.timeStarted;
      this.setState({timeStarted: -1})
      // add the new ammount of seconds played
      this.setState({timePlayed: playedFor + this.state.timePlayed})
    }
    debugger
    if(this.state.timePlayed > 0.05 * this.props.duration && this.state.ownerCreated == false && this.props.topicId){ 
      this.props.progressOwnerCreate(this.props.topicId, this.props.activity)
      this.setState({ownerCreated: true})
    }
  }

  frameWidth(){ 
    let width = window.innerWidth
    if(window.innerWidth/window.innerHeight > 1600/900){ 
      width = window.innerHeight * 1600/900
    }
    return width
  }

  frameHeight(width){ 
    return width * 900/1600
  }

  buttonSize(){ 
    if(isMobile){ 
      // ipad
      if(this.state.frameWidth > 700){
        return 70
      }
      return 45
    }
    if(window.innerWidth > 1200){ 
      return 80
    }
    if(window.innerWidth > 800){ 
      return 65
    }
    return 50
  }

  onWindowSizeChange(){ 
    var frameWidth = this.frameWidth()
    this.setState({frameWidth: frameWidth, frameHeight: this.frameHeight(frameWidth)})
  }

  youtubeItem(){ 
    const opts = {
      height: `${this.frameWidth() * 900/1600}px`,
      width: `${this.frameWidth()}px`,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: true, 
        modestbranding: 1
       },
    };
    if(this.props.activeUser){
      return( 
        <YouTube 
          videoId={this.props.videoUrl} 
          opts={opts} 
          onPlay={this.handlePlay.bind(this)} 
          onPause={this.handlePause.bind(this)} 
          onEnd={this.handleEnd.bind(this)} 
        />
      )
    }else{ 
      return(
        <YouTube 
          videoId={this.props.videoUrl} 
          opts={opts} 
        />
      )
    }
  }

  backButton(){ 
    if(this.props.backButtonHandler){ 
      return(
        <button className="z-50 focus:outline-none fancy-round-button orange"
          style={{position: 'absolute', 
            left: '10px', 
            top: '10px',
            width: `${this.buttonSize()}px`,
            height: `${this.buttonSize()}px`,
          }}
          onClick={this.props.backButtonHandler}
        > 
          <RiReplyFill className="text-3xl md:text-5xl mx-auto text-white"/>
        </button>
      )
    }
  }

  componentDidMount(){
    var frameWidth = this.frameWidth()
    this.setState({frameWidth: frameWidth, frameHeight: this.frameHeight(frameWidth)})
    window.addEventListener('resize', this.onWindowSizeChange.bind(this));
	}	

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowSizeChange.bind(this));
  }

  render() {
    return (
      <div> 
        <div className="flex justify-center min-h-screen bg-primary-color"> 
          <div 
            style={{
              width: `#{this.frameWidth()}px`,
              height: `#{this.frameWidth() * 900/1600}px`,
            }}
          >
            {this.youtubeItem()}
            {this.backButton()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  progressOwnerCreate
}) (VideoRenderer);