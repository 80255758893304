// Cards 
export const NOAH_QUEST_UPDATE ='noah_quest_update';
export const NOAH_QUEST_INIT ='noah_quest_init';

export const PAINT_THE_WORLD_UPDATE ='paint_the_world_update';
export const PAINT_THE_WORLD_FETCH ='paint_the_world_fetch';

export const JONAH_GAME_START = 'jonah_game_start';
export const JONAH_GAME_OVER = 'jonah_game_over';
export const JONAH_GAME_RUNNING = 'jonah_game_running';

export const JONAH_WHALE_UP = 'jonah_whale_up';
export const JONAH_WHALE_DOWN = 'jonah_whale_down';
export const JONAH_WHALE_INIT = 'jonah_whale_init';

export const JONAH_BACKGROUND_INIT = 'jonah_background_init';
export const JONAH_BACKGROUND_SCROLL = 'jonah_background_scroll';

export const JONAH_PIPE_GENERATE = 'jonah_pipe_generate';

export const JONAH_OBSTACLE_INIT = 'jonah_obstacle_init';
export const JONAH_OBSTACLE_GENERATE = 'jonah_obstacle_generate';
export const JONAH_OBSTACLE_REMOVE = 'jonah_obstacle_remove';
export const JONAH_OBSTACLE_UPDATE = 'jonah_obstacle_update';

export const FIND_OUT_UPDATE = 'find_out_update';
export const FIND_OUT_INIT = 'find_out_init';

export const SPOT_DIFF_UPDATE = 'spot_diff_update';
export const SPOT_DIFF_INIT = 'spot_diff_init';

export const JIGSAW_PUZZLE_UPDATE = 'jigsaw_puzzle_update';
export const JIGSAW_PUZZLES_ACTIVE = 'jigsaw_puzzles_active';
export const JIGSAW_PUZZLES_INIT = 'jigsaw_puzzles_init';
export const JIGSAW_CLUSTER_UPDATE = 'jigsaw_cluster_update';
export const JIGSAW_CLUSTER_ADD = 'jigsaw_cluster_add';
export const JIGSAW_CLUSTER_DELETE = 'jigsaw_cluster_delete';

export const MAZE_HEAD_UPDATE = 'maze_head_update';
export const MAZE_PIC_UPDATE = 'maze_pic_update';
export const MAZE_SHEEP_UPDATE = 'maze_sheep_update';

export const ARTICLE_FETCH_SUCCESS = "article_fetch_success";
export const ARTICLES_FETCH_SUCCESS = "articles_fetch_success";
export const ARTICLE_SAVE_SUCCESS = "article_save_success";

export const USER_FETCH_SUCCESS = "user_fetch_success";
export const USER_SIGN_OUT_SUCCESS = "user_sign_out_success";
export const USER_SAVE_SUCCESS = "user_save_success";
export const USER_PROFILE_SAVE_SUCCESS = "user_profile_save_success";
export const USER_UPDATE = "user_update";
 
export const AVATAR_SKINS_FETCH_SUCCESS = "avatar_skins_fetch_success";
export const AVATAR_FACES_FETCH_SUCCESS = "avatar_faces_fetch_success";
export const AVATAR_HEADS_FETCH_SUCCESS = "avatar_heads_fetch_success";
export const AVATAR_SHIRTS_FETCH_SUCCESS = "avatar_shirts_fetch_success";

export const USER_AVATAR_ASSETS_FETCH_SUCCESS = "user_avatar_assets_fetch_success"
export const USER_AVATAR_OWNER_SAVE_SUCCESS = "user_avatar_owner_save_success"

export const USER_TROPHIES_FETCH_SUCCESS = "user_trophies_fetch_success"
export const USER_TROPHY_FETCH_SUCCESS = "user_trophy_fetch_success"
export const USER_TROPHY_SAVE_SUCCESS = "user_trophy_save_success"

export const PROGRESS_OWNERS_FETCH_SUCCESS = "progress_owners_fetch_success"
export const PROGRESS_OWNER_SAVE_SUCCESS = "progress_owner_save_success"

export const VIDEO_LIST_FETCH_SUCCESS = "video_list_fetch_success"

export const QUIZ_FETCH_SUCCESS = "quiz_fetch_success"
export const QUIZ_LIST_FETCH_SUCCESS = "quiz_list_fetch_success"

export const GAME_LIST_FETCH_SUCCESS = "game_list_fetch_success"

export const LESSON_LIST_FETCH_SUCCESS = "lesson_list_fetch_success"
export const LESSON_FETCH_SUCCESS = "lesson_fetch_success"

export const CRAFT_LIST_FETCH_SUCCESS = "craft_list_fetch_success"
export const CRAFT_FETCH_SUCCESS = "craft_fetch_success"

export const TOPIC_FETCH_SUCCESS = "topic_fetch_success"
export const TOPIC_LIST_FETCH_SUCCESS = "topic_list_fetch_success"
export const TOPIC_INIT = "topic_init"
