/* This example requires Tailwind CSS v2.0+ */
import React, { Component } from 'react';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { MdOutlineClose } from "react-icons/md"; 

const buttons = (props) => { 
  return(
    <div className="flex items-center justify-center mt-2">
      <button className="w-1/2 fancy-button orange"
        onClick={(e) => window.location = `/${props.targetLink}` } 
      > 
        Play
      </button>
    </div>
  )
}

export default function TrophyModal(props) {
  const completeButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" 
        className="relative z-40" 
        initialFocus={completeButtonRef}
        open={props.open}
        onClose={props.handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 md:p-0 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-primary-dark px-4 p-2 pb-4 text-left shadow-xl transition-all w-full sm:my-8 w-1/2 max-w-md">
                <div>
                  <div className="text-right">
                    <button className="no-outline-button"
                      onClick={props.handleClose}
                    >
                      <p className="text-3xl sm:text-5xl text-white">
                        <MdOutlineClose/>
                      </p>
                    </button>
                  </div>
                  <div className="mx-auto flex">
                    <div className="mx-auto object-center block rounded-lg">
                      <div>
                        <img src={props.image} alt="" className="pt-2 w-4/5 m-auto object-contain" />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3">
                      {
                        props.description.map((description) => { 
                          return( 
                            <p className="lesson-text text-lg md:text-xl lg:text-2xl font-medium leading-6 text-white p-2">
                            {description}
                            </p>
                          )
                        })
                      }
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-white">
                      </p>
                    </div>
                  </div>
                </div>
                {buttons(props)}
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}