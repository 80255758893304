import React, { Component } from 'react';

import { TbLock } from "react-icons/tb"

const lockedItem = (props) => { 
  if(props.locked){ 
    return <TbLock className="h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 text-primary-dark absolute inset-0 m-auto object-contain pointer-events-none opacity-50" />
  }
}

export default function AvatarFaceElement(props){ 
  return(
    <div className="mx-auto object-center block rounded-lg">
      <div className={`h-18 md:h-28 w-18 md:w-28 lg:h-32 lg:w-32 rounded-lg`}>
        <button className={`relative h-full w-full rounded-lg focus:outline-none ${props.buttonClass}`} onClick={props.handleClick} type="button">
          <img src={props.head} alt="" className="w-4/5 absolute inset-0 m-auto object-contain pointer-events-none group-hover:opacity-75" />
          <img src={props.face} alt="" className="w-4/5 absolute inset-0 m-auto object-contain pointer-events-none group-hover:opacity-75" />
          {lockedItem(props)}
        </button>
      </div>
    </div>
  )
}
