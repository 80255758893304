import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";

import GameRuleModal from "./Modals/GameRuleModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"
import Puzzle from "./JigsawPuzzles/Puzzle"
import Shadow from "./JigsawPuzzles/Shadow"
import { puzzleInfo, initAdjacents } from './JigsawPuzzles/JigsawData'

import { ArrowRightOnRectangleIcon, ArrowPathIcon, SpeakerWaveIcon, SpeakerXMarkIcon, PauseCircleIcon, PhotoIcon, LightBulbIcon, ClockIcon } from '@heroicons/react/24/outline'
import PauseGif from '../../assets/animations/pause.gif'
import FrameImage from '../../assets/games/top_frame.jpg'
import RuleThumb from '../../assets/games/jigsaw_puzzles/mount_sinai/background.jpg'

import { jigsawPuzzlesInit, jigsawPuzzleUpdate, jigsawPuzzlesActive } from '../../actions/jigsawPuzzlesActions';
import { buttonSize } from '../../helpers/GameHelper'
import { shuffleArray, randomIntFromInterval, between } from '../../helpers/MathHelper'

import '../../stylesheets/fancyButton.css';
import '../../stylesheets/gameSetting.css';

let puzzleAdjacents = []
let puzzleClusterIds = []
let clusterPuzzles = []
let timeLoop
const frameWidth = 1609
const frameRatio = 1609/152
const buttonRatio = 1609/120 

const puzzlesRatio = 5/4
const actualPuzzlesWidth = 3000 
const actualPuzzlesHeight = 2400
const puzzlePieces = 20
const landscapeWidth = 750 
const puzzleMaxDm = 1000
const studLength = 200
const studHeight = 160
const studAveHeight = 80
const puzzleOffset = 80
const puzzleWidth = 600 
const timeBonusScore = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]

class JigsawPuzzles extends React.Component {
  constructor(props) {
    super(props);
    var frameWidth = this.frameWidth(window.innerWidth)
    var sampleWidth = this.sampleWidth(window.innerWidth, window.innerHeight)
    var sizeRate = sampleWidth/actualPuzzlesWidth
    this.state = { 
      gameRule: true, 
      wellDoneModal: false, 
      audioOn: true, 
      bgMusic: new Audio('/audio/background/sneaky_snitch.mp3'), 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      pause: false, 
      gameState: "pending", 
      pauseAnimation: PauseGif, 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight, 
      frameWidth: frameWidth, 
      frameHeight: frameWidth /frameRatio, 
      sampleWidth: sampleWidth,
      sampleHeight: sampleWidth/ puzzlesRatio, 
      canvasWidth: window.innerWidth, 
      canvasHeight: 0.85 * window.innerHeight, 
      sizeRate: sizeRate, 
      time: 0, 
      clusterId: 1, 
      offsetX: 0, 
      offsetY: 0,
      activeClusterId: 0, 
      activeCluster: {},
      photoOn: false,  
      hintOn: false,  
      frameRef: createRef(),
      canvasRef: createRef(), 
      completeImg: false, 
      processMatching: false, 
      score: 0
    }
  }

  handleWindowSizeChange(){
    var frameWidth = this.frameWidth(window.innerWidth)
    var sampleWidth = this.sampleWidth(window.innerWidth, window.innerHeight)
    var sizeRate = sampleWidth/actualPuzzlesWidth
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.setState({ sampleWidth: sampleWidth, sampleHeight: sampleWidth/ puzzlesRatio });
    this.setState({ frameWidth: frameWidth, frameHeight: frameWidth/frameRatio });
    this.setState({sizeRate: sizeRate })

  };

  handleContextMenu(e){ 
    e.preventDefault()
  }

  handleMouseDown(puzzle, e){
    e = e || window.event;
    if(e.cancelable){
      e.preventDefault();
    }
    this.props.jigsawPuzzleUpdate(puzzle.index, puzzle)
    this.setActiveCluster(puzzle, e.clientX, e.clientY)
  }

  handleTouchStart(puzzle, e){
    e = e || window.event;
    if(e.cancelable){
      e.preventDefault();
    }
    e.stopPropagation()
    this.props.jigsawPuzzleUpdate(puzzle.index, puzzle)
    this.setActiveCluster(puzzle, e.touches[0].clientX, e.touches[0].clientY)
  }

  handleMouseMove(e){ 
    e = e || window.event;
    if(e.cancelable){
      e.preventDefault();
    }

    this.movePuzzles(e.clientX, e.clientY)
  } 

  handleTouchMove(e){ 
    if(e.cancelable){
      e.preventDefault();
    } 
    e.stopPropagation()
    this.movePuzzles(e.touches[0].clientX, e.touches[0].clientY)
  }

  handleMouseUp(e){ 
    e = e || window.event;
    if(e.cancelable){
      e.preventDefault();
    }
    e.stopPropagation()

    if(this.state.activeClusterId){ 
      this.processMatchingPuzzles(this.state.activeCluster)
    }

    this.setState({activeClusterId: 0})
  }

  handleExit(){ 
    this.props.history.goBack()
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
  }

  handleStartPlay(e){
    e.stopPropagation()
    this.registerListeners()
    this.initPuzzles()
    this.initState()
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    }
    this.setState({gameRule: false})
    this.setState({completeImg: false})
  }

  handleAudioToggle(e){ 
    e.stopPropagation()
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }
  
  handlePhotoToggle(){ 
    var photoOn = !this.state.photoOn
    this.setState({photoOn: photoOn })
  }
 
  handleHintToggle(){ 
    var hintOn = !this.state.hintOn
    this.setState({hintOn: hintOn })
  } 

  handlePause(){ 
    if(this.state.gameState == "running"){ 
      this.setState({pause: true})
      this.state.bgMusic.pause()
    }
  } 

  handleUnpause(){ 
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    if(this.state.pause == true){ 
      this.setState({pauseAnimation: ""})
      this.setState({pause: false})
      setTimeout(() => { 
        this.setState({pauseAnimation: PauseGif})
      }, 1)
    }
  } 

  handleRestart(){ 
    this.setState({gameState: "pending"})
    this.initPuzzles()
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    }
    var self = this
    setTimeout(function(){ 
      self.openedCard = []
      self.setState({pause: false})
      self.setState({score: 0})
      self.initPuzzles()
      self.initState()
    }, 100)
    
  }

  registerListeners(){ 
    if(isMobile){ 
      window.addEventListener('contextmenu', this.handleContextMenu.bind(this)) 
      window.addEventListener('touchend', this.handleMouseUp.bind(this) )
      window.addEventListener('touchcancel', this.handleMouseUp.bind(this) )
      window.addEventListener('touchmove', this.handleTouchMove.bind(this) )  
    }else{ 
      window.addEventListener('mouseup', this.handleMouseUp.bind(this) )
      window.addEventListener('mousemove', this.handleMouseMove.bind(this) )
    }  
  }

  reprocessMatching(){ 
    console.log("reprocessing matching")
    let activeCluster
    // Update activeCluster
    for(var i = 0; i < clusterPuzzles.length; i++ ){ 
      if(clusterPuzzles[i].puzzles.includes(this.state.activePuzzleIndex)){ 
        activeCluster = clusterPuzzles[i]
        console.log("processMatchingPuzzles")
        this.processMatchingPuzzles(activeCluster)
        break;
      }
    } 
  }

  processMatchingPuzzles(activeCluster){ 
    const puzzleIndexes = this.matchPuzzles(activeCluster)

    // Matches
    console.log("match")
    if(puzzleIndexes.length){ 
      let timeBonus = 0
      let time = this.state.time
      if(time < 100){ 
        timeBonus = timeBonusScore[parseInt(time/10)]
      }
      this.setState({score: this.state.score + 10 + timeBonus})
      //console.log(`puzzleIndexes: ${puzzleIndexes}`)
      const activeIndex = puzzleIndexes[0]
      const adjacentIndex = puzzleIndexes[1]
      const movement = this.calculatePuzzleMovement(activeIndex, adjacentIndex)
      this.movePuzzlesInCluster(movement[0], movement[1], activeCluster)
      const adjacentClusterId = puzzleClusterIds[adjacentIndex]
      this.updateClusterData(adjacentClusterId, activeCluster)
      console.log(`clusterTotal: ${clusterPuzzles.length}`)
      this.reprocessMatching()
    }

    if(clusterPuzzles.length == 1){ 
      var thisObject = this
      clearInterval(timeLoop)
      thisObject.setState({gameState: "done"}) 
      thisObject.state.cheerAudio.play()
      setTimeout(function(){ 
        thisObject.setState({completeImg: true}) 
      }, 600)
      setTimeout(function(){ 
        thisObject.setState({wellDoneModal: true})
        thisObject.state.bgMusic.pause()
      }, 1800)  
    }
  }

  setActiveCluster(puzzle, x, y){ 

    var activeClusterId = this.state.activeClusterId
    var activeCluster
    
    this.setState({offsetX: x}) 
    this.setState({offsetY: y}) 
    this.setState({activePuzzleIndex: puzzle.index})
    console.log(`setActiveCluster puzzleIndex: ${puzzle.index}`)
    // Search for the cluster that the puzzle belong to
    for(var i = 0; i < clusterPuzzles.length; i++ ){ 
      if(clusterPuzzles[i].puzzles.includes(puzzle.index)){ 
        activeClusterId = clusterPuzzles[i].id
        activeCluster = clusterPuzzles[i]
        //console.log(`activePuzzles: ${clusterPuzzles[i].puzzles[0]}`)
        //this.props.jigsawPuzzlesActive(clusterPuzzles[i].puzzles)
        break;
      }
    } 
    this.setState({activeClusterId: activeClusterId, activeCluster: activeCluster})
  }

  movePuzzles(x, y){ 
    if(this.state.activeClusterId == 0 ){ return }

    const activeCluster = this.state.activeCluster
    // beyond boundary
    if(y < (this.state.canvasRef.current.offsetTop + 20) || y > (this.state.canvasRef.current.offsetTop + this.state.canvasHeight - 20) || 
      x < 20 || x > (this.state.canvasWidth - 20)){ 
      return
    }
    // Move each the puzzles in cluster 
    for(var i = 0; i < activeCluster.puzzles.length; i++ ){ 
      const puzzle = this.props.puzzles.find(x => x.index == activeCluster.puzzles[i])
      puzzle.left = puzzle.left + x - this.state.offsetX
      puzzle.top = puzzle.top + y - this.state.offsetY
      this.props.jigsawPuzzleUpdate(puzzle.index, puzzle)
    }
    this.setState({offsetX: x}) 
    this.setState({offsetY: y}) 
  }

  matchPuzzles(activeCluster){ 
    
    // Go through each of puzzles in the cluster 
    for(var i = 0; i < activeCluster.puzzles.length; i++ ){ 
      const activeIndex = activeCluster.puzzles[i]
      const adjacents = puzzleAdjacents[activeIndex]
      const activePuzzle = this.props.puzzles.find(x => x.index == activeIndex) 
      const activeInfo = puzzleInfo[activeIndex]

      // Go through each of the adjacent 
      for(var j = 0; j < adjacents.length; j++ ){ 

        var match = false
        // skip if the adjacent already part of the active cluster
        if(activeCluster.puzzles.includes(adjacents[j])){ 
          continue; 
        }
        const adjacentIndex = adjacents[j]
        const adjacentInfo = puzzleInfo[adjacentIndex]
        const adjacentPuzzle = this.props.puzzles.find(x => x.index == adjacentIndex) 
        const min = puzzleWidth - puzzleOffset
        const max = puzzleWidth + puzzleOffset
        const activeLeft = activePuzzle.left/this.state.sizeRate + activeInfo.data.center[0]
        const activeTop = activePuzzle.top/this.state.sizeRate + activeInfo.data.center[1]
        const adjacentLeft = adjacentPuzzle.left/this.state.sizeRate + adjacentInfo.data.center[0]
        const adjacentTop = adjacentPuzzle.top/this.state.sizeRate + adjacentInfo.data.center[1]

        if(activeInfo.matrix[0] == adjacentInfo.matrix[0]){ // on same row, adjacent LEFT/RIGHT
          if(activeInfo.matrix[1] < adjacentInfo.matrix[1]){ // active on the left 
            if( between(adjacentLeft - activeLeft, min, max) && between(adjacentTop - activeTop, -puzzleOffset, puzzleOffset ) ){
              match = true
            }
          }else{ // active on the right 
            if( between(activeLeft - adjacentLeft, min, max) && between(activeTop - adjacentTop, -puzzleOffset, puzzleOffset )){
              match = true
            }
          }
        }
        else{ // on same column, adjacent TOP/DOWN 
          if(activeInfo.matrix[0] < adjacentInfo.matrix[0]){ // active on the top 
            
            if( between(adjacentTop - activeTop, min, max) && between(adjacentLeft - activeLeft, -puzzleOffset, puzzleOffset ) ){
              match = true
            }
          }else{ // active on the bottom 
            if( between(activeTop - adjacentTop, min, max) && between(activeLeft - adjacentLeft, -puzzleOffset, puzzleOffset )){
              match = true
            }
          }
        }
        
        // If all points match
        if(match){ 
          // remove from puzzleAdjacent of activePuzzle 
          var index = puzzleAdjacents[activeIndex].indexOf(adjacentIndex)
          puzzleAdjacents[activeIndex].splice(index, 1)
          // remove from puzzleAdjacent of adjacentPuzzle
          index = puzzleAdjacents[adjacentIndex].indexOf(activeIndex)
          puzzleAdjacents[adjacentIndex].splice(index, 1)
          return [activeIndex, adjacentIndex]
          break; 
        }
      }
    } 
    return []
  }

  calculatePuzzleMovement(activeIndex, adjacentIndex){ 
    // Calculate target for the active puzzle to move
    const activeClusterId =  puzzleClusterIds[activeIndex]
    const adjacentClusterId = puzzleClusterIds[adjacentIndex]
    const activeInfo = puzzleInfo[activeIndex]
    const adjacentInfo = puzzleInfo[adjacentIndex]
    var targetTop, targetLeft
    const activePuzzle = this.props.puzzles.find(x => x.index == activeIndex) 
    const adjacentPuzzle = this.props.puzzles.find(x => x.index == adjacentIndex) 
    var adjacentCenterX = adjacentPuzzle.left/this.state.sizeRate + adjacentInfo.data.center[0]
    var adjacentCenterY = adjacentPuzzle.top/this.state.sizeRate + adjacentInfo.data.center[1]
    if(activeInfo.matrix[0] == adjacentInfo.matrix[0]){ // on same row, adjacent LEFT/RIGHT
      if(activeInfo.matrix[1] < adjacentInfo.matrix[1]){ // active on the left 
        targetLeft = adjacentCenterX - puzzleWidth - activeInfo.data.center[0]
        targetTop = adjacentCenterY - activeInfo.data.center[1]
      } 
      else{ // active on the right
        targetLeft = adjacentCenterX + puzzleWidth - activeInfo.data.center[0]
        targetTop = adjacentCenterY - activeInfo.data.center[1]
      }
    }else{ // on same column, adjacent TOP/DOWN 
      if(activeInfo.matrix[0] < adjacentInfo.matrix[0]){ // active on the top 
        targetLeft = adjacentCenterX - activeInfo.data.center[0]
        targetTop = adjacentCenterY - puzzleWidth - activeInfo.data.center[1]
      }else{ // active on the bottom 
        targetLeft = adjacentCenterX - activeInfo.data.center[0]
        targetTop = adjacentCenterY + puzzleWidth - activeInfo.data.center[1]
      } 
    }

    return [targetLeft * this.state.sizeRate - activePuzzle.left, targetTop * this.state.sizeRate - activePuzzle.top]
  }

  movePuzzlesInCluster(left, top, activeCluster){ 
    for(var i = 0; i < activeCluster.puzzles.length; i++ ){ 
      const puzzle = this.props.puzzles.find(x=>x.index == activeCluster.puzzles[i])
      puzzle.left = puzzle.left + left
      puzzle.top = puzzle.top + top
      this.props.jigsawPuzzleUpdate(puzzle.index, puzzle)
    }    
  }

  updateClusterData(adjacentClusterId, activeCluster){ 
    // Update puzzleClusterIds 
    for(var i = 0; i < activeCluster.puzzles.length; i++ ){
      const puzzleIndex =  activeCluster.puzzles[i]
      puzzleClusterIds[puzzleIndex] = adjacentClusterId
    }   
    // update clusterPuzzles  
    var index = clusterPuzzles.findIndex(x => x.id == adjacentClusterId)
    console.log(`activeClusterPuzzles: ${activeCluster.puzzles}`)
    clusterPuzzles[index].puzzles = clusterPuzzles[index].puzzles.concat(activeCluster.puzzles)
    console.log(clusterPuzzles[index].puzzles)
    // remove activeCluster 
    index = clusterPuzzles.findIndex(x => x.id == activeCluster.id)
    clusterPuzzles.splice(index, 1)
    console.dir(clusterPuzzles)
  }

  randomLeft(firstHalf){ 
    const puzzleSize = puzzleMaxDm* this.state.sizeRate
    if(this.state.windowWidth > landscapeWidth){  // Landscape Mode
      if(firstHalf){
        const max = (this.state.canvasWidth - this.state.sampleWidth)/2 - puzzleSize
        //console.log(`max: ${max}`)
        const value = randomIntFromInterval(0, max)
        //console.log(`First Half randomLeft: ${value}`)
        return value
      }
      const min = (this.state.canvasWidth - this.state.sampleWidth)/2 + this.state.sampleWidth
      //console.log(`min: ${min}`)
      const value = randomIntFromInterval(min, this.state.canvasWidth - puzzleSize)
      //console.log(`Second Half randomLeft: ${value}`)
      return value
    }else{ // Portrait Mode
      return randomIntFromInterval(0, this.state.canvasWidth - puzzleSize)
    }
  }

  randomTop(firstHalf){ 
    const puzzleSize = puzzleMaxDm * this.state.sizeRate
    if(this.state.windowWidth > landscapeWidth){ // Landscape Mode
      return randomIntFromInterval(0, this.state.canvasHeight - puzzleSize)
    }else{ // Portrait Mode
      if(firstHalf){
        const max = (this.state.canvasHeight - this.state.sampleHeight)/2 - puzzleSize
        console.log(`firstHalf max: ${max}`)
        const value = randomIntFromInterval(0, max)
        return value
      }
      const min = (this.state.canvasHeight - this.state.sampleHeight)/2 + this.state.sampleHeight
      console.log(`secondHalf min: ${min}`)
      console.log(`max: ${this.state.canvasHeight - puzzleSize}`)
      const value = randomIntFromInterval(min, this.state.canvasHeight - puzzleSize)
      return value
    }
  }

  initState(){ 
    this.setState({gameState: "running"})
    this.setState({score: 0, bonusScore: 10, time: 0})
    let thisObj = this
    timeLoop = setInterval(() => { 
      if(!thisObj.state.pause){ 
        if(thisObj.state.gameState == "running" && thisObj.state.time < 600000){
          thisObj.setState({time: thisObj.state.time + 1})
        }
      }
    }, 1000)
  }

  initPuzzles(){ 
    var initArray = Array.from(Array(puzzlePieces).keys())
    initArray = shuffleArray(initArray) 
    var puzzle = {} 
    var puzzles = []
    var left = 0 
    var top = 0
    var cluster = {}
    var clusterId = 1

    this.setState({clusterId: 1})
    puzzleAdjacents = []
    puzzleClusterIds = []
    clusterPuzzles = []

    for(var i = 0; i < initArray.length; i++ ){ 
      // Split puzzles into half 
      if(i >= initArray.length/2){ 
        left = this.randomLeft(true)
        top = this.randomTop(true)
      }else{ 
        left = this.randomLeft(false)
        top = this.randomTop(false)
      }
      const puzzleIndex = initArray[i]
      puzzle['index'] = puzzleIndex
      puzzle['width'] = puzzleInfo[puzzleIndex].data.width
      puzzle['height'] = puzzleInfo[puzzleIndex].data.height
      puzzle['left'] = left
      puzzle['top'] = top
      puzzles[puzzleIndex] = Object.assign({}, puzzle)
      puzzleClusterIds[puzzleIndex] = clusterId
      console.log(`adjacent ${puzzleIndex}: ${initAdjacents[puzzleIndex]}`)
      puzzleAdjacents[puzzleIndex] = [...initAdjacents[puzzleIndex]]
      cluster['id'] = clusterId
      clusterId = clusterId + 1 
      cluster['puzzles'] = [puzzleIndex]
      clusterPuzzles.push(Object.assign({}, cluster))

    }
    this.setState({clusterId: clusterId})
    this.props.jigsawPuzzlesInit(puzzles) 
  }

  timeDisplay(){ 
    var minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  photographIcon(width){ 
    if(this.state.photoOn == true){ 
      return <PhotoIcon className="active mx-auto text-gray-500" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <PhotoIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  LightBulbIcon(width){ 
    if(this.state.hintOn == true){ 
      return <LightBulbIcon className=" mx-auto text-gray-500" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <LightBulbIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  buttonClass(state){ 
    if(state == true){ 
     return "sm:big-active"
    }
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  frameWidth(width){ 
    if(width > 1000){ 
      return 0.75 * 1000
    }
    return width
  }

  sampleWidth(width, height){ 
    const ratio = width/height
    // Puzzles on left right for window width > landscapeWidth 
    if(width > landscapeWidth){ 
      if(width > 1000) { 
        return 600
      }
      var sampleHeight = 0.6 * height
      return ratio * sampleHeight
    }
    return 0.9 * width
  }

  displayTime(){ 
    const minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  frameItem(){ 
    return( 
      <div ref={this.state.frameRef} 
        className="mx-auto my-auto"
        style={{ 
          position: 'relative',
          width: `${this.state.frameWidth}px`,
          height: `${this.state.frameHeight}px`,
        }}
      >
        {/* Actual Frame */}
        <div className="z-0 my-auto mx-auto" 
          style={{position: 'absolute', 
              top: `0px`,
              left: `0px`,
              width: `${this.state.frameWidth}px`,
              height: `${this.state.frameHeight}px`,
              backgroundImage: `url(${FrameImage})`,
              backgroundSize: `${this.state.frameWidth}px ${this.state.frameHeight}px`, 
              backgroundRepeat: 'no-repeat'
          }}
        >
          <button class="focus:outline-none fancy-round-button green"
            style={{position: 'relative', 
              top: `${10/frameWidth * this.state.frameWidth}px`,
              left: `${1340/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/buttonRatio}px`,
              height: `${this.state.frameWidth/buttonRatio}px`,
            }}
            onClick={isMobile ? undefined: this.handleAudioToggle.bind(this)}
            onTouchStart={isMobile ? this.handleAudioToggle.bind(this): undefined}                  
          >
            {this.volumeIcon(this.state.frameWidth/buttonRatio)}
          </button>
          <button className="focus:outline-none fancy-round-button red"
            style={{position: 'relative', 
            top: `${10/frameWidth * this.state.frameWidth}px`,
            left: `${1355/frameWidth * this.state.frameWidth}px`,
            width: `${this.state.frameWidth/buttonRatio}px`,
            height: `${this.state.frameWidth/buttonRatio}px`,
          }}
            onClick={isMobile ? undefined: this.handlePause.bind(this)}
            onTouchStart={isMobile ? this.handlePause.bind(this): undefined}     
          >
            <p className="text-white font-black" 
              style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
              <PauseCircleIcon className="mx=auto"/>
            </p>
          </button>
          <button className={`focus:outline-none fancy-round-button orange ${this.buttonClass(this.state.hintOn)}`}
            style={{position: 'absolute', 
            top: `${10/frameWidth * this.state.frameWidth}px`,
              left: `${942/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/buttonRatio}px`,
              height: `${this.state.frameWidth/buttonRatio}px`,
            }}     
            onClick={isMobile ? undefined: this.handleHintToggle.bind(this)}  
            onTouchStart={isMobile ? this.handleHintToggle.bind(this): undefined}           
          >
            {this.photographIcon(135/1763 * this.state.frameWidth )}
          </button>
          
          <div
            style = {{
              position: 'absolute',    
              top: `${70/frameWidth * this.state.frameWidth}px`,
              left: `${20/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/4.5}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="grid grid-cols-2">  
              <ClockIcon className="mx-auto text-white pt-1" 
                style={{
                  width: `${this.state.frameWidth/15}px`,
                  height: `${this.state.frameHeight/2.5}px`,
                }} 
                aria-hidden="true"
              />
              <div className="my-auto">
                <div className="text-right frame-font text-white unselectable"
                  style = {{ lineHeight: `${0.4 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                >
                  {this.timeDisplay()}
                </div> 
              </div>
            </div>              
          </div> 
          {/* Score */}
          <div className="unselectable flex"
            style = {{
              position: 'absolute',    
              top: `${15/frameWidth * this.state.frameWidth}px`,
              left: `${670/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/6}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="h-full m-auto">
              <p className="text-center game-desc text-white"
                style = {{ lineHeight: `${0.4 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
              >
                Score  
              </p>  
            </div>      
          </div> 
          <div
            style = {{
              position: 'absolute',    
              top: `${80/frameWidth * this.state.frameWidth}px`,
              left: `${670/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/6}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="text-center frame-font text-white unselectable"
              style = {{ lineHeight: `${0.4 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
            >
              {this.state.score}
            </div>              
          </div> 
        </div>
      </div>  
    )
  }

  puzzleItems(){ 
    var active = false
    return this.props.puzzles.map((puzzle, index) => {
      const active = puzzleClusterIds[puzzle.index] == this.state.activeClusterId
      return( 
        <Puzzle 
          active = { active }
          key={index} 
          puzzle={puzzle}
          sizeRate={this.state.sizeRate}
          mouseDownHandler={this.handleMouseDown.bind(this, puzzle)} 
          touchStartHandler={this.handleTouchStart.bind(this, puzzle)} 
        />
      )
    })
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      let score = `Your score: ${this.state.score}`
      return( 
        <WellDoneModal
          open = { true }
          score = { `${score}` }
          game = "Jigsaw Puzzles"
          descriptions = { [] }
          buttonSize = { buttonSize(this.state.windowWidth, this.state.windowHeight)}
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  hintDiv(){ 
    if(this.state.hintOn){ 
      return(
        <div className="m-auto bg-white"
          style={{ 
            position: 'absolute',
            left: 0, 
            right: 0, 
            top: 0, 
            bottom: 0, 
            width: `${this.state.sampleWidth}px`,
            height: `${this.state.sampleHeight}px`,
          }}
        >
          <img className="object-contain"
          style={{ 
            opacity: 0.5,
          }}
           src={require(`../../assets/games/jigsaw_puzzles/mount_sinai/background.jpg`)}  />
        </div> 
      )
    }
  }

  completeImgClassName(){ 
    if(this.state.completeImg){ 
      console.log("done-img")
      return "object-contain done-img"
    }else{ 
      console.log("done-img-init")
      return "object-contain done-img-init"
    }
  }
  
  endImgDiv(){ 
    return(
      <div
        className="mx-auto my-4"
        style={{ 
          width: `${this.state.sampleWidth}px`,
          height: `${this.state.sampleHeight}px`,
        }}
      >
        <div 
          className="absolute z-50"
          style={{ 
            width: `${this.state.sampleWidth}px`,
            height: `${this.state.sampleHeight}px`,
          }}
        >
          <img className={this.completeImgClassName()}
           src={require(`../../assets/games/jigsaw_puzzles/mount_sinai/complete.jpg`)}  />
        </div> 
        <div 
          className="absolute z-40"
          style={{ 
            width: `${this.state.sampleWidth}px`,
            height: `${this.state.sampleHeight}px`,
          }}
        >
          <img className="object-contain"
           src={require(`../../assets/games/jigsaw_puzzles/mount_sinai/background.jpg`)}  />
        </div> 
      </div> 
    )
  }



  photoDiv(){ 
    if(this.state.photoOn){ 
      console.log(this.state.frameRef.current.offsetTop)
      console.log(this.state.frameRef.current.offsetTop + this.state.frameHeight)
      console.log(this.state.canvasRef.current.offsetTop)
      return(
        <div className="z-40 bg-white"
          style={{ 
            position: 'absolute',
            left: `${this.state.frameRef.current.offsetLeft}px`, 
            right: 0, 
            top: `${this.state.frameRef.current.offsetTop+this.state.frameHeight}px`, 
            bottom: 0, 
            width: `${this.state.sampleWidth/2}px`,
            height: `${this.state.sampleHeight/2}px`,
          }}
        >
          <img className="object-contain"
           src={require(`../../assets/games/jigsaw_puzzles/mount_sinai/background.jpg`)}  />
          
        </div> 
      )
    }
  }

  gameDiv(){ 
    return(  
      <div className="overflow-hidden disable-touch unselectable mt-4 bg-gray-600"
        ref={this.state.canvasRef}
        style={{ 
          position: 'relative',
          width: `${this.state.windowWidth}px`,
          height: `${0.85 * this.state.windowHeight}px`,
        }}
      >
        { this.hintDiv() }
        { this.puzzleItems() }
      </div>
    )
  }

  doneDiv(){ 
    return(
      <div> 
        {this.endImgDiv()}
        <div className="justify-items-center mt-2 grid grid-cols-2">
          <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
            onClick={(e) => window.location.href = "/games" } 
          >
            <span> 
              <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                style={{transform: "rotateY(180deg)"}} 
              />
            </span> 
            <span className="my-auto hidden md:block">
              Exit
            </span>
           
          </button>
          <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
            onClick={ this.handleRestart.bind(this) }
          >
            <span> 
              <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
            </span> 
            <span className="my-auto hidden md:block">
              Play Again
            </span>
          </button>
        </div>
      </div> 
    )
  }
  
  componentDidUpdate(prevProps) { 
    let activeCluster  
    if(this.props.puzzles != prevProps.puzzles ){ 
      console.log(`DidUpdate: ${this.state.activeClusterId}`)
      if(this.state.activeClusterId == 0){
        // Update activeCluster
        for(var i = 0; i < clusterPuzzles.length; i++ ){ 
          if(clusterPuzzles[i].puzzles.includes(this.state.activePuzzleIndex)){ 
            activeCluster = clusterPuzzles[i]
            this.processMatchingPuzzles(activeCluster)
            break;
          }
        } 
      }
    }
  }

  componentDidMount(){
    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    
    window.addEventListener('focus', this.handleUnpause.bind(this));

    // Inactive
    window.addEventListener('blur', this.handlePause.bind(this));

    for(var i = 0; i < puzzlePieces; i++ ){
      const img = new Image()
      img.src = require(`../../assets/games/jigsaw_puzzles/mount_sinai/${i}.png`);
    }
    var img = new Image()
    img.src = require(`../../assets/games/jigsaw_puzzles/mount_sinai/complete.jpg`);
  } 
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    window.removeEventListener('mousemove', this.handleMouseMove.bind(this) ); 
    window.removeEventListener('contextmenu', this.handleContextMenu.bind(this)) 
    window.removeEventListener('touchend', this.handleMouseUp.bind(this) )
    window.removeEventListener('touchcancel', this.handleMouseUp.bind(this) )
    window.removeEventListener('touchmove', this.handleTouchMove.bind(this) )
    window.removeEventListener('blur', this.handlePause.bind(this));  
  }

  render() {
    return (
      <div> 
        {this.pauseModal()}
        <GameRuleModal
          open = { this.state.gameRule }
          audioToggleHandler = { this.handleAudioToggle.bind(this)}
          startPlayHandler = { this.handleStartPlay.bind(this)}
          audio = { this.state.audioOn }
          thumbnail = { RuleThumb }
          instructions = { ["Assemble the jigsaw puzzle pieces."] }
        />
        {this.wellDoneModal()}
        <div className="bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-h-screen py-1 md:py-4">
          <div className="grid justify-items-center ">
            {this.frameItem()}
          </div>
          { this.photoDiv() }
          {this.state.gameState == "done"?this.doneDiv(): this.gameDiv()}
        </div>
      </div> 
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    puzzles: state.jigsawPuzzles.puzzles,
  }
};

export default connect(mapStateToProps, {
  jigsawPuzzlesInit, 
  jigsawPuzzleUpdate, 
  jigsawPuzzlesActive }) (JigsawPuzzles);