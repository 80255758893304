import axios from 'axios';

import { VIDEO_LIST_FETCH_SUCCESS } from './types';

export const videoListFetch = () => {
  const url = '/api/videos' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: VIDEO_LIST_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};
