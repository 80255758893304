import {
    USER_FETCH_SUCCESS, 
    USER_SIGN_OUT_SUCCESS,
    USER_PROFILE_SAVE_SUCCESS,
    USER_SAVE_SUCCESS, 
    USER_AVATAR_ASSETS_FETCH_SUCCESS,
    USER_UPDATE, 
    USER_AVATAR_OWNER_SAVE_SUCCESS, 
    USER_TROPHIES_FETCH_SUCCESS, 
    USER_TROPHY_FETCH_SUCCESS, 
    USER_TROPHY_SAVE_SUCCESS
  } from '../actions/types.js';
  
const INITIAL_STATE = {
  user: {
  }, 
  profile: {},
  avatarAssets: {},
  trophies: {},
  trophiesLoaded: false,
  trophy: {},
  trophyLoaded: false,
  userLoaded: false,
  gameTrophies: [], 
  quizTrophies: [], 
  signed_out: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case USER_FETCH_SUCCESS: 
      return { ...state, user: action.payload, profile: action.payload.user_profile, userLoaded: true };  
    case USER_SAVE_SUCCESS: 
      return { ...state, user: action.payload };  
    case USER_PROFILE_SAVE_SUCCESS: 
      return { ...state, profile: action.payload.user_profile };  
    case USER_AVATAR_OWNER_SAVE_SUCCESS: 
      return { ...state, avatarAssets: action.payload.avatar_assets, profile: action.payload.user_profile };  
    case USER_AVATAR_ASSETS_FETCH_SUCCESS: 
      return { ...state, avatarAssets: action.payload };  
    case USER_TROPHY_FETCH_SUCCESS: 
    case USER_TROPHY_SAVE_SUCCESS: 
      return { ...state, trophy: action.payload, trophyLoaded: true };  
    case USER_TROPHIES_FETCH_SUCCESS: 
      return { ...state, gameTrophies: action.payload.games, quizTrophies: action.payload.quizzes, trophiesLoaded: true };     
    case USER_UPDATE:   
      return { ...state, profile: { ...state.profile, [action.payload.prop]: action.payload.value} }; 
    case USER_SIGN_OUT_SUCCESS: 
      return { ...state, signed_out: true }; 
    default:
      return state;
  }
};
