import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";

import { XMarkIcon } from '@heroicons/react/24/outline'

import '../../../stylesheets/gameSetting.css';

function textTitleClass() {
  if(isMobile){ 
    return "text-lg mb-1"
  }
  return "text-1xl md:text-2xl mb-4"
}

function buttonTextClass(){ 
  if(isMobile){ 
    return "text-xl"
  }
  return "text-2xl md:text-3xl"
}

function textDescClass() {
  if(isMobile){ 
    return "text-base"
  }
  return "text-lg md:text-xl "
}

function paddingLeft() { 
  if(isMobile){ 
    return '36px'
  }
  return '60px'
}

function paddingTop() { 
  if(isMobile){ 
    return '5px'
  }
  return '20px'
}

function templateLayout(props){ 
  return( 
    <div> 
      <div className="px-2 py-1 md:px-3 md:py-2 bg-primary-color">
        <div className="mb-4 content-between">
          <div className="text-center">
            <Dialog.Title as="h3" className="mt-2 text-white modal-text-title text-3xl md:text-4xl leading-6 font-bold">
               Hint
            </Dialog.Title>
          </div>
        </div>
      </div>
      <div className = "pt-4 px-4"> 
        <div className= "relative grid grid-cols-5">
          <div className="col-start-1 col-span-5"> 
            <img src="/games/images/paint_the_world/hint.webp" />  
          </div>
          <div className="absolute z-10 col-start-3 col-span-3">
              <div className="my-auto p-4 sm:p-5"> 
                <p className={`mb-2 game-desc text-white text-base sm:text-xl md:text-2xl}`}>
                  {props.title} 
                </p>  
                <p className={`game-desc text-white text-sm sm:text-lg md:text-xl}`}>
                  {props.description}
                </p>
       
            </div>
          </div>
        </div>
      </div>
    </div>
  ) 
}

export default function HintModal(props) {
  const cancelButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={props.clickHandler}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle w-full sm:w-1/2 max-w-md">
              {templateLayout(props)}
              <div className="my-auto mx-auto justify-items-center grid grid-cols-1">
                <div className="px-2 py-2 md:px-4 md:py-4">
                  <button className="w-24 md:w-36 fancy-button green"
                    ref={cancelButtonRef}
                    onClick={ props.clickHandler }
                    onTouchStart={ props.clickHandler } 
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}