import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userFetch, userSignout, userSave, userSaveName, userUpdate, userSaveSkin, userSaveFace, userSaveHead, userSaveShirt, userAvatarAssetsFetch, userAvatarOwnersCreate } from '../actions/userActions';
import { avatarSkinsFetch, avatarFacesFetch, avatarHeadsFetch, avatarShirtsFetch } from '../actions/avatarActions';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'
import AvatarModal from '../elements/AvatarModal'
import AvatarProfilePhoto from '../elements/AvatarProfilePhoto'
import AvatarFaceElement  from '../elements/AvatarFaceElement';
import AvatarAssetElement  from '../elements/AvatarAssetElement';
import AvatarSettings from './Settings/AvatarSettings'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      editName: false, 
      name: "", 
      avatarModalOpen: false, 
      pointModalOpen: false,
      enoughPoints: false, 
      profileWidth: 0, 
      selectedItem: null,
      activeUser: false, 
    };
    this.imageDivRef = React.createRef()
  }

  onEditName(){ 
    this.setState({editName: true})
  }
  onCancelEditName(){
    this.setState({editName: false}) 
  }
  onTextChange(prop, e) { 
    this.props.userUpdate({ prop: prop, value: e.target.value})
  }
  onSaveName(props, e){
    this.props.userSaveName(this.props.profile.name)
    this.setState({editName: false, name:this.props.profile.name}) 
  }

  // Called from the modal 
  onSaveAvatarItem(){ 
    this.setState({avatarModalOpen: false})
    this.props.userAvatarOwnersCreate(this.state.selectedItem)
  }

  onCancelAvatarItem(){ 
    this.setState({avatarModalOpen: false})
  }

  onWindowSizeChange(){
    this.setProfileWidth(true)
  };

  onClickPointInfo(){ 
  }

  onSelectAvatarItem(item, e){ 
    // Skin is always free, so directly save it
    if(item.type == "skin"){ 
      this.props.userSaveSkin(item.id)
    }
    else if(item.type == "shirt" && this.props.avatarAssets.shirts.includes(item.id)){ 
      this.props.userSaveShirt(item.id)
    }
    else if(item.type == "face" && this.props.avatarAssets.faces.includes(item.id)){ 
      this.props.userSaveFace(item.id)
    }
    else if(item.type == "head" && this.props.avatarAssets.heads.includes(item.id)){ 
      this.props.userSaveHead(item.id)
    }
    // If the asset not in posession, prompt the modal 
    else{ 
      if(this.props.profile.available_points >= item.points){ 
        this.setState({enoughPoints: true})
      }else{ 
        this.setState({enoughPoints: false})
      }
      this.setState({selectedItem: item})
      this.setState({avatarModalOpen: true})
    }
  }

  handleCheckboxChange(e){ 
    this.props.userSave(this.props.user.id, { newsletter: e.target.checked})
  }

  personalInfo(){ 
    return(
      [
        { title: 'Username:', data: this.props.user.username },
        { title: 'Name:', data: this.props.user.username.name }
      ]
    )
  }

  buttonClass(skin){ 
    if(skin.id == this.props.profile.avatar_skin.id){ 
      return "bg-white border-2 md:border-4 border-secondary-yellow"
    }
    return "bg-gray-100 hover:border-1 hover:border-secondary-dark hover:opacity-75"
  }
  
  setProfileWidth(reset){ 
    if((this.state.profileWidth > 0 && reset == false) || this.imageDivRef.current == null){ 
      return
    }
    const offsetWidth = this.imageDivRef.current.offsetWidth
    this.setState({profileWidth: offsetWidth/2}) 
  }

  usernameOrEmailItem(){ 
    if(this.props.user.username){
      return(
        <div className="mt-2 md:my-4 grid md:grid-cols-4 grid-cols-6 gap-2 justify-between">
          <div className="my-auto">
            <p className="md:text-2xl lesson-text">Username:</p>
          </div>
          <div className="my-auto">
            <p className="my-auto md:text-2xl lesson-text">{this.props.user.username}</p>
          </div>
        </div>
      )
    }
    if(this.props.user.email){
      return(
        <div className="mt-2 md:my-4 grid md:grid-cols-4 grid-cols-6 gap-2 justify-between">
          <div className="my-auto">
            <p className="md:text-2xl lesson-text">Email:</p>
          </div>
          <div className="my-auto">
            <p className="my-auto md:text-2xl lesson-text">{this.props.user.email}</p>
          </div>
        </div>
      )
    }
  }
  nameFieldItem(){ 
    if(this.state.editName){ 
      return(
        <div className="my-auto flex">
          <input
            className="relative outline-none rounded py-1 px-2 w-full bg-white shadow text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:shadow-outline"
            type={"text"}
            value={this.props.profile.name}
            onChange={this.onTextChange.bind(this, 'name')}
          />
          <button className="fancy-button orange"
            onClick={this.onSaveName.bind(this, 'name')}
          > Save </button>
          <button className="fancy-button sm:big"
            onClick={this.onCancelEditName.bind(this)}
          > Cancel </button>
        </div>
      )
    }else{ 
      return(
        <div className="grid grid-cols-3">
          <div className="my-auto">
            <p className="my-auto md:text-2xl lesson-text">{this.props.profile.name}</p>
          </div>
          <div>
            <button className="fancy-button orange"
              onClick={(e)=>this.onEditName(e) }
            > Change </button>
          </div>
        </div>
      )
    }
  }

  nameItem(){ 
    return(
      <div className="mt-2 md:my-4 grid grid-cols-6 md:grid-cols-4 gap-2 justify-between">
        <div className="my-auto">
          <p className="md:text-2xl lesson-text">Name:</p>
        </div>
        <div className="col-start-2 col-span-5 md:col-span-3">
          {this.nameFieldItem()}
        </div>
      </div>
    )
  }

  profileItem(){ 

    const headOffset = parseFloat(this.props.profile.avatar_head.head_offset)
    const avatarHeadLeftOffset = parseFloat(this.props.profile.avatar_head.left_offset)
    const avatarHeadWidth = parseFloat(this.props.profile.avatar_head.width)

    return(
      <div className="pb-8 mx-auto"> 
        <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center mx-auto text-xl md:text-2xl font-medium leading-6 tracking-wide text-gray-900">{this.state.name}'s Profile</h3>
        <div className="md:mt-2 md:grid md:grid-cols-5 md:gap-2 lg:gap-4 justify-between ">
          <div className="relative md:col-start-1 col-span-2" ref={this.imageDivRef}
            style={{height: `${this.state.profileWidth}px`}}
          >   
            <AvatarProfilePhoto { ...this.props } 
              profileWidth = {this.state.profileWidth} 
              left = { this.state.profileWidth/2 }
              />
          </div>
          <div className="ml-4 md:py-2 lg:px-0 md:mt-4  md:row-start-1 md:col-start-3 md:col-span-3">
            {this.nameItem()}
            {this.usernameOrEmailItem()}
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.userLoaded != prevProps.userLoaded) {
      this.setProfileWidth(false)
      if(Object.keys(this.props.user).length){ 
        if(this.state.name == ""){
          this.setState({activeUser: true, name: this.props.profile.name})
        } 
      }
    }
  }

  componentDidMount() {
    this.props.userFetch();
    this.props.userAvatarAssetsFetch();
    this.props.avatarShirtsFetch();
    this.props.avatarFacesFetch();
    this.props.avatarHeadsFetch();
    this.props.avatarSkinsFetch();
    window.addEventListener('resize', this.onWindowSizeChange.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowSizeChange.bind(this));
  }

  render() {
    this.setProfileWidth(false)
    if(!this.props.userLoaded){
      return <div/>
    }
    return (
      <div className="bg-primary-color"> 
        <div> 
          <NavBar/>
          <div className="mx-auto lg:max-w-7xl min-h-screen flex flex-col">
            <div className="min-h-0 flex-1 flex overflow-auto">
              <div className="w-full bg-primary-color mx-auto sm:px-6 lg:px-8 py-5">
                {/* content */}
                {this.profileItem()}
                <div key="subscription" className="ml-4 relative py-4">
                  <div className="min-w-0 flex-1 text-base md:text-2xl leading-4">
                    <label className="game-desc select-none">
                      Get Email Update
                    </label>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        checked={this.props.user.newsletter}
                        onChange={this.handleCheckboxChange.bind(this)}
                        className="h-4 w-4 rounded border-primary-light text-primary-dark focus:ring-primary-dark"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="subscribe" className="game-desc font-medium text-gray-900">
                        Subscribe
                      </label>{' '}
                      <span id="comments-description" className="game-desc text-gray-700">
                        <span className="sr-only">Subscribe </span>to receive newsletter
                      </span>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <Footer/>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return { 
    user: state.user.user,
    profile: state.user.profile,
    userLoaded: state.user.userLoaded, 
    avatarAssets: state.user.avatarAssets,
    avatarSkins: state.avatar.skins, 
    avatarFaces: state.avatar.faces, 
    avatarHairs: state.avatar.hairs,
    avatarHeadwears: state.avatar.headwears,
    avatarShirts: state.avatar.shirts
  }
};

export default connect(mapStateToProps, {
  userFetch, 
  userUpdate,
  userSave,
  userSaveName, 
  avatarSkinsFetch, 
  avatarFacesFetch, 
  avatarHeadsFetch, 
  avatarShirtsFetch,
  userSaveSkin, 
  userSaveFace,
  userSaveHead, 
  userSaveShirt, 
  userAvatarAssetsFetch, 
  userAvatarOwnersCreate
})(Settings);