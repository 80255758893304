import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";

import '../../../stylesheets/fancyButton.css';

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowPathIcon, ArrowRightOnRectangleIcon, XMarkIcon, PlayIcon } from '@heroicons/react/24/outline'

function templateLayout(props){ 
  if(isMobile && window.innerWidth/window.innerHeight > 1){ 
    return( 
      <div className="grid grid-cols-2 gap-2">
        <div>
          <img className="mx-auto" src={props.pauseGif} />
        </div>
        <div className="m-6 bg-primary-color rounded-lg flex text-center">
          <Dialog.Title as="h3" className="my-auto text-white modal-text-title text-3xl md:text-4xl leading-normal font-bold">
             Game Paused
          </Dialog.Title>
        </div>
      </div>
    )
  }else{
    return( 
      <div> 
        <div className="px-2 py-2 md:px-3 md:py-3 bg-primary-color">
          <div className="mb-4 content-between">
            <div className="text-center">
              <Dialog.Title as="h3" className="mt-2 text-white modal-text-title text-3xl md:text-4xl leading-6 font-bold">
                 Game Paused
              </Dialog.Title>
            </div>
          </div>
        </div>
        <div> 
          <img className="mx-auto object-contain w-3/4 md:w-full"
            src={props.pauseGif}
            
          /> 
        </div>
      </div>
    ) 
  }
}

export default function PauseModal(props) {
  const completeButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={completeButtonRef}
        open={props.open}
        onClose={props.unpauseHandler}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle w-full sm:w-1/2 max-w-md">
              {templateLayout(props)}
              <div className="bg-primary-dark px-2 py-2 md:px-4 md:py-4">
                <div className="justify-items-center grid grid-flow-row-dense grid-cols-2 gap-3">  
                  <button className="w-24 md:w-36 fancy-button red"
                    onClick={props.exitHandler.bind(this)}   
                    onTouchStart={props.exitHandler.bind(this)}   
                  >
                    <div className="flex justify-left md:justify-center"> 
                      <div className="flex flex-row">
                        <div>
                          <ArrowRightOnRectangleIcon className="ml-0 md:-ml-2 h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                            style={{transform: "rotateY(180deg)"}}
                          /> 
                        </div>
                        <div className="ml-2 my-auto hidden md:block">
                          Exit
                        </div>
                      </div>
                    </div>
                  </button>

                  <button className="w-24 md:w-36 fancy-button green"
                    ref={completeButtonRef}
                    onClick={props.unpauseHandler.bind(this)}   
                    onTouchStart={props.unpauseHandler.bind(this)}   
                  >
                    <div className="flex justify-left md:justify-center"> 
                      <div className="flex flex-row">
                        <div>
                          <PlayIcon className="ml-0 md:-ml-2 h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                        </div>
                        <div className="ml-2 my-auto hidden md:block">
                          Play
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}