import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import Unity, { UnityContext } from "react-unity-webgl";
import { isMobile } from "react-device-detect";
import ReactPlayer from 'react-player'

import GameRuleModal from "./Modals/GameRuleModal"
import GameTitleModal from "./Modals/GameTitleModal"
import WellDoneModal from "./Modals/WellDoneModal"
import GameOverModal from "./Modals/GameOverModal"
import PauseModal from "./Modals/PauseModal"
import GameIntroVideo from '../../elements/GameIntroVideo'

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon } from '@heroicons/react/24/outline'
import { buttonSize } from '../../helpers/GameHelper'

import { userFetch, userTrophyFetch, userTrophyCreate } from '../../actions/userActions';
import { trophyConstant } from '../../helpers/ConstantHelper'
import RuleThumb from '../../assets/games/where_is_jonah/game_rule.json'
import FrameImage from '../../assets/games/frame2.png'
import PauseGif from '../../assets/animations/pause.gif'
import LoadingGif from '../../assets/animations/loading.gif'


const unityContext = new UnityContext({
 loaderUrl: "builds/jonah/Build.loader.js", // public Let's go to the table of contents 
 dataUrl: "builds/jonah/Build.data",
 frameworkUrl: "builds/jonah/Build.framework.js",
 codeUrl: "builds/jonah/Build.wasm",
});

let gameLoop
let airInLoop
let airOutLoop

const actualBgWidth = 1600 
const actualBgHeight = 900 


class JonahAdventure extends React.Component {
  constructor(props) {
    super(props);
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    this.state = { 
      audioOn: true, 
      gameRule: false,
      gameTitle: true, 
      intro: true, 
      bgMusic: new Audio('/audio/background/local_forecast.mp3'), 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight, 
      bgWidth: this.backgroundWidth(window.innerWidth, window.innerHeight), 
      bgHeight: this.backgroundHeight(bgWidth), 
      sizeRate: this.backgroundWidth(window.innerWidth, window.innerHeight)/actualBgWidth, 
      score: 0,
      day: 1, 
      time: 1440, 
      gameState: "pending", 
      pauseAnimation: PauseGif,
      breathing: false, 
      airLevel: 100, 
      introVidRef: React.createRef(),
      endVidRef: React.createRef(),
      gameOverModal: false, 
      introVideoLoaded: false, 
      unityLoaded: false
    };
  }

  handleWindowSizeChange(){
    if(this.state.windowWidth != window.innerWidth){
      this.resizeCanvas()
    }
  };

  handleIntroPlay(){
    this.setState({introVideoStarted: true})
  }

  handleIntroEnd(){ 
    this.setState({gameState: "ready", gameRule: true})
  }

  handleContextMenu(e){ 
    if(isMobile){ 
      e.preventDefault()
    }
  }

  handleMouseDown(e){
    this.setState({mouseDown: true})
    //this.props.jonahWhaleUp()
  }

  handleMouseUp(e){
    this.setState({mouseDown: false})
  }

  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
      this.state.introVidRef.current.volume = 0.6
    }else{ 
      this.state.bgMusic.pause()
      this.state.introVidRef.current.volume = 0
      this.state.endVidRef.current.volume = 0.0
    }
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.setState({gameState: "done"})
    setTimeout(() => { 
      this.state.bgMusic.pause();
      this.state.endVidRef.current.muted = !this.state.audioOn   
      this.state.endVidRef.current.play();
    }, 500)
  }

  handlePause(){ 
    if(this.state.gameState == "running")
    {
      unityContext.send("GameController", "PauseGame");
      this.setState({gameState: "paused"})
      this.state.bgMusic.pause()
    }
  }

  handleUnpause(){ 
    unityContext.send("GameController", "ResumeGame");
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({gameState: "running"})
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleRestart(){ 
    unityContext.send("Whale", "Restart");
    console.log(`handleRestart`)
    this.setState({airLevel: 100, time: 1440, day: 1, gameState: "started"})
    this.startGame(this)
    this.state.bgMusic.currentTime = 0
    this.playBackgroundAudio();
  }

  handleExit(){ 
    this.props.history.goBack()
  }

  handleGameOver(e){ 
    unityContext.send("GameController", "PauseGame");
    this.processGameOver()
    this.setState({gameState: "gameOver"})
    let trophy = null
    if(this.state.day == 1){ 
      this.setState({gameOverModal: true})
    }
    else{ 
      if(this.state.day == 2){
        if(!this.props.trophy || this.props.trophy.bronze != true){ 
          trophy = "bronze" 
        }
      }else if(this.state.day == 3){ 
        if(!this.props.trophy || this.props.trophy.silver != true){ 
          trophy = "silver" 
        }
      }
      this.setState({wellDoneModal: true, trophy: trophy})
      if(this.state.activeUser && trophy){ 
        this.props.userTrophyCreate("game", "jonah-adventure", trophyConstant[trophy])  
      } 
    }
  }

  handleBreathingStatus(breathing){ 
    this.setState({breathing: breathing})
  }

  handleGameOverComplete(){ 
    this.setState({gameState: "done"})
    this.setState({gameOverModal: false})
    
    setTimeout(() => { 
      this.state.bgMusic.pause();
      this.state.endVidRef.current.muted = !this.state.audioOn   
      this.state.endVidRef.current.play();
    }, 500)
  }

  handleStartPlay(){
    this.playBackgroundAudio()
    this.setState({gameRule: false, gameState: "started"})
    let thisObj = this
    setTimeout(function(){ 
      if(thisObj.state.unityLoaded){
        thisObj.transitGameRunning(thisObj)
      }
    }, 1000) 
  }

  handleStartIntro(){
    const video = this.state.introVidRef.current
    this.state.bgMusic.pause()
    video.muted = !this.state.audioOn   
    video.play();
    video.addEventListener('ended', this.handleVideoEnd.bind(this), false)
    this.setState({gameTitle: false})
    
  }

  handleVideoEnd(){ 
    this.state.bgMusic.currentTime = 0
    this.playBackgroundAudio()
    this.setState({gameRule: true})
  }

  handleLoadingProgress(percentage){ 
    console.log(`loadingProgress: ${percentage}`)
    if(percentage == 1){ 
      const thisObj = this
      setTimeout(function(){ 
        thisObj.setState({unityLoaded: true})
      }, 1500)
      setTimeout(function(){ 
        thisObj.transitGameRunning(thisObj)
      }, 2000)
    }
  }

  processGameOver(){ 
    unityContext.send("GameController", "PauseGame");
    clearInterval(gameLoop)
    clearInterval(airInLoop)
    clearInterval(airOutLoop)
  }

  playBackgroundAudio(){ 
    if(this.state.audioOn == true){ 
      this.state.bgMusic.volume = 0.3
      this.state.bgMusic.play()
    }
  }

  transitGameRunning(thisObj){ 
    console.log(`transitGameRunning: ${thisObj.state.unityLoaded}, ${thisObj.state.gameState}`)
    if(thisObj.state.unityLoaded && thisObj.state.gameState == "started"){
      this.startGame(thisObj)
    }
  }

  startGame(thisObj){ 
    setTimeout(function(){ 
      console.log(`unity resumeGame`)
      unityContext.send("GameController", "ResumeGame");
      console.log(`set gameState to running`)
      thisObj.setState({gameState: "running"})
      setTimeout(function(){ 
        gameLoop = setInterval(() => { 
          if(thisObj.state.gameState == "running"){
            const time = thisObj.state.time - 1
            thisObj.setState({time: time})
            if(time == 0){ 
              let trophy = null
              const day = thisObj.state.day + 1
              let score = thisObj.state.score 
              thisObj.setState({score: score + 500})
              // Complete the 3 days adventure 
              if(day > 3){ 
                if(!thisObj.props.trophy || thisObj.props.trophy.gold != true){ 
                  trophy = "gold" 
                  if(thisObj.state.activeUser){ 
                    thisObj.props.userTrophyCreate("game", "jonah-adventure", trophyConstant[trophy])  
                  } 
                }
                thisObj.setState({wellDoneModal: true, gameState:"gameOver", trophy: trophy})
                thisObj.processGameOver()
              }else{ 
                unityContext.send("Whale", "GameLevel", day);
                thisObj.setState({day: thisObj.state.day + 1, time: 24 * 60})
              }
            }
          }
        }, 40)
        airOutLoop = setInterval(() => { 
          if(thisObj.state.gameState == "running"){
            if(!thisObj.state.breathing){
              if(thisObj.state.airLevel == 0){ 
                thisObj.handleGameOver()
              }
              if(thisObj.state.airLevel > 0){ 
                thisObj.setState({airLevel: thisObj.state.airLevel - 1})
              }
            }
          }
        }, 300)
        airInLoop = setInterval(() => { 
          if(thisObj.state.gameState == "running"){
            if(thisObj.state.breathing){
              if(thisObj.state.airLevel < 100){ 
                thisObj.setState({airLevel: thisObj.state.airLevel + 1})
              }
            }
          }
        }, 80)

      }, 100)
    }, 500)
  }

  resizeCanvas(){ 
    this.setState({pause: true})
    this.setState({pauseTime: new Date().getTime()})
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    var sizeRate = bgWidth/actualBgWidth
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.setState({ bgWidth: bgWidth, bgHeight:bgHeight });
    this.setState({sizeRate: bgWidth/actualBgWidth})    
  }

  componentDidUpdate(prevProps) {
    if(this.props.userLoaded != prevProps.userLoaded){ 
      // Only fetch trophies if user is login 
      if(Object.keys(this.props.user).length){ 
        this.setState({activeUser: true, gameState:"ready", gameRule: true})
        this.props.userTrophyFetch("game", "feeding-frenzy")
      }
    }
  }

   
  componentDidMount() {
    unityContext.on("GameOver", this.handleGameOver.bind(this))
    unityContext.on("BreathingStatus", this.handleBreathingStatus.bind(this))
    unityContext.on("progress", this.handleLoadingProgress.bind(this)) 
    //unityContext.on("progress", this.handleLoadingProgress.bind(this)) 
    var bgWidth = this.backgroundWidth(window.innerWidth, window.innerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    document.addEventListener('dragstart', event=>event.preventDefault())
    window.addEventListener('contextmenu', this.handleContextMenu.bind(this))
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.addEventListener('blur', this.handlePause.bind(this));

    this.setState({sizeRate: bgWidth/actualBgWidth})   
  
    window.scrollTo(0, 0);
    this.props.userFetch()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.removeEventListener('blur', this.handlePause.bind(this));
  }

  backgroundWidth(width, height){ 
    if(width/height > (1763/1210)){ 
      return 0.85 * height * 1763/1210
    }
    if(width > 1280){ 
      return 0.7 * width 
    }else{ 
      return 0.9 * width
    }
  }

  paddingClass(){ 
    if(window.innerWidth/window.innerHeight > 1763/1210){ 
      return "py-1"
    }else{ 
      return "py-5 lg:py-10"
    }
  }

  backgroundHeight(width){ 
   var height = parseFloat(width) * 9 / 16
    return (parseFloat(width) * 9 / 16)
  }

  pauseModal(){
    if(this.state.gameState == "paused"){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.gameState == "paused"}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  displayTime(){ 
    var hour = parseInt(this.state.time/60) 
    var minute = (this.state.time % 60) + ""
    if(minute.length < 2){ 
      minute = "0" + minute
    }
    return(
      <div className="my-auto grid grid-cols-4">
        <div> 
        </div>
        <div className="text-right game-desc text-white"
          style = {{ fontSize: `${55 * this.state.sizeRate}px` }}
        >
         {hour}
        </div> 
        <div className="text-center game-desc text-white"
          style = {{ fontSize: `${55 * this.state.sizeRate}px` }}
        >
         :
        </div> 
        <div className="text-left game-desc text-white"
          style = {{ fontSize: `${55 * this.state.sizeRate}px` }}
        >
         {minute}
        </div> 
      </div>
    )
  }

  introVideoDiv(){ 
    if(this.props.userLoaded && !this.state.activeUser){ 
      return(
        <GameIntroVideo
          introEndHandler = { this.handleIntroEnd.bind(this)}
          introPlayHandler = { this.handleIntroPlay.bind(this)}
          introTextEnabled = { !this.state.introVideoStarted && this.props.userLoaded }
          videoId = { "zzxU34i5Z5M" }
        />
      )
    }
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          trophy = { this.state.trophy }
          searchParams = { '?tab=game'}
          game = "Jonah Adventure"
          activeUser = { this.state.activeUser }
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  gameOverModal(){
    if(this.state.gameOverModal==true){ 
      return( 
        <GameOverModal
          open = { true }
          buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
          endHandler = { this.handleGameOverComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  gameDivClass(){ 
    if(this.state.gameState == "pending" || this.state.gameState == "done"){ 
      return "invisible"
    }
    return ""
  }

  airLevelColor(){ 
    if(this.state.airLevel < 20){ 
      return 'text-white blink-fast'
    }
    return 'text-white'
  }

  doneDiv(){ 
    if(this.state.gameState == "done"){ 
      return(
        <div> 
          <div className="p-4 flex justify-center justify-items-center mx-auto"> 
            <video style={{width:`${1600 * this.state.sizeRate}px`}} ref={this.state.endVidRef}>
              <source src={`/videos/jonah_end.mp4`} type="video/mp4" />
            </video>
          </div>
          <div className="mx-auto justify-items-center mt-2 grid grid-cols-2"
            style={{width:`${1600 * this.state.sizeRate}px`}} 
          >
            <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
              onClick={(e) => window.location.href = "/games" } 
            >
              <span> 
                <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                  style={{transform: "rotateY(180deg)"}} 
                />
              </span> 
              <span className="my-auto hidden md:block">
                Exit
              </span>
             
            </button>
            <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
              onClick={ this.handleRestart.bind(this) }
            >
              <span> 
                <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
              </span> 
              <span className="my-auto hidden md:block">
                Play Again
              </span>
            </button>
          </div>
        </div> 
      )
    }
  }

  gameDiv(){ 
    if(this.state.gameState == "pending"){ 
      return this.introVideoDiv()
    }
    return( 
      <div> 
        {this.pauseModal()}
        {this.gameOverModal()}
        {this.wellDoneModal()}
        <GameRuleModal
          open = { this.state.gameRule }
          audioToggleHandler = { this.handleAudioToggle.bind(this)}
          startPlayHandler = { this.handleStartPlay.bind(this)}
          audio = { this.state.audioOn }
          lottieJson = { RuleThumb }
          heading = { "Imagine yourself as Jonah staying inside the fish for 3 days." }
          instructions = { ["Tap/Click and hold to make the fish swims up.", "Watch out the air level of the fish."] }
        />
        <div className="bg-yellow mx-auto my-auto"
          style={{ 
            position: 'relative',
            width: `${1763 * this.state.sizeRate}px`,
            height: `${1210 * this.state.sizeRate}px`,
          }}
        > 
          {this.doneDiv()}
          <div className={`z-20 m-auto ${this.gameDivClass()}`}
            style={{position: 'absolute', 
                  top: `0px`,
                  left: `0px`,
                  width: `${1763 * this.state.sizeRate}px`,
                  height: `${1210 * this.state.sizeRate}px`,
            backgroundImage: `url(${FrameImage})`,
            backgroundSize: `${1763 * this.state.sizeRate}px ${1210 * this.state.sizeRate}px`, 
            backgroundRepeat: 'no-repeat'
            }}
          >  
            <button className="focus:outline-none fancy-round-button red"
              style={{position: 'absolute', 
                top: `${1075 * this.state.sizeRate}px`,
                left: `${1480 * this.state.sizeRate}px`,
                width: `${115 * this.state.sizeRate}px`,
                height: `${115 * this.state.sizeRate}px`,
              }}
              onClick={this.handlePause.bind(this)}
            >
              <p className="text-white font-black" 
                style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
                <PauseCircleIcon className="mx=auto"/>
              </p>
            </button>
            <button class="focus:outline-none fancy-round-button green"
              style={{position: 'absolute', 
                top: `${1075 * this.state.sizeRate}px`,
                left: `${1263 * this.state.sizeRate}px`,
                width: `${115 * this.state.sizeRate}px`,
                height: `${115 * this.state.sizeRate}px`,
              }}
              onClick={this.handleAudioToggle.bind(this)}                
            >
              {this.volumeIcon(100 * this.state.sizeRate )}
            </button>
            <div className="flex flex-col space-y-0"
              style = {{
                position: 'absolute',    
                top: `${0 * this.state.sizeRate}px`,
                left: `${150 * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className="unselectable mr-2 text-right game-desc text-white"
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${65 * this.state.sizeRate}px` }}
                >
                  Air
                </p>
              </div>
            </div>
            <div
              style = {{
                position: 'absolute',    
                top: `${80 * this.state.sizeRate}px`,
                left: `${150 * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="my-auto">
                <p className={`unselectable mr-2 text-right game-desc ${this.airLevelColor()}`}
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${55 * this.state.sizeRate}px` }}
                >
                 {this.state.airLevel}
                </p>
              </div>
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${0 * this.state.sizeRate}px`,
                left: `${1300 * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div>
                <p className="unselectable mr-1 text-right game-desc text-white"
                  style = {{ lineHeight: `${80 * this.state.sizeRate}px`, fontSize: `${65 * this.state.sizeRate}px` }}
                >
                  Day {this.state.day}
                </p>
              </div>
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${80 * this.state.sizeRate}px`,
                left: `${1300 * this.state.sizeRate}px`,
                width: `${290 * this.state.sizeRate}px`, 
                height: `${80 * this.state.sizeRate}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              {this.displayTime()}
            </div> 
          </div>
          <div className={`z-30 ${this.gameDivClass()}`}
                style = {{position: 'absolute',    
                          top: `${164 * this.state.sizeRate}px`,
                          left: `${81 * this.state.sizeRate}px`,
                          width: `${this.state.bgWidth}px`,
                          height: `${this.state.bgHeight}px`,
                          background: 'transparent',
                          overflow: 'hidden'}}
              onMouseDown={this.handleMouseDown.bind(this)} 
              onMouseUp={this.handleMouseUp.bind(this)} 
              onMouseLeave={this.handleMouseUp.bind(this)} 
              onTouchStart={this.handleMouseDown.bind(this)} 
              onTouchEnd={this.handleMouseUp.bind(this)} 
              onTouchCancel={this.handleMouseUp.bind(this)}>
              <Unity style={{'width': '100%', 'height': '100%'}} unityContext={unityContext} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div> 
        <div className={`mx-auto my-auto grid justify-items-center bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-w-screen min-h-screen ${this.paddingClass()}`}
          style={{position: 'relative'}}
        >
          {this.gameDiv()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    user: state.user.user,
    userLoaded: state.user.userLoaded
  }
};

export default connect(mapStateToProps, { 
  userFetch, 
  userTrophyFetch, 
  userTrophyCreate
}) (JonahAdventure);