import {
    ARTICLES_FETCH_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    articles: []
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case ARTICLES_FETCH_SUCCESS: 
        return { ...state, articles: action.payload };  
      default:
        return state;
    }
  };

