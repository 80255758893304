import {
    CRAFT_LIST_FETCH_SUCCESS, 
    CRAFT_FETCH_SUCCESS
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    craftList: [],
    craft: {}
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case CRAFT_FETCH_SUCCESS: 
      return { ...state, craft: action.payload };  
      case CRAFT_LIST_FETCH_SUCCESS: 
        return { ...state, craftList: action.payload };  
      default:
        return state;
    }
  };
