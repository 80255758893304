export const lookupTable = { "garden-of-eden": 0,  "mount-sinai": 1 }
export const coverPresent = [ true, false ]
export const folderName = [ "garden_of_eden", "mount_sinai" ]

export const gameName = ["Garden of Eden", "Mount Sinai"]

export const instructions = [ 
  ["Find 10 hidden animals in the garden."],
  ["This is a picture of Jesus giving sermon on the Mount Sinai.", "Find 10 objects that shouldn't be there during Jesus' time."] 
]

export const heading = [ 
  ["Imagine yourself as Adam in the Garden of Eden, playing hide and seek with the animals."], 
  ["Imagine yourself as one of Jesus' follower, listening to His preaching. "]
]

export const videoId = [ "m5gszpArVPY", ""]

export const itemsInfo = [ 
  [ // garden of eden
    { // croc
      left: 1672, 
      top: 463, 
      width: 604, 
      height: 203
    }, 
    { // elephant
      left: 167, 
      top: 174, 
      width: 202, 
      height: 157
    },
    { // cub
      left: 2517, 
      top: 844, 
      width: 288, 
      height: 190,
    }, 
    { // pig
      left: 435, 
      top: 363, 
      width: 225, 
      height: 166,
    },
    { // bear
      left: 1926, 
      top: 68, 
      width: 217, 
      height: 277,
    },
    { // goat
      left: 2483, 
      top: 1132, 
      width: 310, 
      height: 305,
    },
    { // turtle
      left: 1500, 
      top: 1197, 
      width: 201, 
      height: 117,
    },
    { // flamingo
      left: 345, 
      top: 1112, 
      width: 205, 
      height: 329,
    },
    { // monkey
      left: 1015, 
      top: 355, 
      width: 114, 
      height: 255,
    },
    { // giraffe
      left: 2381, 
      top: -43, 
      width: 356, 
      height: 532,
    },
  ],
  [ // Mount Sinai
    { // trophy
      left: 1859, 
      top: 393.6, 
      width: 119, 
      height: 123
    }, 
    { // phone
      left: 1260, 
      top: 852, 
      width: 86, 
      height: 283.5
    }, 
    { // radio
      left: 2000, 
      top: 1517.4, 
      width: 267.4, 
      height: 153,
    }, 
    { // pump
      left: 794, 
      top: 749.4, 
      width: 95.5, 
      height: 222
    },
    { // globe
      left: 2334.5, 
      top: 896, 
      width: 156, 
      height: 214,
    },
    { // balloon
      left: 1290, 
      top: 205, 
      width: 78.5, 
      height: 218.5,
    },
    { // clock
      left: 80, 
      top: 567, 
      width: 124, 
      height: 124,
    },
    { // guitar
      left: 1046.5, 
      top: 402, 
      width: 75.7, 
      height: 218,
    },
    { // dynamites
      left: 92.5, 
      top: 951, 
      width: 197, 
      height: 93,
    },
    { // ice cream
      left: 2040, 
      top: 787, 
      width: 58.5, 
      height: 141,
    },
  ]
]

export const coversInfo = [ 
  [ // garden of eden
    { // croc
      left: 1670, 
      top: 455, 
      width: 596, 
      height: 147
    }, 
    { // elephant
      left: 167, 
      top: 174, 
      width: 208, 
      height: 155
    }, 
    { // cub
      left: 2532, 
      top: 859, 
      width: 258, 
      height: 172,
    }, 
    { // pig
      left: 435, 
      top: 363, 
      width: 213, 
      height: 128,
    },
    { // bear
      left: 1926, 
      top: 68, 
      width: 172, 
      height: 246,
    },
    { // goat
      left: 2483, 
      top: 1132, 
      width: 310, 
      height: 305,
    },
    { // turtle
      left: 1500, 
      top: 1197, 
      width: 201, 
      height: 117,
    },
    { // flamingo
      left: 345, 
      top: 1112, 
      width: 205, 
      height: 329,
    },
    { // monkey
      left: 1015, 
      top: 355, 
      width: 114, 
      height: 255,
    },
    { // giraffe
      left: 2381, 
      top: -43, 
      width: 356, 
      height: 532,
    },
  ],
  [ // Mount Sinai
    { // trophy
      left: 1859, 
      top: 393.6, 
      width: 119, 
      height: 123
    }, 
    { // phone
      left: 1260, 
      top: 852, 
      width: 86, 
      height: 283.5
    }, 
    { // radio
      left: 2000, 
      top: 1517.4, 
      width: 267.4, 
      height: 153,
    }, 
    { // pump
      left: 794, 
      top: 749.4, 
      width: 95.5, 
      height: 222
    },
    { // globe
      left: 2334.5, 
      top: 896, 
      width: 156, 
      height: 214,
    },
    { // balloon
      left: 1290, 
      top: 205, 
      width: 78.5, 
      height: 218.5,
    },
    { // clock
      left: 80, 
      top: 567, 
      width: 124, 
      height: 124,
    },
    { // guitar
      left: 1046.5, 
      top: 402, 
      width: 75.7, 
      height: 218,
    },
    { // dynamites
      left: 92.5, 
      top: 951, 
      width: 197, 
      height: 93,
    },
    { // ice cream
      left: 2040, 
      top: 787, 
      width: 58.5, 
      height: 141,
    },
  ]
]