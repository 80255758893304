import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { MdOutlineClose } from "react-icons/md"; 

const islandWidth = 5000 
const starWidth = 91 
const starHeight = 116 

const areaWidth = 270
const areaHeight = 200

const themeInfo = [ 
  { 
    left: 700,
    top: 540, 
    width: 1535,
    height: 720, 
    points: "360 0,920 0,1500 280, 1500 380, 930 690,550 690, -40 230, 0 120"  
  },
  { 
    top: 45, 
    left: 3800, 
    width: 860,
    height: 500,
    points: "300 0,650 0, 900 500, 600 600, 0, 400, 0 240"
  }
]

class PlanZoomTheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
     
    }
  }

  zoomBgDimension(){ 
    let aspectRatio = window.innerWidth/window.innerHeight
    let width = 0
    let height = 0
    let left = 0
    let themeAspectRatio = 0 

    let info = themeInfo[this.props.theme]
    themeAspectRatio = info.width/info.height

    // phone
    if(isMobile && (aspectRatio > 1.6 || aspectRatio < 0.6 )){ 
      // landscape
      if(aspectRatio > 1){ 
         height = 0.9 * window.innerHeight
         width = themeAspectRatio * height
      }
      // portrait 
      width = 0.9 * window.innerWidth
      height = width/themeAspectRatio
    }
    // portrait tablet
    else if(isMobile && aspectRatio < 1){ 
      width = 0.85 * window.innerWidth
      height = width/themeAspectRatio
    }
    else{
      width = 0.75 * window.innerWidth
      height = width/themeAspectRatio
    }
    left = (window.innerWidth - width)/2
    return {left: left, width: width, height: height}
  }

  starItem(dimensions){ 
    let info = themeInfo[this.props.theme]
    let sizeRatio = islandWidth/dimensions.width
    let scale = islandWidth/themeInfo[this.props.theme].width
    let starSizeRatio = dimensions.width/info.width

    return this.props.zoomData.map((data, index) => {
      let compensation =  data.info.starLeft/400
      return(
        <div className={`z-30`}
            style = {{position: 'absolute',    
                      left: `${dimensions.left - compensation + (data.info.starLeft - info.left) * starSizeRatio}px`,
                      top: `${0  + (data.info.starTop - info.top) * starSizeRatio}px`,
                      width: `${starWidth * starSizeRatio}px`,
                      height: `${starHeight * starSizeRatio}px`,
                      background: 'transparent',
                      overflow: 'hidden'}}
        >
          <img className="unselectable w-full h-full" style={{objectFit: "fill"}}  src={`/images/plan/${data.starImage}.webp`} alt="" /> 
        </div>
      )
    })
  }

  clickableItem(dimensions){ 
    let info = themeInfo[this.props.theme]
    let starSizeRatio = dimensions.width/info.width
    return this.props.zoomData.map((data, index) => {
      return(
        <div className={`z-30 cursor-pointer`}
          style = {{position: 'absolute',  
                    left: `${dimensions.left -2 + (data.info.left - info.left) * starSizeRatio}px`,  
                    top: `${0  + (data.info.top - info.top) * starSizeRatio}px`,
                    width: `${areaWidth * starSizeRatio}px`,
                    height: `${areaHeight * starSizeRatio}px`,
                    background: 'transparent',
                    overflow: 'hidden'}}
          onClick={this.props.clickHandler.bind(this, data.index) }
        />
      )
    })
  }


  render() {
    let theme = this.props.theme
    let ratio = themeInfo[theme].width/themeInfo[theme].height
    let dimensions = this.zoomBgDimension()
    let scale = islandWidth/themeInfo[theme].width
    let sizeRatio = islandWidth/dimensions.width

    return (
      <div>
        <div className={`z-30 bg-sea-color border-2 border-black overflow-hidden`}
                  style = {{position: 'absolute',    
                            top: 0,
                            left:`${dimensions.left}px`,
                            width: `${dimensions.width}px`,
                            height: `${dimensions.height}px`,                           
                            }}
        >
          <img className="unselectable" 
            style={{
              transformOrigin: "top left",
              transform: `scale(${scale}, ${scale}) translate(-${themeInfo[theme].left / sizeRatio}px, -${themeInfo[theme].top / sizeRatio}px)`
            }} 
            src="/images/plan/plan_map.webp" alt="" />     
            
            
        </div> 
        <div className={`z-30`}
          style = {{position: 'absolute',  
                    left: `${dimensions.left}px`,  
                    top: `${0}px`,
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                    background: 'transparent',
                    overflow: 'hidden'}}
        >
          <div className = "relative float-right">
            <p className="cursor-pointer text-right text-3xl sm:text-5xl text-white">
              <MdOutlineClose onClick={this.props.closeHandler.bind(this)} />
            </p>
          </div>
        </div>
        {this.starItem(dimensions)}
        {this.clickableItem(dimensions)}
      </div>
    );
  }
}

export default connect() (PlanZoomTheme);