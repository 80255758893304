import { SPOT_DIFF_INIT, SPOT_DIFF_UPDATE } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const spotDiffUpdate = (index, value) => {
  return {
    type: SPOT_DIFF_UPDATE,
    index: index,
    value: value
  }
};

export const spotDiffInit = (payload) => {
  return {
    type: SPOT_DIFF_INIT,
    payload: payload
  }
};
