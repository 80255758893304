import {
  JIGSAW_PUZZLES_INIT, 
  JIGSAW_PUZZLE_UPDATE,
  JIGSAW_PUZZLES_ACTIVE,
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    puzzles: [], 
  };
  
export default (state = INITIAL_STATE, action) => { 
  switch(action.type) {   
    case JIGSAW_PUZZLES_INIT: 
      return { ...state, puzzles: action.puzzles}  

    case JIGSAW_PUZZLE_UPDATE:  
      var newArray = [...state.puzzles]; //making a new array
      //console.log(`update ${action.index}`)
      const index = state.puzzles.findIndex(x => x.index == action.index)
      newArray.splice(index, 1)
      newArray = newArray.concat(action.puzzle)
      //newArray[index] = action.puzzle //changing value in the new array
      //console.dir(newArray, {'maxArrayLength': null})
      return { 
        ...state, //copying the original state
        puzzles: newArray, //reassingning cards to new array
      }

    case JIGSAW_PUZZLES_ACTIVE:
      const newPuzzles = [...state.puzzles]; 
      console.log(`active ${action.indexes}`)
      console.dir(newPuzzles, {'maxArrayLength': null})

      const activePuzzles = newPuzzles.filter(puzzle => action.indexes.includes(puzzle.index))
      const indexes = newPuzzles.map((elm, idx) => action.indexes.includes(elm.index)? idx: '').filter(String)
      //console.dir(activePuzzles, {'maxArrayLength': null})
      for (var i = indexes.length -1; i >= 0; i--){ 
        newPuzzles.splice(indexes, 1)
      }
      //console.dir(newPuzzles, {'maxArrayLength': null})
      var test = newPuzzles.concat(activePuzzles)
      //console.dir(test, {'maxArrayLength': null})
      return {
        ...state, 
        puzzles: newPuzzles.concat(activePuzzles)
      }

    default:  
      return state; 
  } 
}; 