import axios from 'axios';

import { ARTICLE_FETCH_SUCCESS, ARTICLES_FETCH_SUCCESS, ARTICLE_SAVE_SUCCESS } from './types';

export const articleFetch = (id) => {
  const url = '/api/admin/articles/' + `${id}`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: ARTICLE_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const articlesFetch = (articleType) => {
  const url = '/api/admin/articles'
  const params = new URLSearchParams([['article_type', articleType]]);
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url, {params})
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: ARTICLES_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const articleCreate = (content, articleType, topic) => {
  const url = '/api/admin/articles'
  const params = {
    content: content,
    article_type: articleType, 
    topic: topic
  }
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.post(url, params)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: ARTICLE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const articleUpdate = (id, content) => {
  const url = '/api/admin/articles/' + `${id}`
  const params = {
    content: content
  }
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.put(url, params)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: ARTICLE_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};




