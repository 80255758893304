import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import { CheckIcon, SpeakerXMarkIcon, SpeakerWaveIcon, PlayIcon } from '@heroicons/react/24/outline'
import { MdOutlineClose } from "react-icons/md"; 
import { RadioGroup } from '@headlessui/react'


function volumeIcon(audio){ 
  if(audio == true){ 
    return <SpeakerWaveIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }else{
    return <SpeakerXMarkIcon className="h-12 w-12 text-white" aria-hidden="true"/>
  }
}

function handleClickItem(starInfo, props){ 
  props.closeHandler(null)
  window.location.href = `${starInfo.url}`
}

function checkboxOrButton(starInfo, index, props){ 
  if(starInfo.done){ 
    return(
      <div className="ml-12 flex h-6 items-center">
        <input
          id={index}
          checked = { true }
          disabled = {true }
          type="checkbox"
          className="h-4 w-4 rounded text-primary-color focus:ring-primary-color"
        />
      </div>
    )
  }else{ 
    return(
      <div className="ml-10 flex h-8 items-center">
        <button className="-mt-2 h-8 w-8 fancy-round-button orange small"
          onClick={(e) => handleClickItem(starInfo, props) } 
        >
         Go
        </button>
      </div>
    )
  }
}

function itemList(props){ 
  let starInfo = props.starInfo

  if(!props.starInfo){ 
    return <div/>
  }
  return (
    <fieldset>
      <div className="">
        { starInfo.map((item, index) => (
          <div className="mt-2 md:my-4 grid grid-cols-2 gap-2">
            <div className="text-right text-white game-desc" >
              {item.text}
            </div>
            {checkboxOrButton(item, index, props)}
          </div>
        ))}
      </div>
    </fieldset>
  )
}

function signInLink(activeUser){ 
  if(!activeUser){ 
    return(
      <div className="mt-2 text-center text-white game-desc ">
        <Link className="plan-link"
          onClick={(e) => window.location = `/users/sign_in` } 
        >Login </Link>
        to track your progress
      </div>
    )
  }
}

function templateLayout(props){ 
  if(props.phoneLandscape){ 
    return( 
      <div> 
        <div className="text-center -mt-2">
          <p className="game-desc text-1xl md:text-2xl text-yellow-200 mb-1 md:mb-2 lg:mb-4">
            {props.topic.name}  
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="py-2">
            <img className="mx-auto" src={props.topic.thumbnail} />
          </div>
          <div className="ml-2">
            {itemList(props)}
          </div>
        </div>
      </div> 
    ) 
  }else{ 
    return( 
      <div className="text-center -mt-4">
        <p className="game-desc text-1xl md:text-2xl text-yellow-200 mb-1 md:mb-2 lg:mb-4">
          {props.topic.name}  
        </p>
        <div className="py-2">
          <img className="mx-auto w-2/3" src={props.topic.thumbnail} />
        </div>
        <div className="w-2/3 mx-auto">
          {itemList(props)}
        </div>
      </div>
    )
  }
}

export default function PlanModal(props) {
  const completeButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={completeButtonRef}
        open={props.open}
        onClose={props.closeHandler}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg px-4 p-2 pb-4 text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm lg:max-w-lg sm:w-full">
              <div className="text-right">
                <button className="no-outline-button"
                  onClick={props.closeHandler.bind(this, null)}
                >
                  <p className="text-3xl sm:text-5xl text-white">
                    <MdOutlineClose/>
                  </p>
                </button>
              </div>
              {templateLayout(props)}
              <div className="mt-2 my-auto mx-auto justify-items-center grid grid-cols-1">
                <button className="w-1/2 fancy-button green"
                  ref={completeButtonRef}
                  onClick={(e) => props.closeHandler(props.topic.url) }
                >
                  Go to Page
                </button>
              </div>
              {signInLink(props.activeUser)}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}