import {
    VIDEO_LIST_FETCH_SUCCESS, 
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    videoList: [],
    lesson: {}
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case VIDEO_LIST_FETCH_SUCCESS: 
        return { ...state, videoList: action.payload };  
      default:
        return state;
    }
  };
