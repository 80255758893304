import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from '../elements/NavBar'
import Footer from '../elements/Footer'

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div> 
        <div className="min-h-screen bg-primary-dark"> 
          <NavBar/>
          <div className="overflow-hidden">
            <div className="min-h-screen m-auto relative max-w-7xl bg-primary-color py-6 md:py-10 px-4 sm:px-6 lg:px-8">
              <div>
                <h3 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-4xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-4xl">
                  Privacy Policy
                </h3>
              </div> 
              <div>
                <p className="mt-2 lesson-text text-justify text-lg text-gray-800 font-bold">
                  Put simply: we will NEVER share your information with anyone!
                </p>
                <br/>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                 This Privacy Policy describes how your personal information is collected, used, and shared when you visit or register an account in BibleFunClub.com
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  General Information
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  BibleFunClub.com collects personal information such as your name and email address when you register with BibleFunClub.com.  
                  That’s just so you can personalize your account, and we can send you updates, or other essential information. 
                  We’ll never sell your personal info to any third parties.
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Data We Collect
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  When you visit the Site, we automatically collect certain information about your device, including information about your IP address. 
                  BibleFunClub.com may set and access BibleFunClub.com 'cookies' on your computer. These are used to remember your preferences and login name.
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Use of Data
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                 We use the data to identify you and remember your login session, to provide and maintain our Service, to notify you about changes to our Service, and to monitor usage of our Service.
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Your Rights
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  <span> 
                    You have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. 
                    If you would like to exercise this right, please contact us.
                  </span>
                </p>
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Policy Update
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                </p> 
                <br/>
                <h4 style={{fontFamily: 'Luckiest Guy'}} className="my-2 text-2xl leading-8 font-sans heading tracking-wide text-gray-900 sm:text-2xl">
                  Contact Us
                </h4>
                <p className="ml-4 mt-2 lesson-text text-justify text-lg text-gray-800">
                  <span>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us 
                  </span> 
                  <span>
                    <a onClick={(e) => window.location.pathname = "/contact-us" } class="link-text"> here</a>
                  </span>
                </p> 
                <br/>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default connect() (PrivacyPolicy);