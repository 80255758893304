import {
  MAZE_HEAD_UPDATE, 
  MAZE_PIC_UPDATE, 
  MAZE_SHEEP_UPDATE
  } from '../actions/types.js';

const initialState = {
  head: { 
    x: 7, 
    y: 0
  },
  pic: { 
    x: 0, 
    y: 0
  }, 
  sheep: { 
    x: 0, 
    y: 0
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MAZE_HEAD_UPDATE:
      return {...state, head: { ...state.head, x: action.x, y: action.y }}
    case MAZE_PIC_UPDATE:
      return {...state, pic: { ...state.pic, [action.payload.prop]: action.payload.value }}
    case MAZE_SHEEP_UPDATE:
      return {...state, sheep: { ...state.sheep, x: action.x, y: action.y }}
    default:
      return state
  }
}
