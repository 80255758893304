import React, { Component } from 'react';
import { connect } from 'react-redux';

import AvatarFaceElement from '../../elements/AvatarFaceElement';
import AvatarAssetElement from '../../elements/AvatarAssetElement';

import '../../stylesheets/userSettings.css';

class AvatarSettings extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
      activeTab: 2
    };
  }
  
  onToggleTab = (index) => { 
    this.setState({activeTab: index})
  }

  getActiveTabClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-tabs"
    }
    return "inactive-avatar-tabs"
  }

  getActiveContentClass = (index) => { 
    if(this.state.activeTab == index){ 
      return "active-avatar-content"
    }
    return ""
  }

  clickCallBack(item){
    if(!this.props.activeUser){ 
      return this.props.handleInactiveUserClick.bind(this.props.parent)
    }
    if(item.id != this.profileItem(item).id){ 
      return this.props.handleSelectItem.bind(this.props.parent, item)
    }
  }

  assetLocked(ownAsset){ 
    if(!this.props.activeUser){ return false }
    return !ownAsset
  }

  avatarFacesItem(){
    if(this.props.avatarFaces.length && this.props.avatarSkins.length){
      return(
        <ul role="list" className="flex gap-2 flex-wrap">
          {this.props.avatarFaces.map((face) => (
            <li key={face.image} className="relative">
              <AvatarFaceElement
                buttonClass = { this.buttonClass(face) }
                handleClick = {this.clickCallBack(face)}
                head = {this.props.avatarSkins[0].head}
                face = { face.image }
                locked = { this.assetLocked(this.props.avatarAssets.faces.includes(face.id)) }
              />
            </li>
          ))}
        </ul>
      )
    }
  } 

  avatarHairsItem(){
    if(this.props.avatarHairs.length){
      return(
        <ul role="list" className="flex gap-2 flex-wrap">
          {this.props.avatarHairs.map((hair) => (
            <li key={hair.image} className="relative">
              <AvatarAssetElement
                buttonClass = { this.buttonClass(hair) }
                handleClick = {this.clickCallBack(hair)}
                image = { hair.image }
                locked = { this.assetLocked(this.props.avatarAssets.heads.includes(hair.id)) }
              />
            </li>
          ))}
        </ul>
      )
    }
  } 

  avatarHeadwearsItem(){
    if(this.props.avatarHeadwears.length){
      return(
        <ul role="list" className="flex gap-2 flex-wrap">
          {this.props.avatarHeadwears.map((headwear) => (
            <li key={headwear.image} className="relative">
              <AvatarAssetElement
                buttonClass = { this.buttonClass(headwear) }
                handleClick = {this.clickCallBack(headwear)}
                image = { headwear.image }
                locked = { this.assetLocked(this.props.avatarAssets.heads.includes(headwear.id)) }
              />
            </li>
          ))}
        </ul>
      )
    }
  } 

   avatarShirtsItem(){
    if(this.props.avatarShirts.length){
      return(
        <ul role="list" className="flex gap-2 flex-wrap">
          {this.props.avatarShirts.map((shirt) => (
            <li key={shirt.image} className="relative">
              <AvatarAssetElement
                buttonClass = { this.buttonClass(shirt) }
                handleClick = {this.clickCallBack(shirt)}
                image = { shirt.image }
                locked = { this.assetLocked(this.props.avatarAssets.shirts.includes(shirt.id)) }
              />
            </li>
          ))}
        </ul>
      )
    }
  } 

  avatarSkinsItem(){
    if(this.props.avatarSkins.length){
      return(
        <ul role="list" className="flex gap-2 flex-wrap">
          {this.props.avatarSkins.map((skin) => (
            <li key={skin.head} className="relative">
              <AvatarAssetElement
                buttonClass = { this.buttonClass(skin) }
                handleClick = {this.clickCallBack(skin)}
                image = { skin.head }
              />
            </li>
          ))}
        </ul>
      )
    }
  } 

  buttonClass(item){ 
    if(!this.props.activeUser){ 
      return "bg-white hover:border-1 hover:border-secondary-dark hover:opacity-75"
    }
    if(item.id == this.profileItem(item).id){ 
      return "bg-white cursor-default border-2 md:border-4 border-secondary-yellow"
    }
    // For skin, all are accessible
    if(item.type =="skin" || this.avatarAssetList(item).includes(item.id) ){ 
      return "bg-white hover:border-1 hover:border-secondary-dark hover:opacity-75"
    }
    else{
      return "bg-gray-300 hover:border-1 hover:border-secondary-dark opacity-75"
    }
  }

  profileItem(item){ 
    if(item.type == "skin"){ return this.props.profile.avatar_skin }
    else if(item.type == "face"){ return this.props.profile.avatar_face }
    else if(item.type == "head"){ return this.props.profile.avatar_head }
    else { return this.props.profile.avatar_shirt }
  }

  avatarAssetList(item){ 
    if(item.type == "shirt"){ return this.props.avatarAssets.shirts }
    else if(item.type == "face"){ return this.props.avatarAssets.faces }
    else if(item.type == "head"){ return this.props.avatarAssets.heads }
    else { return this.props.avatarAssets.skins }
  }

  render(){
    return(
      <div className="sm:px-1 lg:px-8 ">
        <div className="flex px-1 md:px-0 gap-x-1 md:gap-x-4 avatar-tab-list">
          <div className={`md:ml-0 w-full avatar-tabs ${this.getActiveTabClass(1)}`}
            onClick={() => this.onToggleTab(1)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Skin </h3>
            </div>
            <div className="mt-0"> 
              <img className="p-1 m-auto h-12 md:h-16" src="/images/ui_assets/avatar_skin.webp" alt="i1"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs flex flex-col ${this.getActiveTabClass(2)}`}
            onClick={() => this.onToggleTab(2)}
          >  
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-bold leading-10 tracking-wider text-white">Face </h3>
            </div>
            <div className="mt-0"> 
              <img className="p-1 m-auto h-12 md:h-16" src="/images/ui_assets/avatar_face.webp" alt="i2"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs ${this.getActiveTabClass(3)}`}
            onClick={() => this.onToggleTab(3)}
          >
            <div className="-mt-1 sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Hair </h3>
            </div>
            <div className="mt-0"> 
              <img className="p-1 m-auto h-12 md:h-16" src="/images/ui_assets/avatar_hair.webp" alt="i3"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs ${this.getActiveTabClass(4)}`}
            onClick={() => this.onToggleTab(4)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Hat </h3>
            </div>
            <div className="mt-0"> 
              <img className="p-1 m-auto h-12 md:h-16" src="/images/ui_assets/avatar_hat.webp" alt="i4"/>
            </div>
          </div>
          <div className={`md:ml-0 w-full avatar-tabs ${this.getActiveTabClass(5)}`}
            onClick={() => this.onToggleTab(5)}
          >
            <div className="-mt-1  sm:mt-2 h-6 my-auto">
              <h3 style={{fontFamily: 'Luckiest Guy'}}  className="text-center text-lg md:text-xl font-medium leading-10 tracking-wider text-white">Body </h3>
            </div>
            <div className="mt-0"> 
              <img className="p-1 m-auto h-12 md:h-16" src="/images/ui_assets/avatar_body.webp" alt="i5"/>
            </div>
          </div>
        </div>
        <div>
          <div className={`avatar-content ${this.getActiveContentClass(1)}`}>
            <div className="ml-1 md:ml-0 w-full py-5">
              {this.avatarSkinsItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(2)}`}>
            <div className="ml-1 md:ml-0 w-full py-5">
              {this.avatarFacesItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(3)}`}>
            <div className="ml-1 md:ml-0 w-full py-5">
              {this.avatarHairsItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(4)}`}>
            <div className="ml-1 md:ml-0 w-full py-5">
              {this.avatarHeadwearsItem()}
            </div>
          </div>
          <div className={`avatar-content ${this.getActiveContentClass(5)}`}>
            <div className="ml-1 md:ml-0 w-full py-5">
              {this.avatarShirtsItem()}
            </div>
          </div>
         
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
  }
};

export default connect(mapStateToProps, {
})(AvatarSettings);