import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';

import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon, CursorArrowRaysIcon, ClockIcon} from '@heroicons/react/24/outline'
import { spotDiffInit, spotDiffUpdate } from '../../actions/spotDiffActions';
import { buttonSize } from '../../helpers/GameHelper'
import { isMobile } from "react-device-detect";

import Item from "./SpotDifference/Item"
import GameRuleModal from "./Modals/GameRuleModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"
import FrameImage from '../../assets/games/top_frame.jpg'
import Thumbnail from '../../assets/games/spot_difference/daniel/1.jpg'
import PauseGif from '../../assets/animations/pause.gif'
import WellDoneGif from '../../assets/animations/well_done.gif'

import '../../stylesheets/gameSetting.css';

let timeLoop
const frameWidth = 1609
const frameRatio = 1609/152
const buttonRatio = 1609/120 

const actualBgWidth = 6000  
const actualBgHeight = 4000
const bgRatio = actualBgWidth/actualBgHeight 
const lookupPic = { "daniel": 0 }
const timeBonusScore = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
const itemsInfo = [ 
  [ 
    // lion head
    {
      left: 880, 
      top: 2259, 
      width: 450, 
      height: 550
    },  
    { // spider
      left: 1935, 
      top: 797, 
      width: 430, 
      height: 410,
    }, 
    // rock
    { 
      left: 2516, 
      top: 1850, 
      width: 445, 
      height: 600,
    },
    // stone
    { 
      left: 1822, 
      top: 3450, 
      width: 258, 
      height: 225,
    },
    // bone
    { 
      left: 584, 
      top: 3556, 
      width: 444, 
      height: 275,
    }, 
  ]
]
class SpotDiff extends React.Component {
  constructor(props) {
    super(props);
    var frameWidth = this.frameWidth(window.outerWidth)
    var bgWidth = this.bgWidth(window.outerWidth, window.outerHeight)
    var sizeRate = bgWidth/actualBgWidth
    var bgHeight = this.backgroundHeight(bgWidth)
    this.state = { 
      audioOn: true, 
      gameRule: true,
      wellDoneModal: false, 
      bgMusic: new Audio('/audio/background/fluffing_a_duck.mp3'), 
      windowWidth: window.outerWidth, 
      windowHeight: window.outerHeight, 
      frameWidth: frameWidth, 
      frameHeight: frameWidth /frameRatio, 
      bgWidth: bgWidth, 
      bgHeight: bgHeight, 
      sizeRate: sizeRate,
      pauseAnimation: PauseGif,
      score: 0, 
      bonusScore: 5, 
      error: false, 
      gameId: null, 
      gameState: "pending", 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      correctBuzzer: new Audio('/audio/correct_buzzer.mp3'),
      imageLoaded: 0,
      time: 0, 
      result: 0
    };
    this.refArray = [] 
    for(var i = 0; i < 10; i++ ){ 
      this.refArray.push(createRef())
    }
  }

  handleCorrectClick(index, e) {
    if(this.state.audioOn){ 
      this.state.correctBuzzer.currentTime = 0
      this.state.correctBuzzer.volume = 0.2
      this.state.correctBuzzer.play()
    }
    if(this.props.selectedIds.length + 1 == itemsInfo[this.state.gameId].length){ 
      var thisObject = this
      setTimeout(function(){ 
        thisObject.setState({gameState: "done"}) 
        thisObject.setState({wellDoneModal: true})
        thisObject.state.bgMusic.pause()
        thisObject.state.cheerAudio.play()
        clearInterval(timeLoop)
      }, 200)  
    }
    this.props.spotDiffUpdate(index, true)
    var score = this.state.error? 10 : this.state.bonusScore + 10
    let timeBonus = 0
    let time = this.state.time
    if(time < 50){ 
      timeBonus = timeBonusScore[parseInt(time/5)]
    }
    this.setState({score: this.state.score + score + timeBonus})
    this.setState({error: false, result: this.state.result + 1})
    this.setState({bonusScore: this.state.bonusScore + 5})
  }

  handleWrongClick(e){ 
    this.setState({bonusScore: 10})
    this.setState({error: true})
  }

  handleWindowSizeChange(){
    var bgWidth = this.bgWidth(window.outerWidth, window.outerHeight)
    var bgHeight = this.backgroundHeight(bgWidth)
    var frameWidth = this.frameWidth(window.outerWidth)
    var frameHeight = frameWidth /frameRatio
    var sizeRate = bgWidth/actualBgWidth
    this.setState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight });
    this.setState({ bgWidth: bgWidth, bgHeight:bgHeight });
    this.setState({ frameWidth: frameWidth, frameHeight: frameHeight, sizeRate: sizeRate})   
  };

  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }

  handlePause(){ 
    this.state.bgMusic.pause();
    this.setState({pause: true})    
  }
  handleUnpause(){ 
    if(this.state.audioOn){ 
      this.state.bgMusic.play();
    }
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleExit(){ 
    this.props.history.goBack()
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
  }
  handleRestart(){ 
    let game = this.props.match.params.game
    if(!this.props.match.params.game){ game = "daniel"}
    this.initItems(lookupPic[game])
    this.initStates()
    if(this.state.audioOn == true){ 
      this.state.bgMusic.play()
    }
  }

  handleStartPlay(){
    if(this.state.audioOn){
      this.state.bgMusic.volume = 0.3
      this.state.bgMusic.play() 
    } 
    this.setState({gameRule: false})
    this.initStates()
  }

  frameWidth(width){ 
    if(width > 1000){ 
      return 0.8 * 1000
    }
    return 0.9 * width
  }

  resultDisplay(){ 
    return `${this.state.result}/5`
  }

  timeDisplay(){ 
    var minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  initStates(){ 
    this.setState({gameState: "running"})
    this.setState({score: 0, bonusScore: 10, time: 0, result: 0})
    let thisObj = this
    timeLoop = setInterval(() => { 
      if(!thisObj.state.pause){ 
        if(thisObj.state.gameState == "running" && thisObj.state.time < 600000){
          thisObj.setState({time: thisObj.state.time + 1})
        }
      }
    }, 1000)
  }

  bgWidth(width, height){ 
    const windowRatio = width/height
    if(windowRatio > bgRatio){ 
      if(height < 1000){ 
        return (0.9 * height) * bgRatio
      }else{ 
        return (0.9 * 1000) * bgRatio
      }
    }
    return  0.95 * width
  }

  backgroundHeight(width){ 
    return (parseFloat(width) * 4 / 6)
  }

  componentDidMount() {
    let game = this.props.match.params.game
    if(!this.props.match.params.game){ game = "daniel"}
    var gameId = lookupPic[game]
    this.initItems(gameId)
    this.setState({gameId: gameId})
    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    window.addEventListener('blur', this.handlePause.bind(this));

    const images = [] 
    for(var i = 0; i < 2; i++ ){ 
      const img = new Image()
      img.src = require(`../../assets/games/spot_difference/daniel/${i+1}.jpg`);
      img.onload = () => {
        this.setState({ imageLoaded: this.state.imageLoaded + 1 });
      }
      images.push(img)
    } 
    // Load gif files 
    var thisObject = this
    setTimeout(function(){ 
      const img = new Image()
      img.src = WellDoneGif
      img.onload = () => {
        thisObject.setState({ imageLoaded: thisObject.state.imageLoaded + 1 });
      }
    }, 2000)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          game = "Spot The Difference"
          buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  initItems(gameId){ 
    var items = [] 
    var info = itemsInfo[gameId]
    for(var i = 0; i < info.length; i++ ){ 
      items.push({id: i, 
                  selected: false,  
                  left: info[i].left, 
                  top: info[i].top, 
                  width: info[i].width, 
                  height: info[i].height, 
                  })
    }
    this.props.spotDiffInit(items)
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  displayTime(){ 
    const minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  diffItems(){ 
    return this.props.items.map((item, index) => {
      return( 
        <Item 
          key={index} 
          refer={this.refArray[index]}
          item={item}
          picWidth={actualBgWidth/2 * this.state.sizeRate}
          sizeRate={this.state.sizeRate}
          clickHandler={this.handleCorrectClick.bind(this, index)}
        />
      )
    })
  }

  frameItem(){ 
    return( 
      <div className="grid justify-items-center ">
        <div ref={this.state.frameRef} 
          className="mx-auto my-auto"
          style={{ 
            position: 'relative',
            width: `${this.state.frameWidth}px`,
            height: `${this.state.frameHeight}px`,
          }}
        >
          {/* Actual Frame */}
          <div className="z-0 my-auto mx-auto" 
            style={{position: 'absolute', 
                top: `0px`,
                left: `0px`,
                width: `${this.state.frameWidth}px`,
                height: `${this.state.frameHeight}px`,
                backgroundImage: `url(${FrameImage})`,
                backgroundSize: `${this.state.frameWidth}px ${this.state.frameHeight}px`, 
                backgroundRepeat: 'no-repeat'
            }}
          >
            <button class="focus:outline-none fancy-round-button green"
              style={{position: 'relative', 
              top: `${10/frameWidth * this.state.frameWidth}px`,
                left: `${1340/frameWidth * this.state.frameWidth}px`,
                width: `${this.state.frameWidth/buttonRatio}px`,
                height: `${this.state.frameWidth/buttonRatio}px`,
              }}
              onClick={this.handleAudioToggle.bind(this)}                
            >
              {this.volumeIcon(this.state.frameWidth/buttonRatio)}
            </button>
            <button className="focus:outline-none fancy-round-button red"
              style={{position: 'relative', 
              top: `${10/frameWidth * this.state.frameWidth}px`,
              left: `${1355/frameWidth * this.state.frameWidth}px`,
              width: `${this.state.frameWidth/buttonRatio}px`,
              height: `${this.state.frameWidth/buttonRatio}px`,
            }}
              onClick={this.handlePause.bind(this)}
            >
              <p className="text-white font-black" 
                style={{height: `${100 * this.state.sizeRate}`, width: `${100 * this.state.sizeRate}`}} > 
                <PauseCircleIcon className="mx=auto"/>
              </p>
            </button>
            <div
              style = {{
                position: 'absolute',  
                top: `${12/frameWidth * this.state.frameWidth}px`,
                left: `${20/frameWidth * this.state.frameWidth}px`,
                width: `${this.state.frameWidth/4.5}px`,
                height: `${this.state.frameHeight}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="grid grid-cols-2">           
                <CursorArrowRaysIcon className="mx-auto text-white" 
                  style={{
                    width: `${this.state.frameWidth/9}px`,
                    height: `${this.state.frameHeight/2.5}px`,
                  }} 
                  aria-hidden="true"
                />
                <div>
                  <p className="text-right frame-font text-white unselectable"
                    style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                  >
                    {this.resultDisplay()}
                  </p>
                </div>
              </div>              
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${75/frameWidth * this.state.frameWidth}px`,
                left: `${20/frameWidth * this.state.frameWidth}px`,
                width: `${this.state.frameWidth/4.5}px`,
                height: `${this.state.frameHeight}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="grid grid-cols-2">  
                <ClockIcon className="mx-auto text-white pt-1" 
                  style={{
                    width: `${this.state.frameWidth/15}px`,
                    height: `${this.state.frameHeight/2.5}px`,
                  }} 
                  aria-hidden="true"
                />
                <div className="my-auto">
                  <div className="text-right frame-font text-white unselectable"
                    style = {{ lineHeight: `${0.35 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                  >
                   {this.timeDisplay()}
                  </div> 
                </div>
              </div>              
            </div> 
            {/* Score */}
            <div className="unselectable flex"
              style = {{
                position: 'absolute',    
                top: `${15/frameWidth * this.state.frameWidth}px`,
                left: `${670/frameWidth * this.state.frameWidth}px`,
                width: `${this.state.frameWidth/6}px`,
                height: `${this.state.frameHeight}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="h-full m-auto">
                <p className="text-center game-desc text-white"
                  style = {{ lineHeight: `${0.4 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
                >
                  Score  
                </p>  
              </div>      
            </div> 
            <div
              style = {{
                position: 'absolute',    
                top: `${80/frameWidth * this.state.frameWidth}px`,
                left: `${670/frameWidth * this.state.frameWidth}px`,
                width: `${this.state.frameWidth/6}px`,
                height: `${this.state.frameHeight}px`,
                display: 'inline-block', 
                overflow: 'hidden'}}
            > 
              <div className="text-center frame-font text-white unselectable"
                style = {{ lineHeight: `${0.4 * this.state.frameHeight}px`, fontSize: `${0.4 * this.state.frameHeight}px` }}
              >
                {this.state.score}
              </div>              
            </div> 
          </div>
        </div>  
      </div>
    )
  }

  pictureDiv(){ 
    return( 
      <div className="mx-auto mt-2 bg-black"
        style={{ 
          position: 'relative',
          width: `${this.state.bgWidth}px`,
          height: `${this.state.bgHeight}px`,
        }}
      >
        <div 
          className="z-0 border-t-2 border-l-2 border-b-2 border-r-2 border-black" 
          style = {{
            position: 'absolute',    
            top: `0`,
            left: `0`,
            width: `${this.state.bgWidth/2}px`, 
            height: `${this.state.bgHeight}px`,
          }}
          onClick={this.handleWrongClick.bind(this)}
        >
          <img className="unselectable object-cover" 
                src={require(`../../assets/games/spot_difference/daniel/1.jpg`)}  />
        </div> 

        <div 
          className="z-0 border-t-2 border-r-2 border-b-2 border-l-2 border-black" 
          style = {{
            position: 'absolute',    
            top: `0`,
            left: `${this.state.bgWidth/2}px`,
            width: `${this.state.bgWidth/2}px`, 
            height: `${this.state.bgHeight}px`,
          }}
          onClick={this.handleWrongClick.bind(this)}
        >
          <img className="unselectable object-cover"
                src={require(`../../assets/games/spot_difference/daniel/2.jpg`)}  />
        </div> 
        {this.diffItems()}
      </div>
    ) 
  }

  gameRuleModal(){ 
    return( 
      <GameRuleModal
        open = { this.state.gameRule }
        audioToggleHandler = { this.handleAudioToggle.bind(this)}
        startPlayHandler = { this.handleStartPlay.bind(this)}
        audio = { this.state.audioOn }
        thumbnail = { Thumbnail }
        description = { "" }
        instructions = { ["There are 5 differences between the two pictures.", "Can you find out all of them?"]}
      />
    )
  }

  doneDiv(){ 
    return(
      <div> 
        <div className="justify-items-center mt-2 grid grid-cols-2">
          <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
            onClick={(e) => window.location.href = "/games" } 
          >
            <span> 
              <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                style={{transform: "rotateY(180deg)"}} 
              />
            </span> 
            <span className="my-auto hidden md:block">
              Exit
            </span>
           
          </button>
          <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
            onClick={ this.handleRestart.bind(this) }
          >
            <span> 
              <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
            </span> 
            <span className="my-auto hidden md:block">
              Play Again
            </span>
          </button>
        </div>
      </div> 
    )
  }

  gameDiv(){ 
    if(this.state.gameState=="done" ){ 
      return( 
        <div>
          {this.pictureDiv()}
          {this.doneDiv()}
        </div> 
      ) 
    }
    return(
      <div> 
        {this.frameItem()}
        {this.pictureDiv()}
      </div>
    )
  }
  render() {
    return (
      <div> 
      {this.pauseModal()}
      {this.gameRuleModal()}
      {this.wellDoneModal()}
      <div className="bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-h-screen py-2 md:py-3">
        {this.gameDiv()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    items: state.spotDiff.items,
    selectedIds: state.spotDiff.selectedIds,
  }
};

export default connect(mapStateToProps, { 
  spotDiffInit, 
  spotDiffUpdate
}) (SpotDiff);