import {
    TOPIC_FETCH_SUCCESS, TOPIC_LIST_FETCH_SUCCESS, TOPIC_INIT
  } from '../actions/types.js';
  
  const INITIAL_STATE = {
    topic: {}, 
    topicList: [], 
    topicIndex: -1
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
      case TOPIC_INIT: 
        return { ...state, topic: state.topicList[action.index], topicIndex: action.index };  
      case TOPIC_FETCH_SUCCESS: 
        return { ...state, topic: action.payload };  
      case TOPIC_LIST_FETCH_SUCCESS: 
        return { ...state, topicList: action.payload };  
      default:
        return state;
    }
  };

