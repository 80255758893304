import React, { Component } from 'react';
import { connect } from 'react-redux';

import HomePage from '../pages/HomePage'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    }
  }

  onWindowSizeChange(){
    setTimeout(() => {
      this.setState({ width: window.innerWidth });
    }, 100) 
  };

  componentDidMount(){
    window.addEventListener('resize', this.onWindowSizeChange.bind(this));
  } 
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowSizeChange.bind(this));
  }

  render() {
    return( 
      <HomePage
        width = {this.state.width}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps, {
})(Home);