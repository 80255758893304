import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isMobile } from "react-device-detect";

import '../stylesheets/gameSetting.css';
import { LightBulbIcon, PlayIcon } from '@heroicons/react/24/outline'

function buttonTextClass(){ 
  if(isMobile){ 
    return "text-base"
  }
  return "text-1xl lg:text-2xl"
}

function paddingLeft() { 
  if(isMobile){ 
    return '45px'
  }
  return '70px'
}

function paddingTop() { 
  if(isMobile){ 
    return '5px'
  }
  return '25px'
}

function buttonsItem(props, cancelButtonRef){ 
  if(props.hint){ 
    return( 
      <div className="bg-primary-dark px-2 pb-4 md:px-4">
        <div className="justify-items-center grid grid-flow-row-dense grid-cols-2 gap-3">  
          <button className="w-24 md:w-36 fancy-button orange"
            onClick={props.hintHandler}  
            onTouchStart={props.hintHandler}  
          >
            <div className="flex justify-left md:justify-center"> 
              <div className="flex flex-row">
                <div>
                  <LightBulbIcon className="ml-0 md:-ml-2 h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                </div>
                <div className="ml-2 my-auto hidden md:block">
                  Hint
                </div>
              </div>
            </div>
          </button>

          <button className="w-24 md:w-36 fancy-button green"
            ref={cancelButtonRef}
            onClick={props.clickHandler.bind(this)}   
            onTouchStart={props.clickHandler.bind(this)}   
          >
            <div className="flex justify-left md:justify-center"> 
              <div className="flex flex-row">
                <div>
                  <PlayIcon className="ml-0 md:-ml-2 h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
                </div>
                <div className="ml-2 my-auto hidden md:block">
                  Retry
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    )
  }else{ 
    return(
      <div className="my-auto mx-auto justify-items-center grid grid-cols-1 pb-4"> 
        <button className="w-24 md:w-36 fancy-button green"
          ref={cancelButtonRef}
          onClick={props.clickHandler.bind(this)}   
          onTouchStart={props.clickHandler.bind(this)}   
        >
          <div className="flex justify-left md:justify-center"> 
            <div className="flex flex-row">
              <div>
                <PlayIcon className="ml-0 md:-ml-2 h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
              </div>
              <div className="ml-2 my-auto hidden md:block">
                Continue
              </div>
            </div>
          </div>
        </button>
      </div>
    )
  }
}


export default function Notification(props) {
  const cancelButtonRef = useRef()
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.open}
        onClose={props.clickHandler}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="bg-primary-dark inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle w-full sm:w-1/2 max-w-md">
              <div className = "pt-4 px-4"> 
                <div className= "relative grid grid-cols-2">
                  <div className="col-start-1"> 
                    <img src={props.gifImage} />
                  </div>
                  <div className="-ml-4 absolute z-10 col-start-2">
                    <img src="/games/images/paint_the_world/chat_bubble.webp" />  
                  </div>
                  <div className="absolute z-10 col-start-2">
                    <div className="my-auto ml-6 md:ml-8 pt-8"> 
                      <p className={`mb-2 game-desc text-white text-base sm:text-xl md:text-2xl}`}>
                        {props.title} 
                      </p>  
                      {props.description.map((description)=>( 
                        <div className="mt-2">
                          <p className={`game-desc text-white text-sm md:text-lg lg:text-xl}`}>
                            {description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {buttonsItem(props, cancelButtonRef)}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}