import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from '../../elements/Footer'
import NavBar from '../../elements/NavBar'

import { gameListFetch } from '../../actions/gameActions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class FindOuts extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.gameListFetch("find_out");
  }

  thumbnail(game){ 
    return `/images/games/find_out/${game.category_thumbnail}/rule_thumb.webp`
  }

  content(){ 
    if(this.props.gameList.length){ 
      return(
        <div className="lg:mx-auto lg:max-w-7xl bg-primary-color flex flex-col">
          <div className="min-h-0 flex-1 flex">
            <div className="w-full mx-auto px-2 py-2 sm:px-6 lg:px-8 sm:py-5">
              <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {this.props.gameList.map((game) => (
                  <li key={game.url} className="relative">
                    <div className="bg-white aspect-square flex h-full items-stretch flex-col rounded-lg cursor-pointer hover:opacity-75"
                      onClick={(e) => window.location.href = `/games/${game.url}` }
                    >
                      <div > 
                        <img src={this.thumbnail(game)} alt="" className="rounded-t-lg object-contain pointer-events-none" />
                      </div> 
                      <div className ="relative m-auto w-full"
                      > 
                        <div className="flex text-center">
                          <div className="m-auto">
                            <h2 style={{fontFamily: 'Luckiest Guy'}} className="lg:px-4 mt-1 leading-4 text-1xl text-primary-dark font-extrabold font-sans tracking-wide md:text-2xl lg:text-3xl"> 
                              { game.name }
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div> 
      )
    }
  }

  render() {
    return (
      <>
        <div className="min-h-screen bg-primary-color"> 
          <NavBar/>
          <div className="text-center mx-auto py-2 sm:py-5">
            <h2 style={{fontFamily: 'Luckiest Guy'}} className="text-3xl text-primary-dark font-extrabold font-sans tracking-wide sm:text-5xl">
              Find Out
            </h2>
          </div>
          {this.content()}   	
        </div>
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    gameList: state.game.gameList
  }
};

export default connect(mapStateToProps, {
  gameListFetch
})(FindOuts);