import { FIND_OUT_INIT, FIND_OUT_UPDATE } from './types';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export const findOutUpdate = (index, value) => {
  return {
    type: FIND_OUT_UPDATE,
    index: index,
    value: value
  }
};

export const findOutInit = (isSelectedArray) => {
  return {
    type: FIND_OUT_INIT,
    isSelectedArray: isSelectedArray
  }
};
