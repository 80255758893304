import React, { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { SpeakerXMarkIcon, SpeakerWaveIcon, ArrowRightOnRectangleIcon, ArrowPathIcon, PauseCircleIcon } from '@heroicons/react/24/outline'
import { mazeHeadUpdate, mazePicUpdate, mazeSheepUpdate } from '../../actions/lostSheepActions';
import { mazeGenerator, placeSheep } from './LostSheep/MazeGenerator'

import GameRuleModal from "./Modals/GameRuleModal"
import PauseModal from "./Modals/PauseModal"
import WellDoneModal from "./Modals/WellDoneModal"
import FrameImage from '../../assets/games/top_frame.png'
import PauseGif from '../../assets/animations/pause.gif'
import WellDoneGif from '../../assets/animations/well_done.gif'
import Maze from '../../assets/games/lost_sheep/maze.png'
import Head1 from '../../assets/games/lost_sheep/head1.png'
import Head2 from '../../assets/games/lost_sheep/head2.png'
import Sheep  from '../../assets/games/lost_sheep/sheep.png'
import Top from '../../assets/games/lost_sheep/top.png'
import Right from '../../assets/games/lost_sheep/right.png'
import Bottom from '../../assets/games/lost_sheep/bottom.png'
import Left  from '../../assets/games/lost_sheep/left.png'
import Tile from '../../assets/games/lost_sheep/tile.jpg'
import Control from '../../assets/games/lost_sheep/control.png'

import { buttonSize } from '../../helpers/GameHelper'
import '../../stylesheets/lostSheep.css';

const frameRatio = 1763/166
const colPerPage = 12
const rowPerPage = 12
const bgRatio = colPerPage/rowPerPage  
const actualBgWidth = 6000  
const actualBgHeight = 4000
const actualControlSize = 1080

let stepLoop 
let maze

class LostSheep extends React.Component {
  constructor(props) {
    super(props);
    const frameWidth = this.frameWidth(window.innerWidth)
    console.log(`window ${window.innerWidth} ${window.innerHeight}`)
    const landscape = window.innerWidth/window.innerHeight > 1
    const bgWidth = this.bgWidth(window.innerWidth, window.innerHeight, landscape)
    const sizeRate = bgWidth/actualBgWidth
    const bgHeight = this.bgHeight(bgWidth)
    const frameHeight = 166/1763 * bgWidth
    const displayWidth = this.displayWidth(bgWidth, landscape)
    const displayHeight = this.displayHeight(bgHeight, landscape)
    const mazePage = 2
    const stepSize = bgWidth/colPerPage
    const controlRate = stepSize * 4/actualControlSize

    this.state = { 
      audioOn: true, 
      gameRule: true,
      wellDoneModal: false, 
      bgMusic: new Audio('/audio/background/fluffing_a_duck.mp3'), 
      windowWidth: window.innerWidth, 
      windowHeight: window.innerHeight, 
      frameWidth: frameWidth, 
      frameHeight: frameWidth /frameRatio, 
      bgWidth: bgWidth, 
      bgHeight: bgHeight, 
      displayWidth: displayWidth, 
      displayHeight: displayHeight, 
      sizeRate: sizeRate,
      pauseAnimation: PauseGif,
      score: 0, 
      bonusScore: 5, 
      error: false, 
      gameId: null, 
      gameState: "pending", 
      cheerAudio: new Audio('/audio/cheers.mp3'),
      imageLoaded: 0,
      time: 0, 
      direction: null,
      stepSize: stepSize,
      controlSize: stepSize * 4, 
      totalCols: colPerPage * mazePage + 1, 
      totalRows: rowPerPage * mazePage + 1,
      mazePage: mazePage, 
      landscape: landscape, 
      controlRate: controlRate, 
      autoDirection: null, 
      found: false, 
      mazeRef: createRef(),
      showCtrl: true, 
    };
    this.mazeRef = createRef()
    this.maze = []
  }

  handleContextMenu(e){ 
    e.preventDefault()
  }

  handleKeyDown(e) {
    var direction = null

    if(this.state.pause){ 
      return
    }
    if(this.state.direction){return}
    console.log(`keyDown ${e.keyCode}`)
    if (e.keyCode == '38') {
      direction = "up"
    }
    else if (e.keyCode == '40') {
      direction = "down"
    }
    else if (e.keyCode == '37') {
      direction = "left"
    }
    else if (e.keyCode == '39') {
      direction = "right"
    }
    this.processKeyDown(direction)
  }

  processKeyDown(direction){ 
    this.moveHead(direction, this, 1)
    var thisObj = this
    stepLoop = setInterval(thisObj.moveHead, 350, direction, thisObj, 1) 
    this.setState({showCtrl: false})
  }

  handleKeyUp(e) {
    console.log("handleKeyUp")
    this.setState({direction: null})
    clearInterval(stepLoop);
    const thisObj = this
  }

  handleTouchStart(row, col, e){ 
    //const x = e.touches[0].clientX
    //const y = e.touches[0].clientY
    this.setState({touchStart: true})
    console.log(`touchStart row: ${row} col: ${col}`)
    let direction, steps;
    [direction, steps] = this.checkDirectionSteps(this.maze, row, col)
    console.log(`touchStart direction:${direction}`)
    if(direction){ 
      this.moveHead(direction, this, steps)
      this.setState({showCtrl: false})
    }   
  }

  handleMouseDown(row, col, e){ 
    console.log(`mouseDown row:${row} col:${col}`)
    let direction, steps;
    [direction, steps] = this.checkDirectionSteps(this.maze, row, col)
    console.log(`touchStart direction:${direction}`)
    if(direction){ 
      this.moveHead(direction, this, steps)
      this.setState({showCtrl: false})
    }   
  }

  handleWindowSizeChange(){
    const landscape = window.innerWidth/window.innerHeight > 1
    const bgWidth = this.bgWidth(window.innerWidth, window.innerHeight, landscape)
    const bgHeight = this.bgHeight(bgWidth)
    const frameWidth = this.frameWidth(window.innerWidth)
    const frameHeight = 166/1763 * bgWidth
    const sizeRate = bgWidth/actualBgWidth
    const displayWidth = this.displayWidth(bgWidth, landscape)
    const displayHeight = this.displayHeight(bgHeight, landscape)
    const stepSize = bgWidth/colPerPage
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.setState({ bgWidth: bgWidth, bgHeight:bgHeight, landscape: landscape });
    this.setState({ displayWidth: displayWidth, displayHeight:displayHeight});
    this.setState({ frameWidth: frameWidth, frameHeight: frameWidth /frameRatio, sizeRate: sizeRate})   
    this.setState({ stepSize: stepSize, controlSize: stepSize * 4, controlRate: stepSize * 4/actualControlSize })
  };

  handleAudioToggle(){ 
    var audioOn = !this.state.audioOn
    this.setState({audioOn: audioOn })
    if(audioOn == true){ 
      this.state.bgMusic.play()
    }else{ 
      this.state.bgMusic.pause()
    }
  }

  handlePause(){ 
    this.setState({pause: true})    
  }

  handleUnpause(){ 
    this.setState({pauseAnimation: ""})
    setTimeout(() => { 
      this.setState({pause: false})
      this.setState({pauseAnimation: PauseGif})
    }, 0)
  } 

  handleExit(){ 
    this.props.history.goBack()
  }

  handleWellDoneComplete(){ 
    this.setState({wellDoneModal: false})
    this.state.cheerAudio.pause()
  }
  handleRestart(){ 
    this.initStates()
    if(this.state.audioOn == true){ 
      this.state.bgMusic.play()
    }
  }

  handleStartPlay(){
    if(this.state.audioOn){
      this.state.bgMusic.play() 
    } 
    this.setState({gameRule: false})
  }

  moveHead(direction, thisObj, steps){ 
    if(direction == null){ 
      return
    }
    var nextStep = []
    if(direction == "up"){ 
      nextStep = [ thisObj.props.mazeHead.x, thisObj.props.mazeHead.y - steps]
      //thisObj.props.mazeHeadUpdate({ prop: "y", value: thisObj.props.mazeHead.y - 1 })
    }
    else if(direction == "down"){
      nextStep = [ thisObj.props.mazeHead.x, thisObj.props.mazeHead.y + steps]
      //thisObj.props.mazeHeadUpdate({ prop: "y", value: thisObj.props.mazeHead.y + 1 })
    } 
    else if(direction == "left"){
      nextStep = [ thisObj.props.mazeHead.x - steps, thisObj.props.mazeHead.y]
      //thisObj.props.mazeHeadUpdate({ prop: "x", value: thisObj.props.mazeHead.x - 1 })
    } 
     else if(direction == "right"){
      nextStep = [ thisObj.props.mazeHead.x + steps, thisObj.props.mazeHead.y]
      //thisObj.props.mazeHeadUpdate({ prop: "x", value: thisObj.props.mazeHead.x + 1 })
    } 
    if(thisObj.checkValidSlot(thisObj.maze, nextStep[1], nextStep[0])){ 
      thisObj.moveMazePic(thisObj, direction, nextStep[1], nextStep[0], 1)
      thisObj.props.mazeHeadUpdate(nextStep[0], nextStep[1])
      thisObj.setState({direction: direction})
      console.log(`headSheep nextStep: ${nextStep[0]} ${nextStep[1]} ${direction}`)
      if(nextStep[0] == thisObj.props.mazeSheep.x && nextStep[1] == thisObj.props.mazeSheep.y){ 
        setTimeout(function(){ 
          thisObj.setState({found: true})
        }, 500)
      }
    }
  }

  checkDirectionSteps(maze, r, c){ 
    if(this.props.mazeHead.x == c){ 
      if( r > this.props.mazeHead.y ){ 
        const steps = this.calculateColSteps(maze, c, this.props.mazeHead.y, r)
        return ["down", steps]
      }else{ 
        const steps = this.calculateColSteps(maze, c, r, this.props.mazeHead.y)
        return ["up", steps]
      }
    }
    else if(this.props.mazeHead.y == r){ 
      if( c > this.props.mazeHead.x ){
        const steps = this.calculateRowSteps(maze, r, this.props.mazeHead.x, c)
        return ["right", steps]
      }else{ 
        const steps = this.calculateRowSteps(maze, r, c, this.props.mazeHead.x)
        return ["left", steps]
      }
    }
    return null
  }

  calculateRowSteps(maze, r, fromC, toC){ 
    for(var i = fromC; i <= toC; i++ ){ 
      if(!this.checkValidSlot(maze, r, i)){ return i - 1 - fromC }
    }
    return toC - fromC
  }

  calculateColSteps(maze, c, fromR, toR){ 
    for(var i = fromR; i <= toR; i++ ){ 
      if(!this.checkValidSlot(maze, i, c)){ return i - 1 - fromR }
    }
    return toR - fromR
  }

  checkValidSlot(maze, r, c){ 
    if(maze[r][c].includes("wall") || (typeof maze[r] == "undefined") || (typeof maze[r][c] == "undefined")){ 
      return false
    }
    return true
  }

  moveMazePic(thisObj, direction, r, c, steps){ 
    const maze = thisObj.maze
    var offset = 0 
    var lastPageRow = thisObj.state.totalRows - rowPerPage
    var lastPageCol = thisObj.state.totalCols - colPerPage
    var value = 0 

    console.log(`moveMazePic:direction: ${direction}`)
    switch(direction){ 
      case "up": 
        if(thisObj.props.mazePic.y < 0){ 
          offset = 4 - (r + thisObj.props.mazePic.y + steps)
          console.log(`r:${r} picY:${thisObj.props.mazePic.y} rowpPage:${rowPerPage} totalRows:${thisObj.state.totalRows} offset: ${offset}`)
          if(offset > 0){
            value = thisObj.props.mazePic.y + steps + offset
            console.log(` value: ${value} `)
            if(value > 0){ 
              value = 0
            }
            thisObj.props.mazePicUpdate({ prop: "y", value: value})
          }
        } 
        break; 
      case "down": 
        console.log(`picY:${thisObj.props.mazePic.y} lastPageRow:${lastPageRow}`)
        if(thisObj.props.mazePic.y > (-1*(lastPageRow))){ 
          offset = r + thisObj.props.mazePic.y - rowPerPage + 4  
          console.log(`r:${r} picY:${thisObj.props.mazePic.y} rowpPage:${rowPerPage} totalRows:${thisObj.state.totalRows} offset: ${offset}`)
          if(offset > 0){    
            value = thisObj.props.mazePic.y - steps - offset 
            console.log(` value: ${value} `)
            if(value < -1 * (lastPageRow)){ value =  -1 * (lastPageRow)}
            thisObj.props.mazePicUpdate({ prop: "y", value: value})
          }
        }
        break; 
      case "left":
        if(thisObj.props.mazePic.x < 0){ 
          offset = 4 - (c + thisObj.props.mazePic.x + steps)
          if(offset > 0){
            value = thisObj.props.mazePic.x + steps + offset           
            if(value > 0){ 
              value = 0
            }
            thisObj.props.mazePicUpdate({ prop: "x", value: value})
          }
        } 
        break;  
      case "right":
        if(thisObj.props.mazePic.x > (-1*(lastPageRow))){ 
          offset = c + thisObj.props.mazePic.x - colPerPage + 4     
          if(offset > 0){    
            value = thisObj.props.mazePic.x - steps - offset 
            if(value < -1 * (lastPageCol)){ value =  -1 * (lastPageCol)}
            thisObj.props.mazePicUpdate({ prop: "x", value: value})
          }
        }
        break; 
      default: 

    }
  }


  frameWidth(width){ 
    if(width > 1000){ 
      return 0.7 * 1000
    }
    return 0.9 * width
  }

  gameOver(){ 

  }

  initStates(){ 
    this.setState({gameState: "running"})
    this.setState({score: 0})
    this.setState({bonusScore: 10})
  }

  bgWidth(width, height, landscape){ 
    if(landscape){ 
      if(height < 1000){ 
        return (0.7 * height) * bgRatio
      }else{ 
        return (0.7 * 1000) * bgRatio
      }
    }else{ 
      if(height/width < 1.5){ 
        return 0.9 * width
      }
      return width
    }
  }

  bgHeight(width){ 
    return (parseFloat(width)/ bgRatio)
  }

  displayWidth(bgWidth, landscape){ 
    // landscape
    console.log(`landscape: ${landscape}`)
    if(landscape){ 
      return bgWidth * 4/3
    }
    return bgWidth
  }

  displayHeight(bgHeight, landscape){ 
    // landscape
    if(landscape){ 
      return bgHeight 
    }
    return bgHeight * 4/3
  }

  buttonTop(){ 
    return (this.state.frameHeight - 146/1763 * this.state.frameWidth)/2
  }

  updateSheepLocation(maze){ 
    maze.forEach((row, r) => {
      row.forEach((cell, c) => {
        if(maze[r][c].includes("sheep")){ 
          this.props.mazeSheepUpdate(c, r)
        }
      }) 
    })
  }

  componentDidMount() {
    const cols = this.state.totalCols
    const rows = this.state.totalRows
    const width = (cols -1)/2
    const height = (rows -1)/2
    let fr, fc

    this.state.bgMusic.addEventListener('ended', () => this.state.bgMusic.play())
    const maze = mazeGenerator(width, height) 
    console.log(`cols ${cols} rows: ${rows}`)
    
    this.maze = placeSheep(maze, rows, cols)
    console.dir(this.maze)

    this.updateSheepLocation(maze)

    if(isMobile){ 
      window.addEventListener('contextmenu', this.handleContextMenu.bind(this)) 
      window.addEventListener('touchend', this.handleKeyUp.bind(this)) 
      window.addEventListener('touchleave', this.handleKeyUp.bind(this)) 
      //window.addEventListener('touchstart', this.handleTouchStart.bind(this))
    }else{ 
      window.addEventListener('keydown', this.handleKeyDown.bind(this));
      window.addEventListener('keyup', this.handleKeyUp.bind(this));
      window.addEventListener('mouseup', this.handleKeyUp.bind(this));
    }
    window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    
    // Load gif files 
    var thisObject = this
    setTimeout(function(){ 
      const img = new Image()
      img.src = WellDoneGif
      img.onload = () => {
        thisObject.setState({ imageLoaded: thisObject.state.imageLoaded + 1 });
      }
    }, 2000)  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
  
    if(isMobile){ 
      window.removeEventListener('contextmenu', this.handleContextMenu.bind(this)) 
    }else{ 
      window.removeEventListener('keydown', this.handleKeyDown.bind(this));
      window.removeEventListener('keyup', this.handleKeyUp.bind(this));
    }
  }

  pauseModal(){
    if(this.state.pause == true){ 
      return <PauseModal 
              buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
              pauseGif = {this.state.pauseAnimation}
              audioToggleHandler= {this.handleAudioToggle.bind(this)}
              unpauseHandler = {this.handleUnpause.bind(this)}
              restartHandler = {this.handleRestart.bind(this)}
              exitHandler = {this.handleExit.bind(this)}
              open = {this.state.pause}
              audio = {this.state.audioOn}

            />
    }
    return <div/>
  }

  wellDoneModal(){
    if(this.state.wellDoneModal==true){ 
      return( 
        <WellDoneModal
          open = { true }
          score = { this.state.score }
          game = "The Lost Sheep"
          buttonSize = {buttonSize(this.state.windowWidth, this.state.windowHeight)}
          endHandler = { this.handleWellDoneComplete.bind(this)}
        />
      )
    }
    return <div/>
  }

  volumeIcon(width){ 
    if(this.state.audioOn == true){ 
      return <SpeakerWaveIcon className="mx-auto text-white" style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }else{
      return <SpeakerXMarkIcon className="mx-auto text-white"  style={{height: `${width}px`, width: `${width}px`}} aria-hidden="true"/>
    }
  }

  displayTime(){ 
    const minute = parseInt(this.state.time/60) 
    var second = (this.state.time % 60) + ""
    if(second.length < 2){ 
      second = "0" + second
    }
    return `${minute}:${second}`
  }

  cellClass(cell){ 
    if(cell.length){ 
      return cell.join(" ")
    }
    return "path"
  }

  mazeRow(row, rowIndex, rowOffset, colOffset){ 
    if(rowIndex == 0){ 
      const top = (rowIndex + rowOffset) * this.state.stepSize
    }
    return row.map((cell, index) => { 
      return (
        <div className={ this.cellClass(cell)}
          style={{position: 'absolute', 
              top: `${(rowIndex + rowOffset) * this.state.stepSize}px`,
              left: `${(index + colOffset) * this.state.stepSize}px`,
              width: `${this.state.stepSize}px`,
              height: `${this.state.stepSize}px`,
              backgroundSize: `${this.state.stepSize}px ${this.state.stepSize}px`, 
              backgroundRepeat: 'no-repeat'
          }}
          onTouchStart={isMobile? this.handleTouchStart.bind(this, rowIndex, index): undefined} 
          onMouseDown={isMobile? undefined: this.handleMouseDown.bind(this, rowIndex, index)} 
        >
        </div> 
      )
    })
  }

  mazeItem(){ 

    var maze = this.maze
    const offsetRow = this.props.mazePic.y
    const offsetCol = this.props.mazePic.x
    console.log(`mazeItem ${offsetRow} ${offsetCol}`)
    return maze.map((row, index) => {
      return(
        <div>
          {this.mazeRow(row, index, offsetRow, offsetCol)}
        </div> 
      )
    });
  }

  headSource(){ 
    if(this.state.found){ 
      return Head2
    }
    return Head1
  }
  
  controlItems(){ 
    if(this.state.showCtrl && this.maze.length && this.state.direction == null){ 
      return( 
        <div> 
          <img className="head-img object-center unselectable object-cover" 
          style = {{
            position: 'absolute',   
            left: `${(this.props.mazeHead.x + this.props.mazePic.x) * this.state.stepSize}px`, 
            top: `${(this.props.mazeHead.y -1 + this.props.mazePic.y) * this.state.stepSize}px`,
            width: `${this.state.stepSize}px`, 
            height: `${this.state.stepSize}px`,
            opacity: 0.5
          }}
          src={Top}  />
          <img className="head-img object-center unselectable object-cover" 
          style = {{
            position: 'absolute',   
            left: `${(this.props.mazeHead.x + 1 + this.props.mazePic.x) * this.state.stepSize}px`, 
            top: `${(this.props.mazeHead.y + this.props.mazePic.y) * this.state.stepSize}px`,
            width: `${this.state.stepSize}px`, 
            height: `${this.state.stepSize}px`,
            opacity: 0.5
          }}
          src={Right}  
            onTouchStart={isMobile? this.handleTouchStart.bind(this, 0, this.props.mazeHead.x + 1):undefined} 
            onMouseDown={isMobile? undefined: this.handleMouseDown.bind(this, 0, this.props.mazeHead.x + 1)} 
          />
          <img className="head-img object-center unselectable object-cover" 
          style = {{
            position: 'absolute',   
            left: `${(this.props.mazeHead.x + this.props.mazePic.x) * this.state.stepSize}px`, 
            top: `${(this.props.mazeHead.y + 1 + this.props.mazePic.y) * this.state.stepSize}px`,
            width: `${this.state.stepSize}px`, 
            height: `${this.state.stepSize}px`,
            opacity: 0.5
          }}
          src={Bottom}  
            onTouchStart={isMobile? this.handleTouchStart.bind(this, this.props.mazeHead.y + 1, this.props.mazeHead.x): undefined} 
            onMouseDown={isMobile?  undefined: this.handleMouseDown.bind(this, this.props.mazeHead.y + 1, this.props.mazeHead.x)} 
          />
          <img className="head-img object-center unselectable object-cover" 
            style = {{
              position: 'absolute',   
              left: `${(this.props.mazeHead.x - 1 + this.props.mazePic.x) * this.state.stepSize}px`, 
              top: `${(this.props.mazeHead.y + this.props.mazePic.y) * this.state.stepSize}px`,
              width: `${this.state.stepSize}px`, 
              height: `${this.state.stepSize}px`,
              opacity: 0.5
            }}
          src={Left}  
          onTouchStart={isMobile? this.handleTouchStart.bind(this, this.props.mazeHead.y, this.props.mazeHead.x - 1): undefined} 
          onMouseDown={isMobile? undefined: this.handleMouseDown.bind(this, this.props.mazeHead.y, this.props.mazeHead.x - 1)} 
          />
        </div> 
      )
    }
  }

  headItem(){ 
    return(
      <img className="head-img object-center unselectable object-cover" 
        style = {{
        position: 'absolute',   
          left: `${(this.props.mazeHead.x + this.props.mazePic.x) * this.state.stepSize}px`, 
          top: `${(this.props.mazeHead.y + this.props.mazePic.y) * this.state.stepSize}px`,
          width: `${this.state.stepSize}px`, 
          height: `${this.state.stepSize}px`,
        }}
      src={this.headSource()}  />
    )
  }

  sheepItem(){ 
    if(!this.state.found){ 
      return( 
         <img className="head-img object-center unselectable object-cover" 
            style = {{
            position: 'absolute',   
              left: `${(this.props.mazeSheep.x + this.props.mazePic.x) * this.state.stepSize}px`, 
              top: `${(this.props.mazeSheep.y + this.props.mazePic.y) * this.state.stepSize}px`,
              width: `${this.state.stepSize}px`, 
              height: `${this.state.stepSize}px`,
            }}
          src={Sheep}  
          onTouchStart={isMobile? this.handleTouchStart.bind(this, this.props.mazeSheep.y, this.props.mazeSheep.x): undefined} 
          onMouseDown={isMobile? undefined: this.handleMouseDown.bind(this, this.props.mazeSheep.y, this.props.mazeSheep.x)} 
          />
      )
    }
  }
  headSheepItem(){ 
    return( 
      <div> 
        {this.headItem()}
        {this.controlItems()}
        {this.sheepItem()}
      </div>
    )
  }

  frameItem(){ 
    return( 
      <div ref={this.state.frameRef} 
        className="mx-auto my-auto"
        style={{ 
          position: 'relative',
          width: `${this.state.frameWidth}px`,
          height: `${this.state.frameHeight}px`,
        }}
      >
        {/* Actual Frame */}
        <div className="z-0 my-auto mx-auto" 
          style={{position: 'absolute', 
              top: `0px`,
              left: `0px`,
              width: `${this.state.frameWidth}px`,
              height: `${this.state.frameHeight}px`,
              backgroundImage: `url(${FrameImage})`,
              backgroundSize: `${this.state.frameWidth}px ${this.state.frameHeight}px`, 
              backgroundRepeat: 'no-repeat'
          }}
        >
          {/* Buttons */}
          <button className="focus:outline-none fancy-round-button my-auto red"
            style={{position: 'absolute', 
              top: `${this.buttonTop()}px`,
              left: `${1105/1763 * this.state.frameWidth}px`,
              width: `${135/1763 * this.state.frameWidth}px`,
              height: `${135/1763 * this.state.frameWidth}px`,
            }}
            onClick={isMobile? undefined: this.handlePause.bind(this)}   
            onTouchStart={isMobile? this.handlePause.bind(this): undefined}   
          >
            <p className="text-white font-black">
              <PauseCircleIcon className="mx=auto"/>
            </p>
          </button>
          <button className="focus:outline-none fancy-round-button green"
            style={{position: 'absolute', 
              top:  `${this.buttonTop()}px`,
              left: `${888/1763 * this.state.frameWidth}px`,
              width: `${135/1763 * this.state.frameWidth}px`,
              height: `${135/1763 * this.state.frameWidth}px`,
            }}   
            onClick={isMobile? undefined: this.handleAudioToggle.bind(this)}  
            onTouchStart={isMobile? this.handleAudioToggle.bind(this): undefined}               
          >
            {this.volumeIcon(135/1763 * this.state.frameWidth )}
          </button>
          
          {/* Score */}
          <div className="flex flex-col space-y-0"
            style = {{
              position: 'relative',    
              top: `0px`,
              left: `${1320/1763 * this.state.frameWidth}px`,
              width: `${293/1763 * this.state.frameWidth}px`,
              height: `${this.state.frameHeight}px`,
              display: 'inline-block', 
              overflow: 'hidden'}}
          > 
            <div className="my-auto">
              <p className="-mb-1 mr-3 text-right game-desc text-white"
                style = {{ fontSize: `${0.4 * this.state.frameHeight}px` }}
              >
                Score
              </p>
            </div>
            <div className="my-auto">
              <p className="mr-3 text-right game-desc text-white"
                style = {{ fontSize: `${0.3 * this.state.frameHeight}px` }}
              >
               {this.state.score}
              </p>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  displayClass(){ 
    if(this.state.landscape){ 
      return "grid-cols-10"
    }
    return "grid-rows-10 grid-cols-3"
  } 

  controlClass(){ 
    if(!this.state.landscape){ 
      return "col-start-2"
    }
  }

  pictureDiv(){ 
    return( 
      <div className={"mx-auto my-auto grid " + this.displayClass()}
        style={{ 
            position: 'relative',
            width: `${this.state.displayWidth}px`,
            height: `${this.state.displayHeight}px`,
          }}
      > 
        <div className="col-span-8 row-span-8 mt-2 border-2 border-black overflow-hidden"
          style={{ 
            position: 'relative',
            width: `${this.state.bgWidth}px`,
            height: `${this.state.bgHeight}px`,
          }}
        >
          <div ref={this.state.mazeRef} id="maze" className="maze-bg bg-black"
            style={{ 
              position: 'relative',
              width: `${this.state.stepSize * this.state.totalCols}px`,
              height: `${this.state.stepSize * this.state.totalRows}px`,
              backgroundImage: `url(${Tile})`,
              backgroundSize: `${this.state.bgWidth * this.state.mazePage}px ${this.state.bgHeight * this.state.mazePage}px`, 
              backgroundRepeat: 'repeat'
            }}
          > 
            {this.mazeItem()}
          </div>
          {this.headSheepItem()}
        </div> 
        <div className={"m-auto col-span-2 " + this.controlClass()}> 
          <div 
            style = {{
              position: 'relative', 
                width: `${this.state.controlSize}px`, 
                height: `${this.state.controlSize}px`,
              }}
          > 
            <div className="cursor-pointer"
              style={{position: 'absolute', 
                top: `${100 * this.state.controlRate}px`,
                left: `${410 * this.state.controlRate}px`,
                width: `${260 * this.state.controlRate}px`,
                height: `${260 * this.state.controlRate}px`,
              }}
              onMouseDown={isMobile? undefined: this.processKeyDown.bind(this, "up")}
              onTouchStart={isMobile? this.processKeyDown.bind(this, "up"): undefined} 
              onMouseLeave={isMobile? undefined: this.handleKeyUp.bind(this)}
              onMouseUp={isMobile? undefined: this.handleKeyUp.bind(this)}
              onTouchEnd={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchCancel={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchLeave={isMobile? this.handleKeyUp.bind(this): undefined}
            > 
            </div> 
            <div className="cursor-pointer"
              style={{position: 'absolute', 
                top: `${410 * this.state.controlRate}px`,
                left: `${730 * this.state.controlRate}px`,
                width: `${260 * this.state.controlRate}px`,
                height: `${260 * this.state.controlRate}px`,
              }}
              onMouseDown={isMobile? undefined: this.processKeyDown.bind(this, "right")}
              onTouchStart={isMobile? this.processKeyDown.bind(this, "right"): undefined} 
              onMouseLeave={isMobile? undefined: this.handleKeyUp.bind(this)}
              onMouseUp={isMobile? undefined: this.handleKeyUp.bind(this)}
              onTouchEnd={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchCancel={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchLeave={isMobile? this.handleKeyUp.bind(this): undefined}
            > 
            </div> 
            <div className="cursor-pointer"
              style={{position: 'absolute', 
                top: `${715 * this.state.controlRate}px`,
                left: `${410 * this.state.controlRate}px`,
                width: `${260 * this.state.controlRate}px`,
                height: `${260 * this.state.controlRate}px`,
              }}
              onMouseDown={isMobile? undefined: this.processKeyDown.bind(this, "down")}
              onTouchStart={isMobile? this.processKeyDown.bind(this, "down"): undefined} 
              onMouseLeave={isMobile? undefined: this.handleKeyUp.bind(this)}
              onMouseUp={isMobile? undefined: this.handleKeyUp.bind(this)}
              onTouchEnd={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchCancel={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchLeave={isMobile? this.handleKeyUp.bind(this): undefined}
            > 
            </div> 
            <div className="cursor-pointer"
              style={{position: 'absolute', 
                top: `${410 * this.state.controlRate}px`,
                left: `${100 * this.state.controlRate}px`,
                width: `${260 * this.state.controlRate}px`,
                height: `${260 * this.state.controlRate}px`,
              }}
              onMouseDown={isMobile? undefined: this.processKeyDown.bind(this, "left")}
              onTouchStart={isMobile? this.processKeyDown.bind(this, "left"): undefined} 
              onMouseLeave={isMobile? undefined: this.handleKeyUp.bind(this)}
              onMouseUp={isMobile? undefined: this.handleKeyUp.bind(this)}
              onTouchEnd={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchCancel={isMobile? this.handleKeyUp.bind(this): undefined}
              onTouchLeave={isMobile? this.handleKeyUp.bind(this): undefined}
            > 
            </div> 
            <img className="head-img unselectable object-cover"
              src={Control}  />
          </div>
          
        </div> 
      </div> 
    ) 
  }

  gameRuleModal(){ 
    return( 
      <GameRuleModal
        open = { this.state.gameRule }
        audioToggleHandler = { this.handleAudioToggle.bind(this)}
        startPlayHandler = { this.handleStartPlay.bind(this)}
        audio = { this.state.audioOn }
        thumbnail = { Thumbnail }
        description = { "God sent His angel to save Daniel" }
        instructions = { ["There are 8 differences between the two pictures.", "Can you find out all of them?"]}
      />
    )
  }

  doneDiv(){ 
    return(
      <div> 
        <div className="justify-items-center mt-2 grid grid-cols-2">
          <button className="w-28 md:w-32 fancy-button orange grid grid-cols-2"
            onClick={(e) => window.location.href = "/games" } 
          >
            <span> 
              <ArrowRightOnRectangleIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"
                style={{transform: "rotateY(180deg)"}} 
              />
            </span> 
            <span className="my-auto hidden md:block">
              Exit
            </span>
           
          </button>
          <button className="w-28 md:w-32 fancy-button green grid grid-cols-2"
            onClick={ this.handleRestart.bind(this) }
          >
            <span> 
              <ArrowPathIcon className="h-10 w-10 md:h-12 md:w-12 text-white" aria-hidden="true"/> 
            </span> 
            <span className="my-auto hidden md:block">
              Play Again
            </span>
          </button>
        </div>
      </div> 
    )
  }

  gameDiv(){ 
    if(this.state.gameState=="done" ){ 
      return( 
        <div>
          {this.pictureDiv()}
          {this.doneDiv()}
        </div> 
      ) 
    }
    return(
      <div> 
        {this.frameItem()}
        {this.pictureDiv()}
      </div>
    )
  }
  render() {
    return (
      <div> 
        {this.pauseModal()}
        
        {this.wellDoneModal()}
        <div className="bg-gradient-to-r from-blue-100 via-pink-200 to-yellow-200 min-h-screen py-2 md:py-3">
          <div className="grid justify-items-center ">
            <div> 
              {this.gameDiv()}
            </div> 
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    mazeHead: state.lostSheep.head,
    mazePic: state.lostSheep.pic, 
    mazeSheep: state.lostSheep.sheep
  }
};

export default connect(mapStateToProps, { 
  mazeHeadUpdate, 
  mazePicUpdate, 
  mazeSheepUpdate
}) (LostSheep);