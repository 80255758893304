import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { RiReplyFill } from "react-icons/ri";

class QuizQuestion extends React.Component{
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  buttonSize(){ 
    if(isMobile){ 
       // ipad
      if(window.innerWidth > 700){
        return 70
      }
      return 45
    }
    if(window.innerWidth > 1200){ 
      return 80
    }
    return 65
  }

  backButton(){ 
    return(
      <button className="focus:outline-none fancy-round-button orange"
        style={{position: 'absolute', 
          left: '10px', 
          top: '10px',
          width: `${this.buttonSize()}px`,
          height: `${this.buttonSize()}px`,
        }}
        onClick={() => this.props.history.goBack()}
      > 
        <RiReplyFill className="text-3xl md:text-5xl mx-auto text-white"/>
      </button>
    )
  }

  answerClass(index){ 
    if(this.props.selectedAnswer != null){ 
      if(index != this.props.selectedAnswer ){ 
        return "invisible"
      }
      if(this.props.selectedCorrect){ 
        return "correct"
      }
      return "wrong"
    }
    switch(index){ 
      case 0: return "one";
      case 1: return "two"; 
      case 2: return "three"; 
      case 3: return "four"
      case 4: return "five"
    }
  }

  onClickCallback(index){ 
    if(this.props.selectedAnswer == null){ 
      return this.props.handleSelectAnswer.bind(this.props.parent, index)
    }else{ 
      return null
    }
  }

  render(){
    return(
      <div className="h-full w-full bg-primary-color m-auto p-2 md:p-4 lg:p-6 unselectable">
        
        <div 
          style={{height:`${this.props.divHeight}px`}} 
          className="relative p-4 mx-auto bg-primary-dark rounded-lg"> 
          { this.backButton() }  
          <div className="flex flow-col justify-center items-center question-container w-full rounded-lg"
          >
            <div className="relative flex items-center justify-center h-full media-wrapper">
              <div className="rounded-md w-full h-full">
                <div className="resizeable-text text-white">
                  <p className="resizeable-text">{this.props.question}</p>
                </div>
              </div> 
            </div> 
            
          </div>
          <div className="pb-1 relative flex answer-container items-center justify-center media-wrapper"
          >
            {this.props.answerOptions.map((answer, index) => (
              <div id= {`answer-div-${index}`}
                className={`resizeable-text answer-button my-1 mx-1 sm:my-2 rounded-md relative flex items-center justify-center media-wrapper ${this.answerClass(index)}`}
                style={{
                  width:`${this.props.answerWidth}`,
                  height:`${this.props.answerHeight}`
                }}
                onClick={this.onClickCallback(index)}
              >
                <p className="resizeable-text text-white resizeable-text"
                   ref={this.props.answerDivRefArray[index]}
                >
                  {answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div> 
    )
  }
}

const mapStateToProps = (state) => {
  return { 
  }
};

export default connect(mapStateToProps, {
})(QuizQuestion);