import React, { Component } from 'react';
import { connect } from 'react-redux';

import { coversInfo } from './FindOutData'

import '../../../stylesheets/gameSetting.css';

class Cover extends React.Component {
  constructor(props) {
    super(props);
  }

  item(){ 
    if(this.props.isSelected){ 
      return <div/> 
    }

    let coverData = coversInfo[this.props.gameId][this.props.index]
    return(
      <div> 
        <div 
          className="z-30" 
          style = {{
            position: 'absolute',    
            top: `${coverData.top * this.props.bgSizeRate + 150 * this.props.sizeRate}px`,
            left: `${coverData.left * this.props.bgSizeRate + 120 * this.props.sizeRate}px`,
            width: `${coverData.width * this.props.bgSizeRate}px`, 
            height: `${coverData.height * this.props.bgSizeRate}px`,
          }}
        >
          <img 
            draggable="false"
            className="unselectable"
            style={{
            objectFit: "fill",
            pointerEvents: "none"}} 
            src={(`${this.props.folder}/${this.props.index}.webp`)}  />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div onClick={this.props.clickHandler}>
        {this.item()}
      </div>
    );
  }
}


export default connect()(Cover);
