import {
  JONAH_WHALE_INIT,
  JONAH_WHALE_UP, 
  JONAH_WHALE_DOWN,
  JONAH_GAME_OVER,
  } from '../../actions/types.js';

const initialState = {
  bgWidth: 0, 
  bgHeight: 0, 
  whaleHeight: 0, 
  y: 0,
  r: 0, 
  count: 1
}

const stepDown = [0.00, 0.002, 0.0045, 0.012]
const stepUp = [0.00, 0.002, 0.0045, 0.012]
const angleUp = [0, -0.8, -1.5, -3 ]
const angleDown = [0, 0.5, 1, 2 ]

export default (state = initialState, action) => {
  switch (action.type) {
    case JONAH_WHALE_INIT: 
      return {...state, bgWidth: action.bgWidth, bgHeight: action.bgHeight, whaleHeight: action.whaleHeight, y: action.y, count: 0}
    case JONAH_WHALE_UP:
      var countUp = state.count 
      switch(state.count){
        case 0:
        case 1: 
        case 2:
          countUp = countUp + 1 
          break;

        case -1: 
        case -2: 
        case -3: 
          countUp = 0 
          break;  
      }
      var maxHeight = 0.08 * state.bgHeight 
      if((state.y - (stepUp[countUp] * state.bgHeight)) < maxHeight){ 
        return {...state, r: 0, count: countUp}
      }else{
        return {...state, y: state.y - stepUp[countUp]*state.bgHeight, r: angleUp[countUp], count: countUp}
      }
    case JONAH_WHALE_DOWN:
      var countDown = state.count 
      switch(state.count){
        case 0:
        case -1: 
        case -2: 
          countDown = countDown - 1 
          break;
        case 1: 
        case 2: 
        case 3: 
          countDown = 0 
          break;  
      }
      
      var minHeight = 0.67 * state.bgHeight 
      if(state.y + stepDown[-1 * countDown]*state.bgHeight > minHeight){ 
        return {...state, r: 0, count: countDown}
      }else{
        return {...state, y: state.y + (stepDown[-1 * countDown]*state.bgHeight), r: angleDown[-1 * countDown], count: countDown}
      }
    case JONAH_GAME_OVER:
      return initialState
    default:
      return state
  }
}
