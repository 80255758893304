import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { BiShareAlt } from "react-icons/bi";

import NavBar from '../../elements/NavBar'
import Footer from '../../elements/Footer'
import ShareModal from "../../elements/ShareModal"

import { craftFetch } from '../../actions/craftActions';

class CraftRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      shareModal: false
    }
  }

  handleCloseModal(){ 
    this.setState({shareModal: false})
  }

  craftItem(){ 
    if(Object.keys(this.props.craft).length){ 
      return(
        <div className="overflow-hidden">     
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            { ReactHtmlParser(this.props.craft.article) }
            <button className="my-8 lg:my-12 w-24 lg:w-32 fancy-button orange"
              onClick={(e) => this.setState({shareModal: true}) }
            >
              <div className="flex justify-left md:justify-center"> 
                <div className="flex flex-row">
                  <div>
                    <BiShareAlt className="ml-0 md:-ml-2 h-4 w-4 md:h-6 md:w-6 text-white" aria-hidden="true"/> 
                  </div>
                  <div className="ml-2 my-auto ">
                    Share
                  </div>
                </div>
              </div>
            </button>
          </div>
          
        </div>
      )
    }
  }

  shareModal(){ 
    if(this.state.shareModal){ 
      return <ShareModal 
              open = { this.state.shareModal }
              twitterTitle = {`${this.props.craft.topic}: Learn to build this LEGO at BibleFunClub`}
              WhatsappTitle = {`Check out this Lego I am building -`}
              closeHandler = {this.handleCloseModal.bind(this)}
            />
    }
  }

  componentDidMount() {
    this.props.craftFetch(this.props.match.params.topic)
  }

  render() {
    return (
      <div> 
        <div className="bg-primary-color min-h-screen"> 
          <NavBar/>
          {this.shareModal()}
          {this.craftItem()}
        </div>
        <Footer/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    craft: state.craft.craft
  }
};
export default connect(mapStateToProps, {
  craftFetch
}) (CraftRenderer);