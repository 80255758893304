import axios from 'axios';

import { PROGRESS_OWNERS_FETCH_SUCCESS, 
          PROGRESS_OWNER_SAVE_SUCCESS } from './types';

export const progressOwnerListFetch = () => {
  const url = '/api/progress_owners' 
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.get(url)
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: PROGRESS_OWNERS_FETCH_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const progressOwnerCreate = (topic_id, activity) => { 
  const url = `/api/progress_owners`
  return function action(dispatch) { 
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]').content;
    axios.post(url, { topic_id: topic_id, activity: activity })
    .then(function (response) {
      if(response.status == 200){ 
        dispatch({
          type: PROGRESS_OWNER_SAVE_SUCCESS,
          payload: response.data,
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
}